import React, { useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import {
    CateringStatusType,
    IPurchase,
    PurchaseStatus,
} from "@snackpass/snackpass-types";
import { Spinner } from "react-activity";

import api from "src/api/rest";
import { humanizePurchaseStatus } from "#order-details/utils";
import { useCateringPurchaseReportRows } from "src/api/rest/usePurchaseReports";

import { BorderContainer, Button, Title } from "./ui";

interface Props {
    purchase: IPurchase;
}

export const CompleteCatering = ({ purchase }: Props) => {
    const status = humanizePurchaseStatus(purchase, true);
    const [isLoading, setLoading] = useState(false);
    const { refetch } = useCateringPurchaseReportRows();

    const handleComplete = async () => {
        try {
            setLoading(true);
            await api.purchases.updateStatus(purchase._id, {
                status: PurchaseStatus.completed,
            });
            refetch();
        } finally {
            setLoading(false);
        }
    };

    if (
        !purchase?.catering?.isCatering ||
        purchase.catering.status !== CateringStatusType.confirmed
    )
        return null;

    return (
        <BorderContainer>
            <Title>Order in Progress ({status?.text})</Title>
            <Button onClick={handleComplete}>
                {isLoading ? (
                    <Spinner color={SystemColors.v1.white} size={16} />
                ) : (
                    <Title style={{ color: SystemColors.v1.white }}>
                        Complete Order
                    </Title>
                )}
            </Button>
        </BorderContainer>
    );
};
