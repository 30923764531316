/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getActiveStore } from "src/redux/selectors";
import { getStoreTimezone } from "#utils/helpers";
import GranularityPicker from "#granularity-picker";
import { useGranularityPicker } from "#hooks";
import { useGlobalDate } from "#hooks/use-global-date";
import { DownloadCsvButton } from "#financial-reports/components/download-csv-button";
import { useFinancialReportsData } from "#financial-reports/hooks/useFinancialReportsData";
import { EssentialReportTable } from "#financial-reports/screens/essentials-report/essentials-report";
import { getPresentableData } from "#financial-reports/screens/essentials-report/lib";
import TransactionSourcePicker from "#pickers/transaction-source-picker";
import TransactionChannelPicker from "#pickers/transaction-channel-picker";
import DateRangeSelector from "#date-picker/date-range-selector";

export const EssentialsLayout = () => {
    const activeStore = useSelector(getActiveStore);
    const timezone = getStoreTimezone(activeStore);
    const { startDate: startMoment, endDate: endMoment } = useGlobalDate();
    const { granularity, setGlobalGranularity } = useGranularityPicker();
    const { data } = useFinancialReportsData(granularity);
    const csvFilename = `${
        activeStore?.name
    } - Snackpass Financial Reports ${startMoment.format(
        "YYYY-MMM-DD",
    )}-${endMoment.format("YYYY-MMM-DD")}`;

    useEffect(() => {
        const _init = granularity ?? "day";
        setGlobalGranularity("day");
        return () => setGlobalGranularity(_init);
    }, []);

    return (
        <div className="m-4 md:m-6">
            <h4 className="mb-6 text-large">Essentials</h4>
            <div className="mb-4 flex flex-row items-center gap-2 overflow-auto whitespace-nowrap">
                <DateRangeSelector subtitle={"Currently showing "} />
                <TransactionSourcePicker />
                <TransactionChannelPicker />
                <GranularityPicker initialState="day" />
                {activeStore ? (
                    <div className="flex flex-1 justify-end">
                        <DownloadCsvButton
                            getData={getPresentableData.bind(
                                null,
                                data,
                                timezone,
                            )}
                            filename={csvFilename}
                        />
                    </div>
                ) : null}
            </div>
            <EssentialReportTable granularity={granularity} />
        </div>
    );
};
