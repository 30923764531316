import {
    CateringStatusType,
    IPurchase,
    PurchaseStatus,
} from "@snackpass/snackpass-types";
import React from "react";

import { CompleteCatering } from "./components/complete-catering";
import { ConfirmCatering } from "./components/confirm-catering";

type Props = {
    purchase: IPurchase;
};

// Right now this only supports catering but can be easily extended
export const PurchaseAction = ({ purchase }: Props) => {
    const lastStatus = purchase?.status[purchase.status.length - 1];
    const isCatering = purchase?.catering?.isCatering;

    switch (lastStatus?.type) {
        case PurchaseStatus.received:
        case PurchaseStatus.started: {
            if (isCatering) {
                switch (purchase.catering.status) {
                    case CateringStatusType.unconfirmed:
                        return <ConfirmCatering purchase={purchase} />;
                    case CateringStatusType.confirmed:
                        return <CompleteCatering purchase={purchase} />;
                    default:
                        return null;
                }
            }
            return null;
        }
        default:
            return null;
    }
};
