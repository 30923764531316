import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { debounce } from "radash";

import { TableProperty } from "#table-editor/types";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { Element as Variant } from "src/api/graphql/generated/types";
import { Input } from "src/@/components/ui/input";
import { Section } from "#table-editor/components/Editor/Panel/Section";
import { Button } from "src/@/components/ui/button";

type ModifyTableProps = {
    id: string;
    properties: TableProperty;
};

export function ModifyTable({ id, properties }: ModifyTableProps) {
    const editor = useEditorStore();

    const [tableName, setTableName] = useState(properties.tableName);
    const [numSeats, setNumSeats] = useState(properties.numSeats.toString());

    useEffect(() => {
        setTableName(properties.tableName);
        setNumSeats(properties.numSeats.toString());
    }, [properties]);

    const updateTable = debounce(
        { delay: 100 },
        ({ name, seats }: { name?: string; seats?: number }) => {
            editor.getState().setTableData(id, {
                type: Variant.Table,
                tableId: properties.tableId,
                tableName: name ?? tableName,
                numSeats: seats ?? (numSeats ? parseInt(numSeats) : 4)
            });
        }
    );

    const onChangeName = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setTableName(e.target.value);
            updateTable({ name: e.target.value });
        },
        [updateTable]
    );

    const onChangeSeats = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setNumSeats(e.target.value);

            if (e.target.value) {
                updateTable({ seats: parseInt(e.target.value) });
            }
        },
        [updateTable]
    );

    const onClickDelete = useCallback(() => {
        editor.getState().removeSelected(id);
    }, [editor, id]);

    return (
        <Section label="Edit Table">
            <div className="flex w-full flex-col gap-2.5">
                <Input
                    placeholder="Table Number"
                    maxLength={4}
                    value={tableName}
                    onChange={onChangeName}
                    className="w-full border-none bg-neutral-600 text-white placeholder:text-neutral-400"
                />

                <Input
                    placeholder="Number of Seats"
                    type="number"
                    value={numSeats}
                    onChange={onChangeSeats}
                    className="w-full border-none bg-neutral-600 text-white placeholder:text-neutral-400"
                />

                <Button
                    type="button"
                    variant="destructive"
                    onClick={onClickDelete}
                >
                    <span>Delete</span>
                </Button>
            </div>
        </Section>
    );
}
