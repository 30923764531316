import { Canvas } from "fabric";

/**
 * Subscribes to the `object:moving` event and forces objects to snap to the nearest grid tile
 * @param canvas Fabric canvas instance
 * @param gridSize The size of a single grid tile, assuming a `gridSize x gridSize` tile
 * @param snap The step size within one grid tile. This will split up one `gridSize x gridSize` tile into `snap` steps.
 */
export function withSnapToGrid(canvas: Canvas, gridSize = 30, snap = 1) {
    const moving = canvas.on("object:moving", (e) => {
        if (
            Math.round((e.target.left / gridSize) * snap) % snap === 0 &&
            Math.round((e.target.top / gridSize) * snap) % snap === 0
        ) {
            e.target.set({
                left: Math.round(e.target.left / gridSize) * gridSize,
                top: Math.round(e.target.top / gridSize) * gridSize
            });
            e.target.setCoords();
        }
    });

    const mouse = canvas.on("mouse:up", (e) => {
        if (e.target != null) {
            e.target.set({
                left: Math.round(e.target.left / gridSize) * gridSize,
                top: Math.round(e.target.top / gridSize) * gridSize
            });
            e.target.setCoords();
        }
    });

    return () => {
        moving();
        mouse();
    };
}
