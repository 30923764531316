import { useEffect, useState } from "react";
import { isTablet } from "react-device-detect";
import { useSelector } from "react-redux";
import { OrderStatusConfiguration } from "@snackpass/snackpass-types";

import DownloadPurchasesCSVButton from "#download-purchase-button";
import TransactionSourcePicker from "#pickers/transaction-source-picker";
import PaymentTypePicker from "#payment-type-picker";
import { useGlobalDate } from "#hooks";
import DateRangeSelector from "#date-picker/date-range-selector";
import TransactionChannelPicker from "#pickers/transaction-channel-picker";
import { Button } from "src/@/components/ui/button";
import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

import { DateAddressHeader } from "./components/date-address-header";
import { Sales } from "./components/sales";
import { ReferralButton } from "./components/referral";
import { ShortcutsCarousel } from "./components/shortcuts-carousel";
import { Sheet, SheetContent, SheetTrigger } from "src/@/components/ui/sheet";
import { Warnings } from "./components/warnings";
import { ShortcutsGrid } from "#dashboard/components/shortcuts-grid";
import { ChevronRightIcon } from "@radix-ui/react-icons";

const Dashboard = () => {
    const { startDate, endDate } = useGlobalDate();
    const store = useSelector(getActiveStore);

    const [payoutsWarning, setPayoutsWarning] = useState<
        null | "warning" | "destructive"
    >(null);

    useEffect(() => {
        const checkIfVerified = async (storeId: string) =>
            api.verifications
                .status(storeId)
                .then(({ data }) =>
                    data.status === "none" ? null : data.status,
                )
                .catch(() => null);

        const checkAndSetPayoutsWarnState = async () => {
            if (store?._id) {
                const warning = await checkIfVerified(store._id);
                setPayoutsWarning(warning);
            }
        };

        void checkAndSetPayoutsWarnState();
    }, [store?._id]);

    const warnables = {
        payouts: Boolean(payoutsWarning),
        autoComplete:
            store?.orderStatusConfiguration ===
                OrderStatusConfiguration.AutoAcceptAutoComplete ||
            store?.orderStatusConfiguration ===
                OrderStatusConfiguration.ManualAcceptAutoComplete,
        defaultPin: !store?.hasEmployeeAuditTracking,
        dynamicWaitTime: store?.waitTimeType === "DYNAMIC",
        specificPickupTime: store?.pickupTimeType === "specific",
    };

    return (
        <div className="p-6 sm:p-12 mb-8 ">
            <ReferralButton />
            <Warnings warnables={warnables} />
            <DateAddressHeader />
            <div className="relative mb-2 flex items-center space-x-2 whitespace-nowrap md:mb-5">
                <DateRangeSelector subtitle={"Showing payments from "} />
                <TransactionSourcePicker />
                <TransactionChannelPicker />
                <PaymentTypePicker />
                {!isTablet && (
                    <div className="flex flex-1 justify-end">
                        <DownloadPurchasesCSVButton
                            startDate={startDate}
                            endDate={endDate}
                            showTotals={true}
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-col space-y-6">
                <Sales />
            </div>
            <br />
            <div className="flex justify-between items-center">
                <h2 className="font-semibold text-[20px] leading-6 text-[#0F0F0F]">
                    Shortcuts
                </h2>
                <Sheet>
                    <SheetTrigger asChild>
                        <Button variant="outline">
                            View all
                            <ChevronRightIcon className="ml-2 h-4 w-4" />
                        </Button>
                    </SheetTrigger>
                    <SheetContent
                        side="right"
                        className="w-[80vw] min-w-[60vw] max-w-[90vw]"
                    >
                        <div className="grid gap-4 py-4 h-full overflow-y-auto">
                            <ShortcutsGrid />
                        </div>
                    </SheetContent>
                </Sheet>
            </div>
            <div className="my-8 px-16">
                <ShortcutsCarousel />
            </div>
        </div>
    );
};

export default Dashboard;
