import { PropsWithChildren, useEffect, useRef } from "react";
import { Canvas } from "fabric";

import { withCanvasDefaults } from "#table-editor/canvas/withCanvasDefaults";

import { useEditorStore } from "./EditorProvider";

type EditorCanvasProps = PropsWithChildren<{
    width: number;
    height: number;
    grid: {
        size?: number;
        snap?: { gridSize?: number; fraction?: number };
    };
}>;

export function EditorCanvas({
    width,
    height,
    grid,
    children
}: EditorCanvasProps) {
    const editor = useEditorStore();
    const canvasEl = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasEl.current) {
            const canvas = new Canvas(canvasEl.current, {
                width,
                height
            });

            const unsubscribe = withCanvasDefaults(canvas, editor.getState, {
                snap: grid.snap,
                size: { width, height },
                gridSize: grid.size
            });

            editor.getState().setCanvas(canvas);

            return () => {
                unsubscribe();
                void canvas.dispose();
                editor.getState().setCanvas(null);
            };
        }

        // Adding all deps will reinitialize canvas every save
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, width]);

    return (
        <div className="relative">
            <canvas width={width} height={height} ref={canvasEl} />
            {children}
        </div>
    );
}
