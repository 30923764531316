import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { ChatBubbleIcon } from "@radix-ui/react-icons";

import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { useGetStoreStatsQuery } from "src/api/graphql/generated/types";
import { getActiveStore } from "src/redux/selectors";

export function ReachableSMSBanner() {
    const activeStore = useSelector(getActiveStore);
    const { data, loading } = useGetStoreStatsQuery({
        variables: { storeId: activeStore!._id },
        skip: !activeStore,
    });
    const reachableCustomersCount =
        data?.storeById?.stats?.totalReachableSMSCustomers;
    if (loading) {
        return <Skeleton className="rounded-full" width={500} height={48} />;
    }
    if (!reachableCustomersCount) {
        return null;
    }
    return (
        <Alert variant="blackBanner">
            <ChatBubbleIcon className="h-4 w-4 " />
            <AlertTitle className="text-base leading-none">
                {reachableCustomersCount.toLocaleString()} Reachable Customers
            </AlertTitle>
            <AlertDescription className="text-small">
                Text your customers with Campaigns
            </AlertDescription>
        </Alert>
    );
}
