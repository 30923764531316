import { PropsWithChildren } from "react";

import { ServiceArea } from "#table-editor/types";

import { EditorProvider } from "./EditorProvider";
import { EditorCanvas } from "./EditorCanvas";
import { useEditorSize } from "./hooks/useEditorSize";

type EditorProps = PropsWithChildren<{
    serviceAreas: ServiceArea[];
    width?: number;
    height?: number;
    grid: {
        size?: number;
        snap?: { gridSize?: number; fraction?: number };
    };
}>;

export function Editor({
    width,
    height,
    serviceAreas,
    grid,
    children
}: EditorProps) {
    const size = useEditorSize({ width, height });

    return (
        <EditorProvider serviceAreas={serviceAreas}>
            <EditorCanvas width={size.width} height={size.height} grid={grid}>
                {children}
            </EditorCanvas>
        </EditorProvider>
    );
}
