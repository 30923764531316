import { cva, VariantProps } from "class-variance-authority";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";

import { cn } from "src/@/lib/utils";
import { Shape, Element } from "src/api/graphql/generated/types";
import {
    useEditorState,
    useEditorStore
} from "#table-editor/components/Editor/EditorProvider";

const draw = cva("", {
    variants: {
        border: {
            transparent: "border-0",
            solid: "border-2 border-solid",
            dash: "border-2 border-dashed"
        },
        borderColor: {
            default: "border-white",
            selected: "border-[#ffaa33]"
        },
        fill: {
            solid: "bg-white",
            transparent: "bg-transparent"
        },
        shape: {
            empty: "",
            square: "h-16 w-16 rounded-sm",
            rect: "h-8 w-32 rounded-sm",
            circle: "h-16 w-16 rounded-full",
            diamond: "h-16 w-16 rounded-sm"
        }
    },
    defaultVariants: {
        borderColor: "default",
        border: "solid",
        fill: "solid"
    }
});

type DrawShapeProps = PropsWithChildren<
    Omit<VariantProps<typeof draw>, "shape">
> & {
    drawShape: VariantProps<typeof draw>["shape"];
    shape: Shape;
    variant: Element;
    className?: string;
};

const DrawShape = ({
    drawShape,
    shape,
    variant,
    fill,
    border,
    className,
    children
}: DrawShapeProps) => {
    const editor = useEditorStore();
    const controls = useEditorState(useShallow((state) => state.controls));

    const onClick = useCallback(() => {
        if (
            controls.mode === "draw" &&
            controls.shape === shape &&
            controls.variant === variant
        ) {
            editor.getState().setEditMode();
        } else {
            editor.getState().setDrawMode(shape, variant);
        }
    }, [controls, editor, shape, variant]);

    const borderColor = useMemo(
        () =>
            controls.mode === "draw" &&
            controls.shape === shape &&
            controls.variant === variant
                ? "selected"
                : "default",
        [controls, shape, variant]
    );

    return (
        <div
            className={cn(
                draw({ fill, border, borderColor, shape: drawShape }),
                className
            )}
            children={children}
            onClick={onClick}
        />
    );
};

type DrawShapeSelectProps = Omit<
    DrawShapeProps,
    "shape" | "size" | "borderColor"
>;

export const Diamond = ({
    fill,
    border,
    className,
    children,
    variant
}: Omit<DrawShapeSelectProps, "drawShape">) => (
    <div
        className={cn(
            draw({
                fill: "transparent",
                border: "transparent",
                shape: "diamond"
            }),
            "flex flex-row items-center justify-center",
            className
        )}
    >
        <DrawShape
            drawShape="empty"
            shape={Shape.Diamond}
            variant={variant}
            border={border}
            fill={fill}
            className="h-3/4 w-3/4 rotate-45 rounded-sm"
            children={children}
        />
    </div>
);

export const Rectangle = ({
    fill,
    border,
    className,
    children,
    variant,
    drawShape
}: DrawShapeSelectProps) => (
    <DrawShape
        variant={variant}
        shape={Shape.Rect}
        drawShape={drawShape}
        border={border}
        fill={fill}
        className={className}
        children={children}
    />
);

export const Circle = ({
    fill,
    border,
    className,
    children,
    variant
}: Omit<DrawShapeSelectProps, "drawShape">) => (
    <DrawShape
        drawShape="circle"
        variant={variant}
        fill={fill}
        border={border}
        shape={Shape.Circle}
        className={className}
        children={children}
    />
);
