import { Button } from "src/@/components/ui/button";
import { useSaveServiceArea } from "#table-editor/api";
import { useEditorState } from "#table-editor/components/Editor/EditorProvider";

type SaveButtonProps = {
    overrideLoading: boolean;
};

export function SaveButton({ overrideLoading }: SaveButtonProps) {
    const isDirty = useEditorState((state) => state.isDirty);

    const { loading: saveLoading, saveServiceArea } = useSaveServiceArea();

    return (
        <Button
            type="button"
            variant="secondary"
            className="w-full"
            disabled={overrideLoading || !isDirty || saveLoading}
            onClick={saveServiceArea}
            loading={overrideLoading || saveLoading}
            loadingColor="black"
        >
            Save
        </Button>
    );
}
