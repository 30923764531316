import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";
import React from "react";

import { useCateringPurchaseReportRows } from "src/api/rest/usePurchaseReports";
import { useQuery } from "#hooks/use-query";
import { PurchaseItems } from "#order-details/components/purchase-items";
import { PurchaseInfo } from "#order-details/components/purchase-info";
import { PurchaseCustomer } from "#order-details/components/purchase-customer";
import { PurchaseAction } from "#order-details/components/purchase-actions";

export const OrderDetail = () => {
    const query = useQuery();
    const purchaseId = query.get("order") || "";
    const { data, isLoading } = useCateringPurchaseReportRows();
    const purchase = (data?.rows || []).find(
        (p) => p.purchaseId === purchaseId,
    );

    if (isLoading || !purchaseId.length) return null;

    if (!purchase?.purchase) {
        return <NotFound>This purchase doesn't exist.</NotFound>;
    }

    return (
        <Container>
            <PurchaseCustomer purchase={purchase.purchase} />
            <PurchaseAction purchase={purchase.purchase} />
            <PurchaseInfo purchase={purchase.purchase} />
            <PurchaseItems purchase={purchase.purchase} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media ${ScreenState.MOBILE} {
        margin: 16px 16px 16px 16px;
    }

    @media ${ScreenState.TABLET} {
        margin: 24px 24px 24px 24px;
    }

    @media ${ScreenState.DESKTOP} {
        margin: 48px 48px 40px 48px;
    }
`;

const NotFound = styled(Container)<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: ${SystemColors.v1.gray20};
`;
