import React, { useMemo } from "react";
import "chartjs-plugin-style";
import { PurchaseSummaryData } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { BarChart, BarChartProps } from "@tremor/react";
import Skeleton from "react-loading-skeleton";

import { toDollarFormatted } from "#reports/sales-summary/lib";
import { useActiveStoreTimezone, useGlobalDate } from "#hooks";
import { calculateChartWidth } from "#utils/helpers";

export type Props = {
    data: PurchaseSummaryData[];
    className?: string;
    isLoading?: boolean;
    isError?: boolean;
};

export const NetSalesChart = ({
    data,
    className,
    isLoading,
    isError,
}: Props) => {
    const timezone = useActiveStoreTimezone();
    const { startDate, endDate } = useGlobalDate();

    const chartData = useMemo(() => {
        const result: { [date: string]: null | PurchaseSummaryData } = {};
        const diff = moment(endDate).diff(moment(startDate), "days") + 1;
        for (let i = 0; i < diff; i++) {
            result[moment(startDate).add(i, "days").format("M[/]D")] = null;
        }
        data.forEach((day) => {
            const formatted = moment(day.date).tz(timezone).format("M[/]D");
            if (result[formatted] !== undefined) {
                result[formatted] = day;
            }
        });
        return Object.entries(result).map(([label, value]) => ({
            label,
            "Net Sales": value?.netSales,
            Orders: value?.count,
        }));
    }, [data, startDate, endDate, timezone]);

    if (isLoading) {
        return <Skeleton className="h-80 w-full rounded-xl" />;
    }
    if (isError) {
        return (
            <div className="flex h-80 w-full items-center justify-center rounded-xl bg-neutral-300 text-neutral-600">
                Report has timed out due to heavy volume. Please use the Sales
                Summary report or select a smaller date range.
            </div>
        );
    }

    const yWidth = (): number => {
        const validNetSales = chartData
            .map((item) => item["Net Sales"])
            .filter((netSales): netSales is number => netSales !== undefined);

        const maxNetSales = Math.max(...validNetSales);
        return calculateChartWidth(maxNetSales, true);
    };

    return (
        <BarChart
            data={chartData}
            index="label"
            categories={["Net Sales"]}
            valueFormatter={toDollarFormatted}
            yAxisWidth={yWidth()}
            customTooltip={CustomTooltip}
            showLegend={false}
            minValue={0}
            className={className}
        />
    );
};

const CustomTooltip: BarChartProps["customTooltip"] = ({
    payload,
    active,
    label,
}) => {
    if (!active || !payload) return null;
    return (
        <div className="flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div className={`flex w-1 flex-col rounded bg-blue-500`} />
                    <div className="space-y-1">
                        <p className="font-semibold">{label}</p>
                        <p className="text-neutral-600">Net Sales</p>
                        <p className="font-medium text-neutral-800">
                            {toDollarFormatted(category.value as number)}
                        </p>
                        <p className="text-neutral-600">Orders</p>
                        <p className="font-medium text-neutral-800">
                            {category.payload.Orders}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};
