import { SystemColors } from "@snackpass/design-system";
import { PurchaseReportData } from "@snackpass/snackpass-types";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { useCateringPurchaseReportRows } from "src/api/rest/usePurchaseReports";
import { sortCateringPurchases } from "#order-details/utils";
import { useQuery } from "#hooks/use-query";
import { FeatureBadge } from "#navigation/FeatureBadge";

import { LoadingOrderListRow } from "./components/loading-order-list-row";
import { OrderListRow } from "./components/order-list-row";

type OrderListView = "upcoming" | "complete";

export const OrderList = () => {
    const history = useHistory();
    const query = useQuery();
    const [view, setView] = useState<OrderListView>("upcoming");

    const getView = (p: PurchaseReportData): OrderListView => {
        if (p.status === "COMPLETED" || p.status === "CANCELED")
            return "complete";
        else return "upcoming";
    };

    const { data: reportData, isLoading } = useCateringPurchaseReportRows();
    const purchases = reportData?.rows || [];
    const upcomingPurchases = purchases.filter(
        (p) => getView(p) === "upcoming",
    );
    const completePurchases = purchases.filter(
        (p) => getView(p) === "complete",
    );

    const filteredPurchases = useMemo(() => {
        if (view === "upcoming") {
            return upcomingPurchases;
        }
        if (view === "complete") {
            return completePurchases;
        }
        return purchases;
    }, [purchases, view]);

    // Auto select most recent upcoming order
    useEffect(() => {
        if (!upcomingPurchases.length || query.get("order")?.length) return;
        const params = new URLSearchParams();
        params.append("order", upcomingPurchases[0].purchaseId);
        setView(getView(upcomingPurchases[0]) || "upcoming");
        history.push({ search: params.toString() });
    }, [upcomingPurchases]);

    return (
        <Container>
            <Header>
                <Title>
                    <div className="flex flex-row items-center gap-3">
                        Catering Orders
                        <FeatureBadge variant="legacy" />
                    </div>
                </Title>

                <ToggleContainer>
                    <ToggleButton
                        isActive={view === "upcoming"}
                        onClick={() => setView("upcoming")}
                    >
                        Upcoming
                    </ToggleButton>
                    <ToggleButton
                        isActive={view === "complete"}
                        onClick={() => setView("complete")}
                    >
                        Complete
                    </ToggleButton>
                </ToggleContainer>
            </Header>
            <Rows>
                {isLoading ? (
                    <>
                        <LoadingOrderListRow />
                        <LoadingOrderListRow />
                        <LoadingOrderListRow />
                    </>
                ) : filteredPurchases.length ? (
                    filteredPurchases
                        .sort(sortCateringPurchases)
                        .map((purchase) => (
                            <OrderListRow
                                purchase={purchase}
                                key={purchase.purchaseId}
                            />
                        ))
                ) : (
                    <NoOrders>No orders</NoOrders>
                )}
            </Rows>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-width: 375px;
    border-left: 1px solid ${SystemColors.v1.gray90};
    border-right: 1px solid ${SystemColors.v1.gray90};
`;

const Rows = styled.div`
    overflow: auto;
    flex: 1;

    /* hide scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const Header = styled.div`
    position: sticky;
    padding: 16px;
    top: 0;
    background: ${SystemColors.v1.white};
    border-bottom: 1px solid ${SystemColors.v1.gray90};
`;

const Title = styled.div`
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
`;

const NoOrders = styled.div`
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: ${SystemColors.v1.gray20};
    margin-bottom: 24px;
    text-align: center;
    margin-top: 20px;
`;

const ToggleContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: ${SystemColors.v1.gray90};
    border-radius: 44px;
    height: 44px;
    padding: 4px;
`;

const ToggleButton = styled.div<{ isActive?: boolean }>`
    cursor: pointer;
    display: flex;
    flex: 1;
    background: ${({ isActive }) =>
        isActive ? SystemColors.v1.white : undefined};
    border-radius: 44px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: ${SystemColors.v1.sesame};
`;
