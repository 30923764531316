// TODO: remove this when skippedphone@snackpass.co is removed from data
// just adding a client override here so this does not block release.
import { CustomerTableRow } from "#reports/customer-directory-insights/types";

const OVERRIDE_EMAILS_TO_NAME: { [email: string]: string } = {
    "skippedphone@snackpass.co": "Register Customer",
};

export const processCustomerData = (
    rows: CustomerTableRow[],
): CustomerTableRow[] =>
    rows.map((e) =>
        OVERRIDE_EMAILS_TO_NAME[e.email]
            ? { ...e, name: OVERRIDE_EMAILS_TO_NAME[e.email] }
            : e,
    );

export const exportColumns = [
    {
        title: "Name",
        dataIndex: "name",
    },
    {
        title: "Lifetime Rank",
        dataIndex: "rank",
    },
    {
        title: "Total Spent",
        dataIndex: "totalSpent",
    },
    {
        title: "Lifetime Orders",
        dataIndex: "orders",
    },
    {
        title: "Customer Since",
        dataIndex: "customerSince",
    },
    {
        title: "Last Order",
        dataIndex: "lastOrder",
    },
    {
        title: "Points",
        dataIndex: "points",
    },
    {
        title: "Frequency",
        dataIndex: "frequency",
    },
    {
        title: "Gift Card Balance",
        dataIndex: "giftcardBalance",
    },
    {
        title: "Phone Number",
        dataIndex: "phone",
    },
    {
        title: "Email",
        dataIndex: "email",
    },
];
