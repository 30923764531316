import { useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { DialogClose } from "@radix-ui/react-dialog";
import { useHistory } from "react-router-dom";
import {
    Cross1Icon,
    MixerVerticalIcon,
    PlusCircledIcon,
} from "@radix-ui/react-icons";
import { FullscreenIcon } from "lucide-react";
import { toast } from "sonner";
import clsx from "clsx";

import {
    MenuFormValues,
    MenuFormValuesSchema,
} from "#menu-manager/components/menu-form/schema";
import { Form, FormDescription, FormLabel } from "src/@/components/ui/form";
import { CategoryItemsInput } from "#menu-manager/components/menu-form/CategoryItemsInput";
import { useCategories, useCreateMenu, useItems } from "#menu-manager/hooks";
import {
    DEFAULT_MENU_VALUES,
    formValuesToInputType,
} from "#menu-manager/components/menu-form/lib";
import { Dialog, DialogContent } from "src/@/components/ui/dialog";
import { Button } from "src/@/components/ui/button";
import { MenuManagerRoutes } from "#menu-manager/routes";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStoreId } from "src/redux/slices";
import { Switch } from "src/@/components/ui/switch";
import { MenuNameInput } from "#menu-manager/components/menu-form/MenuNameCard";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu";
import {
    EnableDefaultPriceAdjustmentSwitch,
    PriceAdjustmentInput,
} from "#menu-manager/components/menu-form/PriceAdjustmentCard";
import { Separator } from "src/@/components/ui/separator";

export type NewMenuDialogProps = {
    open: boolean;
    close: () => void;
};

export function NewMenuDialog({ open, close }: NewMenuDialogProps) {
    const [selectCategoriesDialogOpen, setSelectCategoriesDialogOpen] =
        useState(false);

    const [shouldRemainOpenAfterSubmit, setShouldRemainOpenAfterSubmit] =
        useState<boolean>(false);

    const form = useForm<MenuFormValues>({
        resolver: zodResolver(MenuFormValuesSchema),
        defaultValues: DEFAULT_MENU_VALUES,
    });

    const categories = useCategories();
    const items = useItems();
    const priceAdjustment =
        formValuesToInputType(form.watch())?.priceAdjustment ?? undefined;

    const storeId = useAppSelector(getActiveStoreId);
    const { createMenu, loading: submitting } = useCreateMenu();

    const handleClose = useCallback(() => {
        if (!submitting) close();
    }, [close, submitting]);

    const onSubmit = useCallback(
        async (values: MenuFormValues) =>
            createMenu({
                input: formValuesToInputType(values),
                storeId,
            })
                .then((result) => {
                    toast.success(
                        `Successfully created ${result.data?.createStoreMenu.name}`,
                    );
                    if (!shouldRemainOpenAfterSubmit) handleClose();
                    form.reset(DEFAULT_MENU_VALUES);
                })
                .catch((e) =>
                    toast.error("Error creating menu", {
                        description: e?.message,
                    }),
                ),
        [createMenu, form, handleClose, shouldRemainOpenAfterSubmit, storeId],
    );

    const handleSubmit = useCallback(() => {
        void form.handleSubmit(onSubmit)();
    }, [form, onSubmit]);

    return (
        <Dialog open={open} onOpenChange={handleClose} modal={false}>
            <DialogContent
                className="max-h-[80vh] w-full max-w-80 overflow-y-auto p-0"
                closeButtonClassName="hidden"
                forceOverlay
            >
                <Form {...form}>
                    <Header />
                    <div className="space-y-4 px-6">
                        <div>
                            <FormLabel className="text-lg">Name</FormLabel>
                            <FormDescription>
                                This is the name of the menu that will appear on
                                point of sale and ordering platforms.
                            </FormDescription>
                        </div>
                        <MenuNameInput />
                        <Separator />
                        <div>
                            <FormLabel className="text-lg">Items</FormLabel>
                            <FormDescription>
                                Choose categories and items to display in this
                                menu.
                            </FormDescription>
                        </div>
                        <div>
                            <CategoryItemsInput
                                items={items}
                                categories={categories}
                                dialogOpen={selectCategoriesDialogOpen}
                                priceAdjustment={priceAdjustment}
                                setDialogOpen={setSelectCategoriesDialogOpen}
                                className="rounded-md border-x"
                            />
                            <Button
                                variant="outline"
                                size="sm"
                                type="button"
                                onClick={() =>
                                    setSelectCategoriesDialogOpen(true)
                                }
                            >
                                <PlusCircledIcon className="mr-1" />
                                Add Items
                            </Button>
                        </div>
                        <Separator />
                        <PriceAdjustmentDropdown />
                    </div>
                </Form>

                <div className="flex items-center justify-end space-x-2 border-t border-neutral-400 p-6">
                    <div className="mr-4 flex items-center space-x-2">
                        <span className="text-micro text-neutral-600">
                            Create more
                        </span>
                        <Switch
                            checked={shouldRemainOpenAfterSubmit}
                            onCheckedChange={setShouldRemainOpenAfterSubmit}
                            aria-label="toggle create more after submitting"
                            className="mr-4"
                        />
                    </div>
                    <Button
                        disabled={submitting}
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                        Create Menu
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const Header = () => {
    const form = useFormContext<MenuFormValues>();
    const history = useHistory();
    const onFullscreen = useCallback(() => {
        history.push(MenuManagerRoutes.NEW_MENU, form.getValues());
    }, [form, history]);

    return (
        <div className="sticky flex flex-row items-center justify-between border-b border-neutral-400 p-4 font-semibold">
            <DialogClose asChild>
                <Button variant="ghost" size="sm" className="bg-transparent">
                    <Cross1Icon />
                </Button>
            </DialogClose>
            New Menu
            <Button
                variant="ghost"
                size="sm"
                className="flex items-center bg-transparent text-neutral-950"
                aria-label="open in full form view"
                onClick={onFullscreen}
            >
                <FullscreenIcon className="h-4 w-4" />
            </Button>
        </div>
    );
};

function PriceAdjustmentDropdown() {
    const form = useFormContext<MenuFormValues>();
    const enabled = form.watch("priceAdjustment.enabled");
    return (
        <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
                <Button
                    size="sm"
                    variant="outline"
                    className={clsx(
                        enabled && "border-black",
                        form.formState.errors.priceAdjustment &&
                            "border-red-500",
                    )}
                >
                    <MixerVerticalIcon className="mr-2 h-3 w-3" />
                    Price Adjustment
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-screen p-3 sm:w-fit">
                <div className="mb-3 flex items-center justify-between border-b border-neutral-400 py-6">
                    <div>
                        <span className="block text-small font-semibold">
                            Default Price Adjustment
                        </span>
                        <span className="mt-1 block text-small text-neutral-600">
                            Adjust prices of all items on the menu.
                        </span>
                    </div>
                    <EnableDefaultPriceAdjustmentSwitch />
                </div>
                <PriceAdjustmentInput disabled={!enabled} />
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
