import { ChangeEvent, useCallback, useState } from "react";

import {
    useEditorState,
    useEditorStore
} from "#table-editor/components/Editor/EditorProvider";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { Input } from "src/@/components/ui/input";
import { Button } from "src/@/components/ui/button";

export function FirstServiceAreaDialog() {
    const editor = useEditorStore();
    const serviceAreas = useEditorState((state) => state.serviceAreas);

    const [name, setName] = useState("");

    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const onClickHandler = useCallback(() => {
        editor.getState().addServiceArea(name);
        editor.getState().setServiceArea("");
    }, [editor, name]);

    return (
        <Dialog modal open={serviceAreas.length === 0}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create a Service Area</DialogTitle>
                    <DialogDescription>
                        A Service Area marks a region of the store that houses a
                        group of tables. This can be sections of a store,
                        different levels, or separate grouping of tables.
                    </DialogDescription>
                </DialogHeader>
                <Input
                    placeholder="Name"
                    value={name}
                    onChange={onChangeName}
                    className="w-full text-black placeholder:text-neutral-500"
                />
                <Button
                    type="button"
                    variant="default"
                    onClick={onClickHandler}
                >
                    <span>Submit</span>
                </Button>
            </DialogContent>
        </Dialog>
    );
}
