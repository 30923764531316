import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { IProduct } from "@snackpass/snackpass-types";
import { adjustPriceDollars } from "@snackpass/menus-sdk";

import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import { RowDragHandle } from "src/@/components/ui/data-table/row-drag-handle";
import { useDataTable } from "src/@/components/ui/data-table";
import { Switch } from "src/@/components/ui/switch";
import { Button } from "src/@/components/ui/button";
import { DataTableComponent } from "src/@/components/ui/data-table/data-table-component";
import { Category } from "#menu-manager/hooks";
import { toDollarFormatted } from "#reports/sales-summary/lib";
import { CategoryItems } from "#menu-manager/components/menu-form/schema";
import { FormCard, FormCardProps } from "src/@/components/form-card";
import { PriceAdjustment } from "src/api/graphql/generated/types";
import { TooltipProvider } from "src/@/components/ui/tooltip";
import {
    HybridTooltip,
    HybridTooltipTrigger,
    HybridTooltipContent,
} from "src/@/components/ui/HybridTooltip";
import { MenuFormTitles } from "#menu-manager/components/menu-form/lib";

import { SelectCategoriesDialog } from "./SelectCategoriesDialog";

type CategoryItemsProps = Omit<FormCardProps, "title" | "subtitle"> &
    Omit<CategoryItemsInputProps, "dialogOpen" | "setDialogOpen">;

export function CategoryItemsCard({
    items,
    categories,
    priceAdjustment,
    ...props
}: CategoryItemsProps) {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    return (
        <FormCard
            title={MenuFormTitles.Items}
            subtitle={
                "Add categories to your menu. Toggling off an item that appears in multiple categories will only hide it in that category."
            }
            {...props}
        >
            <CategoryItemsInput
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                priceAdjustment={priceAdjustment}
                items={items}
                categories={categories}
            />
            <Button
                variant="outline"
                size="sm"
                type="button"
                onClick={() => setDialogOpen(true)}
            >
                <PlusCircledIcon className="mr-1" />
                Add Items
            </Button>
        </FormCard>
    );
}

export type CategoryItemsInputProps = {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    priceAdjustment?: Pick<
        PriceAdjustment,
        "adjustmentType" | "roundTo" | "value"
    >;
    categories: Category[];
    items: Pick<IProduct, "name" | "_id" | "price">[];
    className?: string;
};

export function CategoryItemsInput({
    dialogOpen,
    setDialogOpen,
    priceAdjustment,
    items,
    categories,
    className,
}: CategoryItemsInputProps) {
    const form = useFormContext<CategoryItems>();

    const categoryIDs = form.watch("categories");
    const productExclusionIDs = form.watch("productExclusions");

    const deleteCategory = useCallback(
        (id: string) => {
            form.setValue(
                "categories",
                categoryIDs.filter((e) => e !== id),
            );
        },
        [categoryIDs, form],
    );

    const toggleItemDisabled = useCallback(
        (id: string, disabled: boolean) => {
            const newExclusions: string[] = disabled
                ? productExclusionIDs.filter((e) => e !== id)
                : [...productExclusionIDs, id];
            form.setValue("productExclusions", newExclusions);
        },
        [productExclusionIDs, form],
    );

    // NB: We use this pattern of passing in functions that require component state into the data of each row
    // because row DnD requires that the column reference does not mutate.
    const data: MenuCategoryRow[] = useMemo(
        () =>
            categoryIDs
                .map((id) => categories.find((category) => category.id === id))
                .filter((e): e is Category => !!e)
                .map(({ productIds, ...category }) => ({
                    ...category,
                    deleteCategory,
                    subRows: productIds
                        .map<MenuCategoryRow | undefined>((id) => {
                            const item = items.find((e) => e._id === id);
                            if (!item) return undefined;
                            return {
                                id: item._id,
                                name: item.name,
                                price: item.price,
                                adjustedPrice: adjustPriceDollars(
                                    item.price,
                                    priceAdjustment,
                                ),
                                disabled: productExclusionIDs.includes(
                                    item._id,
                                ),
                                toggleItemDisabled,
                            };
                        })
                        .filter((e): e is MenuCategoryRow => !!e),
                })),
        [
            categories,
            categoryIDs,
            deleteCategory,
            items,
            priceAdjustment,
            productExclusionIDs,
            toggleItemDisabled,
        ],
    );

    const table = useDataTable({
        data,
        columns,
        getRowId: (row) => row.id,
        customPageSize: 999999,
        defaultExpanded: true,
    });

    const pushIDs = useCallback(
        (ids: string[]) => {
            form.setValue("categories", [...categoryIDs, ...ids]);
            table.toggleAllRowsExpanded(true);
        },
        [categoryIDs, form, table],
    );

    return (
        <>
            <FormField
                control={form.control}
                name="categories"
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <div>
                                <DataTableComponent
                                    className={clsx(
                                        "rounded-none border-x-0",
                                        className,
                                    )}
                                    table={table}
                                    getRowClassName={(row) =>
                                        clsx(
                                            row.depth === 0
                                                ? "bg-neutral-300"
                                                : "data-[state=selected]:bg-neutral-50",
                                        )
                                    }
                                    dragAndDropOptions={{
                                        getRowId: (item) => item.id,
                                        setData: (action) => {
                                            if (Array.isArray(action))
                                                field.onChange(
                                                    action.map((e) => e.id),
                                                );
                                            else
                                                field.onChange(
                                                    action(data).map(
                                                        (e) => e.id,
                                                    ),
                                                );
                                        },
                                    }}
                                    emptyText="This menu has no items."
                                />
                            </div>
                        </FormControl>
                        <FormDescription />
                        <FormMessage />
                    </FormItem>
                )}
            />
            <SelectCategoriesDialog
                categories={categories}
                open={dialogOpen}
                close={() => setDialogOpen(false)}
                selectedCategoryIDs={categoryIDs}
                onChange={pushIDs}
            />
        </>
    );
}

export type MenuCategoryRow = {
    name: string;
    id: string;
    subRows?: MenuCategoryRow[];
    price?: number;
    adjustedPrice?: number;
    disabled?: boolean;
    deleteCategory?: (id: string) => void;
    toggleItemDisabled?: (id: string, disabled: boolean) => void;
};

const columns: ColumnDef<MenuCategoryRow>[] = [
    {
        id: "dragHandle",
        header: () => null,
        accessorFn: () => null,
        cell: ({ row }) =>
            row.depth === 0 ? (
                <div>
                    <RowDragHandle
                        uniqueRowId={row.original.id}
                        className="h-4 px-2"
                    />
                </div>
            ) : null,
        size: 40,
    },
    {
        header: "Item",
        id: "name",
        accessorKey: "name",
        cell: ({ row }) => (
            <div className="flex items-center space-x-5">
                <span>{row.original.name}</span>
                {row.depth === 0 ? (
                    <span className="text-neutral-600">
                        {
                            row.original.subRows?.filter(
                                ({ disabled }) => !disabled,
                            ).length
                        }
                    </span>
                ) : null}
            </div>
        ),
        sortingFn: "alphanumeric",
        size: 1000,
    },
    {
        header: () => "Price",
        id: "Price",
        accessorKey: "price",
        cell: ({ row }) => {
            if (
                row.original.price === undefined ||
                row.original.adjustedPrice === undefined
            )
                return null;

            if (row.original.adjustedPrice === row.original.price)
                return toDollarFormatted(row.original.price);

            return (
                <TooltipProvider delayDuration={100}>
                    <HybridTooltip>
                        <HybridTooltipTrigger asChild>
                            <div>
                                {`${toDollarFormatted(row.original.adjustedPrice)}*`}
                            </div>
                        </HybridTooltipTrigger>
                        <HybridTooltipContent
                            className={clsx(
                                "z-50 whitespace-normal rounded-md bg-neutral-900 px-3 py-2 text-center text-xs font-normal text-neutral-50",
                            )}
                            sideOffset={5}
                        >
                            {`Original Price: ${toDollarFormatted(row.original.price)}`}
                        </HybridTooltipContent>
                    </HybridTooltip>
                </TooltipProvider>
            );
        },
        size: 40,
    },
    {
        header: () => <div className="text-center">Visible</div>,
        id: "Visible",
        accessorKey: "visible",
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        cell: ({ row }) => {
            if (row.depth === 0)
                return (
                    <div className="text-right">
                        <Button
                            variant={"ghost"}
                            className="h-5 bg-transparent px-2"
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                row.original.deleteCategory?.(row.original.id);
                            }}
                        >
                            <XMarkIcon className="h-4 w-4" />
                        </Button>
                    </div>
                );

            return (
                <div className="text-center">
                    <Switch
                        checked={!row.original.disabled}
                        aria-label={`exclude item ${row.original.name}`}
                        onCheckedChange={(checked) =>
                            row.original.toggleItemDisabled?.(
                                row.original.id,
                                checked,
                            )
                        }
                    />
                </div>
            );
        },
    },
];
