import React, { useContext, useMemo, useState } from "react";
import { debounce } from "radash";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";

import Text from "#devices/components/Text";
import {
    validateScreenSerial,
    validatePrinterSerial,
} from "#devices/utils/serialUtils";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { Button } from "src/@/components/ui/button";
import api from "src/api/rest";
import theme from "#devices/components/theme";
import { DeviceType } from "#devices/utils/deviceTypes";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { CenteredSpin } from "#devices/components/Loading";
import { useEnableKeyBasedRegistration } from "#navigation/utils";

const Gap = styled.div`
    margin-bottom: 10px;
`;

const FormControlStyles = styled.div`
    input::placeholder {
        opacity: 0.5;
    }
`;

const DEVICES_NO_CHECK_ID = [DeviceType.CashDrawer, DeviceType.PrepStation];

const Step2 = (): JSX.Element => {
    const {
        addNewDeviceTypeValue,
        addNewScreenSerialValue,
        setAddNewScreenSerialValue,
        previousStep,
        nextStep,
    } = useContext(DevicesPageContext);

    const [checking, setChecking] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined,
    );

    const enableKeyBasedRegistration = useEnableKeyBasedRegistration();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddNewScreenSerialValue(
            addNewDeviceTypeValue === DeviceType.StripeTerminal ||
                (enableKeyBasedRegistration &&
                    addNewDeviceTypeValue === DeviceType.Kiosk)
                ? event.target.value.trim()
                : event.target.value.toUpperCase(),
        );
        setErrorMessage(undefined);
    };

    const invalidEntry =
        addNewDeviceTypeValue === DeviceType.Printer
            ? !validatePrinterSerial(addNewScreenSerialValue)
            : !validateScreenSerial(addNewScreenSerialValue);

    const checkValidId = () => {
        setErrorMessage(undefined);
        if (
            addNewDeviceTypeValue === DeviceType.StripeTerminal ||
            (enableKeyBasedRegistration &&
                addNewDeviceTypeValue === DeviceType.Kiosk)
        ) {
            nextStep();
            return;
        }

        setChecking(true);
        api.storeDevices
            .checkValidId(addNewScreenSerialValue, addNewDeviceTypeValue)
            .then(({ data: { valid } }) => {
                setChecking(false);
                if (valid) {
                    setErrorMessage(undefined);
                    nextStep();
                } else {
                    const message =
                        addNewDeviceTypeValue === DeviceType.Printer
                            ? "That serial code does not exist"
                            : "That setup code is not available";
                    setErrorMessage(message);
                }
            })
            .catch((error) => {
                setChecking(false);
                setErrorMessage(error.message);
            });
    };

    const debouncedIdCheck = useMemo(
        () => debounce({ delay: 300 }, checkValidId),
        [addNewScreenSerialValue, addNewDeviceTypeValue],
    );

    const checkIdBeforeContinue = () => {
        if (
            !DEVICES_NO_CHECK_ID.includes(
                addNewDeviceTypeValue as DeviceType,
            ) &&
            !invalidEntry &&
            !checking
        ) {
            return debouncedIdCheck();
        }
        setErrorMessage(undefined);
        nextStep();
    };

    const isNextDisabled = invalidEntry || !!errorMessage || checking;

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === "enter" && !isNextDisabled) {
            checkIdBeforeContinue();
        }
    };

    const stripeCodePlaceholder = useRandomTrinomial();

    /**
     * Main Devices:
     *
     *  KDS, Kiosk, OH, STV, Printer
     */
    const FormBody = ({
        label,
        placeholder,
        isValidFxn,
        minLength = 4,
        maxLength = 5,
    }: {
        label: string;
        placeholder: string;
        isValidFxn: (s: string) => boolean;
        minLength?: number;
        maxLength?: number;
    }) => (
        <FormControlStyles>
            <Form.Group controlId="addNewScreen">
                <Text.Label>{label}:</Text.Label>
                <Gap />
                <Form.Control
                    autoFocus
                    size="lg"
                    type="text"
                    placeholder={placeholder}
                    required
                    value={addNewScreenSerialValue}
                    onChange={handleChange}
                    minLength={minLength}
                    maxLength={maxLength}
                    isValid={isValidFxn(addNewScreenSerialValue)}
                    isInvalid={invalidEntry || !!errorMessage}
                    onKeyDown={handleKeyDown}
                />
                <InvalidFeedback>{errorMessage}</InvalidFeedback>
            </Form.Group>
        </FormControlStyles>
    );

    /**
     * Shared Footer
     */
    const Footer = () => (
        <Modal.Footer>
            <Container fluid>
                <Row>
                    <Col sm={6}>
                        <Button
                            variant="outline"
                            className="mt-3 w-full md:mt-0"
                            onClick={previousStep}
                        >
                            Back
                        </Button>
                    </Col>
                    <Col sm={6}>
                        <Button
                            className="mt-3 w-full md:mt-0"
                            onClick={checkIdBeforeContinue}
                            disabled={isNextDisabled}
                        >
                            {checking ? <CenteredSpin small /> : "Next"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Footer>
    );

    /**
     * Printer
     */
    if (addNewDeviceTypeValue === DeviceType.Printer) {
        return (
            <>
                <Modal.Body>
                    <Header title="Add New Printer" />
                    <FormBody
                        label="Device Serial"
                        placeholder="Enter printer serial"
                        isValidFxn={validatePrinterSerial}
                        maxLength={30} // long enough to accept printer serial
                    />
                </Modal.Body>
                <Footer />
            </>
        );
    }

    if (addNewDeviceTypeValue === DeviceType.StripeTerminal) {
        return (
            <>
                <Modal.Body>
                    <Text.Title2>Add New Stripe Terminal</Text.Title2>
                    <FormBody
                        label="Registration Code"
                        placeholder={stripeCodePlaceholder}
                        isValidFxn={(code) => /^[^-]+-[^-]+-[^-]+$/.test(code)}
                        minLength={6}
                        maxLength={100}
                    />
                </Modal.Body>
                <Footer />
            </>
        );
    }

    if (
        addNewDeviceTypeValue === DeviceType.Kiosk &&
        enableKeyBasedRegistration
    ) {
        return (
            <>
                <Modal.Body>
                    <Text.Title2>Add New SnackOS</Text.Title2>
                    <FormBody
                        label="Registration Code"
                        placeholder={stripeCodePlaceholder}
                        isValidFxn={(code) => /^[^-]+-[^-]+-[^-]+$/.test(code)}
                        minLength={6}
                        maxLength={100}
                    />
                </Modal.Body>
                <Footer />
            </>
        );
    }

    /**
     * Default (Kiosk, OrderHub, KDS, SnackTV)
     */
    return (
        <>
            <Modal.Body>
                <Header
                    title={`Add new ${getDeviceTypeName(
                        addNewDeviceTypeValue,
                    )}`}
                />
                <FormBody
                    label="Setup Code"
                    placeholder="Enter setup code"
                    isValidFxn={validateScreenSerial}
                />
            </Modal.Body>
            <Footer />
        </>
    );
};

export default Step2;

const Header = ({ title }: { title: string }) => (
    <>
        <Text.Title2>{title}</Text.Title2>
        <Text.LargeBody>
            This is the last 5 digits of your serial number found on your device
            in the bottom left corner.
        </Text.LargeBody>
        <Text.LargeBody>
            <span>TTY-NYN-</span>
            <span className="font-medium text-primary">XXXXX</span>
        </Text.LargeBody>
    </>
);

const InvalidFeedback = ({ children }: { children: React.ReactNode }) => (
    <Form.Control.Feedback
        type="invalid"
        style={{ fontSize: theme.typography.body.fontSize }}
    >
        {children}
    </Form.Control.Feedback>
);

// chatgpt poetry
const trinomials = [
    "sunset-eagle-river",
    "mountain-ocean-cloud",
    "forest-stream-meadow",
    "desert-cactus-sky",
    "prairie-wind-grass",
    "valley-stone-lake",
    "glacier-pine-snow",
    "canyon-mesa-dust",
    "seashore-sand-wave",
    "hilltop-breeze-mist",
];

const useRandomTrinomial = () => {
    const randomIndex = Math.floor(Math.random() * trinomials.length);
    const [trinomial] = useState(trinomials[randomIndex]);
    return trinomial;
};
