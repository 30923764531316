import { last } from "lodash";
import { useContext, useMemo } from "react";
import { Spinner } from "react-activity";
import { Col, Row } from "react-bootstrap";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";
import { KioskReader, SavedReader } from "@snackpass/snackpass-types";

import CalloutStat from "#devices/components/DetailsDrawer/CalloutStat";
import DetailsSection from "#devices/components/DetailsDrawer/DetailsSection";
import DescriptionList from "#devices/components/DetailsDrawer/DescriptionList";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import {
    getFormattedDate,
    getFormattedTime,
} from "#devices/components/DevicesTable/lib";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { ReactComponent as Tablet } from "src/assets/icons/tablet-small.svg";
import { ReactComponent as GroceryCart } from "src/assets/icons/grocery-cart.svg";
import { ReactComponent as Barcode } from "src/assets/icons/barcode.svg";
import { ReactComponent as Calendar } from "src/assets/icons/calendar-small.svg";
import { ReactComponent as AppVersion } from "src/assets/icons/software-version.svg";
import { ReactComponent as Charging } from "src/assets/icons/charging.svg";
import { ReactComponent as Wifi } from "src/assets/icons/wifi.svg";
import { ReactComponent as Internet } from "src/assets/icons/internet.svg";
import { ReactComponent as PrepStationIcon } from "src/assets/icons/prep-station.svg";
import { ReactComponent as Filter } from "src/assets/icons/device-setting.svg";
import { ReactComponent as USB } from "src/assets/icons/usb-connection.svg";
import { ReactComponent as Bluetooth } from "src/assets/icons/bluetooth.svg";
import { ReactComponent as CashDrawerIcon } from "src/assets/icons/cash-drawer-small.svg";
import { getDeviceStatus } from "#devices/utils/getDeviceStatus";
import { KioskDevice } from "#devices/utils/deviceTypes";
import DeviceLog from "#devices/components/DetailsDrawer/DeviceLog";
import { getDeviceConnection } from "#devices/utils/getDeviceConnection";
import { cleanNetwork } from "#devices/utils/cleanNetworkName";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import Text from "#devices/components/Text";
import { copyAndNotify } from "#utils/helpers";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { shouldHaveCashDrawerAddon } from "#devices/utils/shouldHaveCashDrawerAddon";
import { NetworkConnectivityHistoryChart } from "#devices/components/DetailsDrawer/network-connectivity-history-chart";
import { EditDeviceRotation } from "#devices/components/EditDrawer/Settings";
import { usePrepStationsEnabled } from "#navigation/utils";

const empty = "--";

const batteryHelp = (level?: number): string =>
    !level || level < 0 || level > 1 ? empty : `${(level * 100).toFixed(0)}%`;

const KioskDetails = () => {
    const {
        device,
        setShowRebootDeviceModal,
        networkReport,
        loadingNetworkReport,
    } = useContext(DevicesPageContext);
    const prepStationEnabled = usePrepStationsEnabled();
    const shouldShowCashDrawer = useMemo(
        () => device && shouldHaveCashDrawerAddon(device),
        [device],
    );

    const kioskDevice = device as KioskDevice;
    const { data: cashDrawers, isLoading: isCashDrawerLoading } =
        useCashDrawers();
    const linkedDrawer = useMemo(
        () => cashDrawers?.find((e) => e.device === device?.serial),
        [cashDrawers, device],
    );

    const deviceStatus = getDeviceStatus(
        kioskDevice,
        last(networkReport)?.status,
    );
    const deviceConnection = getDeviceConnection(kioskDevice.stats);

    const handleCopy = (value: string, field: string) =>
        copyAndNotify({ value, field });

    /**
     * Kiosk Reader Details
     */
    const StyledReaderDetail = (props: {
        label: string;
        value: string;
        last?: boolean;
        copyable?: boolean;
    }) => (
        <ReaderDetailWrapper
            last={props.last}
            onClick={() =>
                props.copyable ? handleCopy(props.value, props.label) : null
            }
            copyable={props.copyable}
        >
            <SmallHeader>{props.label}:</SmallHeader>
            <SmallDetail copyable={props.copyable}>
                {props.value.length > 20
                    ? props.value.slice(0, 17).concat("...")
                    : props.value}
            </SmallDetail>
        </ReaderDetailWrapper>
    );

    const readerDetails = ((
        reader?: KioskReader,
        savedReader?: SavedReader | null,
    ) => {
        const paymentProvider =
            kioskDevice.deviceDetails?.paymentProvider
                .slice(0, 1)
                .toUpperCase()
                .concat(
                    kioskDevice.deviceDetails?.paymentProvider
                        ?.slice(1)
                        .toLowerCase(),
                ) ?? empty;

        const softwareVersion = reader?.deviceSoftwareVersion ?? empty;

        const battery = batteryHelp(reader?.batteryLevel);

        const connectionIcon = ((connection) => {
            switch (connection?.toLowerCase()) {
                case "USB".toLowerCase():
                    return <USB />;
                case "Bluetooth".toLowerCase():
                    return <Bluetooth />;
                case "Internet".toLowerCase():
                // fall through on purpose
                default:
                    return <Internet />;
            }
        })(reader?.connectionType);

        return {
            icon: connectionIcon,
            paymentProvider,
            fields: [
                { label: "Connection", value: reader?.connectionType ?? empty },
                {
                    label: "Serial Number",
                    value: reader?.serialNumber ?? empty,
                    copyable: true,
                },
                {
                    label: "Softare Version",
                    value: softwareVersion,
                    copyable: true,
                },
                { label: "Battery", value: battery },
                {
                    label: "Connected",
                    value: reader?.isConnected ? "Yes" : "No",
                },
                {
                    label: "Last Ping Date",
                    value: getFormattedDate(reader?.lastPing),
                    copyable: true,
                },
                {
                    label: "Last Ping Time",
                    value: getFormattedTime(reader?.lastPing),
                    copyable: true,
                },
                {
                    label: "Saved",
                    value: savedReader?.serialNumber ?? empty,
                    copyable: true,
                },
            ],
        };
    })(
        kioskDevice.deviceDetails?.reader,
        kioskDevice.deviceDetails?.savedReader,
    );

    /**
     * Kiosk Device Details
     */
    return (
        <>
            <Row>
                <Col>
                    <CalloutStat
                        icon={<GroceryCart />}
                        statNumber={`${kioskDevice.deviceDetails.purchaseCount}`}
                        statText="Lifetime Orders"
                        highlightBorder
                        iconColor={SystemColors.v1.snackpass50}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Details">
                        <DescriptionList
                            items={[
                                {
                                    term: "Device",
                                    icon: <Tablet />,
                                    description:
                                        getDeviceTypeName(
                                            kioskDevice?.deviceType,
                                        ) || "",
                                },
                                {
                                    term: "Status · Last Active",
                                    icon: loadingNetworkReport ? (
                                        <Spinner size={8} />
                                    ) : (
                                        <StatusCircleIcon
                                            width={8}
                                            height={8}
                                            fill={deviceStatus.color}
                                        />
                                    ),
                                    description: loadingNetworkReport
                                        ? undefined
                                        : deviceStatus.statusWithLastActiveTime,
                                },
                                {
                                    term: "Snack ID",
                                    icon: <Barcode />,
                                    description: kioskDevice?.snackId,
                                    copyable: true,
                                },
                                {
                                    term: "Installed",
                                    icon: <Calendar />,
                                    description: getFormattedDate(
                                        kioskDevice?.createdAt,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Software Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "App Version",
                                    icon: <AppVersion />,
                                    description:
                                        kioskDevice.stats?.appVersion ?? "--",
                                    copyable: true,
                                },
                                {
                                    term: "Allow Card Payments",
                                    icon: <Filter />,
                                    description: kioskDevice.deviceDetails
                                        .allowCardPayments
                                        ? "On"
                                        : "Off",
                                },
                                {
                                    term: "Allow Cash Payments",
                                    icon: <Filter />,
                                    description: kioskDevice.deviceDetails
                                        .allowCashPayments
                                        ? "On"
                                        : "Off",
                                },
                                {
                                    term: "Allow Other Payments",
                                    icon: <Filter />,
                                    description: kioskDevice.deviceDetails
                                        .allowOtherPayments
                                        ? "On"
                                        : "Off",
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Hardware Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Model",
                                    icon: <Tablet />,
                                    description: `${kioskDevice?.hardwareMake} ${kioskDevice?.hardwareModel}`,
                                    copyable: true,
                                },
                                {
                                    term: "Serial Number",
                                    icon: <Barcode />,
                                    description: kioskDevice?.serial,
                                    copyable: true,
                                },
                                {
                                    term: "Battery %",
                                    icon: <Charging />,
                                    description:
                                        `${kioskDevice?.stats?.batteryPercent}%` ??
                                        empty,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Addon Info">
                        {!isCashDrawerLoading && shouldShowCashDrawer ? (
                            <DescriptionList
                                items={[
                                    {
                                        term: "Cash Drawer",
                                        icon: <CashDrawerIcon />,
                                        description:
                                            linkedDrawer?.name ?? "None",
                                    },
                                ]}
                            />
                        ) : null}
                        {/* NB: Hard code prep station assignment for SnackOS to not be enabled for the time being due to a blocker.
                            We want to unblock the rollout of new prep station features for printers. */}
                        {prepStationEnabled && false && (
                            <DescriptionList
                                items={[
                                    {
                                        term: "Prep Station",
                                        icon: <PrepStationIcon />,
                                        description:
                                            kioskDevice.deviceDetails
                                                .prepStationDetails?.name ??
                                            "None",
                                    },
                                ]}
                            />
                        )}
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <DetailsSection heading="Network">
                    <DescriptionList
                        items={[
                            {
                                term: "Connection",
                                icon: <Internet />,
                                description: deviceConnection.type,
                            },
                            {
                                term: "Network Name",
                                icon: <Wifi />,
                                description: cleanNetwork(
                                    deviceConnection.name,
                                ),
                                copyable: true,
                            },
                        ]}
                    />
                    {networkReport ? (
                        <NetworkConnectivityHistoryChart
                            report={networkReport}
                        />
                    ) : null}
                </DetailsSection>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Quick Actions" bottom>
                        <Row>
                            <Col sm={12}>
                                <p className="mb-4">
                                    <Button
                                        variant="outline"
                                        className="w-full text-base"
                                        onClick={() =>
                                            setShowRebootDeviceModal(true)
                                        }
                                    >
                                        Reboot Device
                                    </Button>
                                </p>
                            </Col>
                            <Col sm={12}>
                                <EditDeviceRotation />
                            </Col>
                        </Row>
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Connected Devices">
                        <Text.Small>Card Reader</Text.Small>
                        <StyledCardHeader>
                            {readerDetails.icon}
                            {`  ${readerDetails.paymentProvider} Reader`}
                        </StyledCardHeader>
                        {readerDetails.fields.map((f, i) => (
                            <StyledReaderDetail
                                key={i}
                                label={f.label}
                                value={f.value}
                                last={readerDetails.fields.length - 1 === i}
                                copyable={f.copyable}
                            />
                        ))}
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DetailsSection heading="Device Log">
                        <DeviceLog logItems={kioskDevice.events ?? []} />
                    </DetailsSection>
                </Col>
            </Row>
        </>
    );
};

const ReaderDetailWrapper = styled.div<{ last?: boolean; copyable?: boolean }>`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: ${({ last }) => (last ? "24px" : "5px")};
    cursor: ${({ copyable }) => (copyable ? "pointer" : "auto")};
`;

const SmallHeader = styled(Text.Small)`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #606c76;
`;

const SmallDetail = styled(Text.Small)<{ copyable?: boolean }>`
    margin-left: 5px;
    line-height: 18px;
    border-radius: 5px;
    transition: background-color 0.5s ease;
    color: #282d32;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    &:hover {
        background-color: ${({ copyable }) =>
            copyable ? "rgba(0, 0, 0, 0.10)" : "auto"};
    }
`;

export default KioskDetails;

const StyledCardHeader = styled.div`
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin: 5px 0;
`;
