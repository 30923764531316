import React, { useState, useEffect, useMemo } from "react";
import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "src/@/components/ui/carousel";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import Autoplay from "embla-carousel-autoplay";
import { shortcuts } from "./shortcuts-data";
import { ShortcutsCard } from "./shortcuts-card";

export function ShortcutsCarousel() {
    const store = useSelector(getActiveStore);

    const [current, setCurrentSlide] = useState(0);
    const [api, setApi] = useState<CarouselApi>();
    const [count, setCount] = useState(0);
    const [shouldAutoplay, setShouldAutoplay] = useState(false);

    // only autoplay the first time user sees the carousel
    useEffect(() => {
        const hasSeenCarousel = localStorage.getItem("hasSeenCarousel");
        if (!hasSeenCarousel) {
            setShouldAutoplay(true);
            localStorage.setItem("hasSeenCarousel", "true");
        }
    }, []);

    useEffect(() => {
        if (!api) {
            return;
        }

        setCount(api.scrollSnapList().length);
        setCurrentSlide(api.selectedScrollSnap() + 1);

        api.on("select", () => {
            setCurrentSlide(api.selectedScrollSnap() + 1);
        });
    }, [api]);

    const autoplayPlugin = useMemo(
        () =>
            shouldAutoplay
                ? Autoplay({
                      delay: 4000,
                      stopOnFocusIn: true,
                      stopOnInteraction: true,
                      stopOnLastSnap: true,
                  })
                : undefined,
        [shouldAutoplay],
    );

    return (
        <Carousel
            className="w-full mx-auto max-w-4xl"
            setApi={setApi}
            plugins={autoplayPlugin ? [autoplayPlugin] : []}
            opts={{
                align: "start",
            }}
        >
            <CarouselContent className="-ml-4">
                {shortcuts.map((shortcut) => (
                    <CarouselItem
                        key={shortcut.id}
                        className="pl-4 basis-[90%] xs:basis-[60%] md:basis-[40%]"
                    >
                        <div className="p-2 py-6">
                            <ShortcutsCard shortcut={shortcut} store={store} />
                        </div>
                    </CarouselItem>
                ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
            <div className="py-2 text-center text-sm text-muted-foreground">
                {current} of {count}
            </div>
        </Carousel>
    );
}
