/** @jsxImportSource @emotion/react */
import { getLineItems } from "@snackpass/accounting";
import { SystemColors } from "@snackpass/design-system";
import { IPurchase, RefundSource } from "@snackpass/snackpass-types";
import { Drawer as AntdDrawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Dispatch } from "redux";
import { useGate } from "statsig-react";
import styled from "styled-components";
import { toast } from "sonner";

import config from "#config";
import { Button } from "#legacy-components";
import { RefundSummary } from "#order-details/components/refund-summary";
import { Header } from "#refund-modal/components/header";
import { Items } from "#refund-modal/components/items";
import { Upcharge } from "#refund-modal/components/upcharge";
import { AdjustmentAmount, Subtitle } from "#refund-modal/lib";
import { colors } from "#utils/colors";

import api from "src/api/rest";
import { setActivePurchase } from "src/redux/slices";
import { getActiveStore } from "src/redux/selectors";

import { REFUND_REASONS } from "./reasons";
import { REFUND_TYPES } from "./refund-types";

type Props = {
    isVisible: boolean;
    hide: () => void;
    purchase: IPurchase;
    titleOverride?: string;
    hideAdjustmentType?: boolean;
};

function RefundModal({
    isVisible,
    hide,
    purchase,
    titleOverride,
    hideAdjustmentType,
}: Props) {
    const allowMultipleRefunds = useGate(
        config.featureGates.allowMultipleRefunds,
    );
    const [isRefunding, setIsRefunding] = useState(false);
    const [reason, setReason] = useState<string | null>(null);
    const [refundType, setRefundType] = useState<string>(
        REFUND_TYPES.FULL_REFUND,
    );
    const [partialRefundBuffer, setPartialRefundBuffer] = useState<string>("0");
    const [upchargeBuffer, setUpchargeBuffer] = useState<string>("0");
    const [disableRefund, setDisableRefund] = useState<boolean>(true);
    const [selectedProductIds, setSelectedProductsIds] = useState<string[]>([]);
    const [isKioskOrder, setIsKioskOrder] = useState<boolean>(
        purchase?.transactionSource === "kiosk",
    );
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const needPin = activeStore?.hasEmployeeAuditTracking || false;
    const [pin, setPin] = useState<string>("");
    const onChange = (evt: any) => setPartialRefundBuffer(evt.target.value);

    useEffect(() => {
        if (hideAdjustmentType) {
            setRefundType(REFUND_TYPES.FULL_REFUND);
        }
    }, [hideAdjustmentType]);

    useEffect(() => {
        const isPartialRefund = refundType === REFUND_TYPES.PARTIAL_REFUND;
        const isFullRefund = refundType === REFUND_TYPES.FULL_REFUND;
        const isUpcharge = refundType === REFUND_TYPES.UPCHARGE;
        const isPartialRefundReady = isPartialRefund
            ? parseFloat(partialRefundBuffer) !== 0
            : false;
        const isUpchargeReady = isUpcharge
            ? parseFloat(upchargeBuffer) !== 0
            : false;

        if (isPartialRefund) {
            if (reason && isPartialRefundReady) {
                setDisableRefund(false);
            } else {
                setDisableRefund(true);
            }
        } else if (isFullRefund) {
            if (reason) {
                setDisableRefund(false);
            } else {
                setDisableRefund(true);
            }
        } else {
            // in case of upcharge
            if (isKioskOrder) {
                setDisableRefund(true);
            } else if (isUpchargeReady) {
                setDisableRefund(false);
            } else {
                setDisableRefund(true);
            }
        }
        if (needPin) {
            setDisableRefund(pin.length <= 0);
        }
    }, [pin, reason, refundType, upchargeBuffer, partialRefundBuffer]);

    const _upchargePurchase = () => {
        upchargePurchase(
            purchase,
            dispatch,
            setIsRefunding,
            hide,
            upchargeBuffer,
            pin,
        );
    };

    const _refundPurchase = async () =>
        refundPurchase(
            purchase,
            dispatch,
            setIsRefunding,
            hide,
            reason,
            refundType,
            partialRefundBuffer,
            pin,
            selectedProductIds,
        );

    const isMobile = useMediaQuery({
        query: `(max-width: 990px)`,
    });

    // screen for refunded and partially refunded purchase
    if (
        (purchase.refund && !purchase.partialRefund) ||
        (!allowMultipleRefunds.value &&
            (purchase.partialRefund || purchase.upCharge))
    ) {
        return (
            <AntdDrawer
                destroyOnClose={true}
                placement="right"
                onClose={hide}
                open={isVisible}
                closable={false}
                forceRender={true}
                width={isMobile ? "90%" : "40%"}
                zIndex={9002}
            >
                <Header title="Refund / Adjust Order" handleClick={hide} />
                <RefundSummary purchase={purchase} />
            </AntdDrawer>
        );
    }

    return (
        <AntdDrawer
            destroyOnClose={false}
            placement="right"
            onClose={hide}
            open={isVisible}
            closable={false}
            forceRender={true}
            width={isMobile ? "90%" : "40%"}
            zIndex={9002}
        >
            <Header
                title={titleOverride ?? "Refund / Adjust Order"}
                handleClick={hide}
            />
            <RefundSummary purchase={purchase} />
            {hideAdjustmentType ? null : (
                <Section>
                    <Subtitle>New Adjustment Type</Subtitle>
                    <TypeSelection>
                        {renderRefundTypes(refundType, setRefundType)}
                    </TypeSelection>
                </Section>
            )}

            {refundType === REFUND_TYPES.PARTIAL_REFUND ? (
                <>
                    <Section>
                        <Subtitle> Items</Subtitle>
                        <Items
                            purchase={purchase}
                            setPartialRefundBuffer={setPartialRefundBuffer}
                            setSelectedProductsIds={setSelectedProductsIds}
                        />
                    </Section>
                    <Section>
                        <Subtitle> Adjustment Amount</Subtitle>
                        <AdjustmentAmount>
                            <input
                                type="text"
                                name="currency"
                                value={partialRefundBuffer}
                                onChange={onChange}
                            />
                        </AdjustmentAmount>
                    </Section>
                </>
            ) : null}

            {refundType === REFUND_TYPES.UPCHARGE ? (
                <Section>
                    <Upcharge
                        purchase={purchase}
                        isKioskOrder={isKioskOrder}
                        setUpchargeBuffer={setUpchargeBuffer}
                        upchargeBuffer={upchargeBuffer}
                    />
                </Section>
            ) : null}

            {refundType === REFUND_TYPES.UPCHARGE ? null : (
                <Section>
                    <Subtitle> Please select a reason</Subtitle>
                    {renderRefundReasons(reason, setReason)}
                </Section>
            )}

            {needPin && (
                <Section>
                    <Subtitle>Employee Pin:</Subtitle>
                    <PinInput
                        type="text"
                        value={pin}
                        onChange={(e) => {
                            setPin(e.target.value);
                        }}
                    />
                </Section>
            )}

            <ConfirmButton
                disableRefund={disableRefund}
                isRefunding={isRefunding}
                refundPurchase={_refundPurchase}
                upchargePurchase={_upchargePurchase}
                reason={reason}
                refundType={refundType}
                partialRefundBuffer={partialRefundBuffer}
            />
        </AntdDrawer>
    );
}

function renderRefundTypes(
    refundType: string,
    setRefundType: (type: string) => void,
) {
    return Object.values(REFUND_TYPES).map((type, index) => {
        const isSelected = refundType === type;

        return (
            <RefundType
                key={index}
                isSelected={isSelected}
                onClick={() => {
                    setRefundType(type);
                }}
            >
                {type}
            </RefundType>
        );
    });
}

function renderRefundReasons(
    selectedReason: string | null,
    setReason: (reason: string) => void,
) {
    return REFUND_REASONS.map((reason, index) => {
        const isSelected = selectedReason === reason;

        return (
            <RefundReason
                key={index}
                isSelected={isSelected}
                onClick={() => {
                    setReason(reason);
                }}
            >
                {reason}
            </RefundReason>
        );
    });
}

type ConfirmButtonProps = {
    disableRefund: boolean;
    isRefunding: boolean;
    refundPurchase: () => void;
    upchargePurchase: () => void;
    reason: null | string;
    refundType: string;
    partialRefundBuffer: string;
};

function ConfirmButton({
    disableRefund,
    isRefunding,
    refundPurchase,
    upchargePurchase,
    reason,
    refundType,
    partialRefundBuffer,
}: ConfirmButtonProps) {
    let label = "";

    switch (refundType) {
        case REFUND_TYPES.FULL_REFUND:
            label = "Confirm Refund";
            break;
        case REFUND_TYPES.PARTIAL_REFUND:
            label = "Confirm Partial Refund";
            break;
        case REFUND_TYPES.UPCHARGE:
            label = "Confirm Upcharge";
            break;
        default:
            label = "Confirm";
    }

    return (
        <Button
            label={label}
            disabled={disableRefund}
            isLoading={isRefunding}
            backgroundColor={colors.candy50}
            onPress={
                refundType === REFUND_TYPES.UPCHARGE
                    ? upchargePurchase
                    : refundPurchase
            }
            style={{ width: "100%", fontWeight: 600, marginBottom: "65px" }}
            containerStyle={{ width: "100%" }}
        />
    );
}

const UPCHARGE_LIMIT = 500;

async function upchargePurchase(
    purchase: IPurchase,
    dispatch: Dispatch,
    setIsRefunding: (isRefunding: boolean) => void,
    handleHide: () => void,
    upchargeBuffer: string,
    pin: string,
) {
    const upchargeVal = parseFloat(upchargeBuffer);
    //basic checks
    if (isNaN(upchargeVal) || upchargeVal < 0) {
        toast.error("Please enter a valid dollar amount.");
        return;
    } else if (upchargeVal > UPCHARGE_LIMIT) {
        toast.error(
            `Please contact customer support for upcharge over $${UPCHARGE_LIMIT}`,
        );
        return;
    }
    setIsRefunding(true);

    try {
        const params = {
            upCharge: upchargeVal,
            productAdjustments: [],
            ...(pin.length > 0 ? { pin } : {}), // only add pin if it has a value
        };
        const res = await api.purchases.upcharge(purchase._id, params);
        dispatch(setActivePurchase(res.data.purchase));
    } catch (err: any) {
        const response = err.cause?.response;
        if (response && response.status === 403 && response.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error("An error occurred while upcharging this purchase.");
        }
    } finally {
        setIsRefunding(false);
        handleHide();
    }
}

async function refundPurchase(
    purchase: IPurchase,
    dispatch: Dispatch,
    setIsRefunding: (isRefunding: boolean) => void,
    handleHide: () => void,
    reason: string | null,
    refundType: string,
    partialRefundBuffer: string,
    pin: string,
    selectedProductIds: string[],
) {
    const { total } = getLineItems(purchase);
    const refundVal = parseFloat(partialRefundBuffer);
    //basic checks
    if (isNaN(refundVal) || refundVal < 0) {
        toast.error("Please enter a valid dollar amount.");
        return;
    } else if (refundVal > total) {
        toast.error("Refund cannot exceed order total.");
        return;
    } else if (reason === null) {
        toast.error("Please provide a reason.");
        return;
    }

    setIsRefunding(true);

    try {
        if (refundType === REFUND_TYPES.FULL_REFUND) {
            const params = {
                reason,
                refundSource: RefundSource.Dashboard,
                isKioskPurchase: purchase.transactionSource === "kiosk",
                ...(pin.length > 0 ? { pin } : {}), // only add pin if it has a value
            };
            const res = await api.purchases.refund(purchase._id, params);
            dispatch(setActivePurchase(res.data.purchase));
        } else {
            const params = {
                partialRefund: refundVal,
                reason,
                refundSource: RefundSource.Dashboard,
                isKioskPurchase: purchase.transactionSource === "kiosk",
                refundedProductIds: selectedProductIds,
                ...(pin.length > 0 ? { pin } : {}), // only add pin if it has a value
            };

            const res = await api.purchases.refund(purchase._id, params);
            dispatch(setActivePurchase(res.data.purchase));
        }
    } catch (err: any) {
        const response = err.cause?.response;
        if (
            response &&
            [403, 422].includes(response.status) &&
            response.data?.message
        ) {
            toast.error(response.data.message);
        } else {
            toast.error("An error occurred while refunding this purchase.");
        }
    } finally {
        setIsRefunding(false);
        handleHide();
    }
}

const Section = styled.div`
    margin-bottom: 15px;
    .DraftEditor-root {
        border-color: ${SystemColors.v1.gray80};
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        padding: 8px 15px !important;
        margin-top: 5px;
    }
`;

const TypeSelection = styled.div`
    display: flex;
    flex-direction: row;
    place-content: space-between;
`;

type RefundTypeProps = {
    isSelected: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const RefundType = styled.div<RefundTypeProps>`
    margin-top: 10px;
    width: 32%;
    color: ${(props) =>
        props.isSelected ? SystemColors.v1.white : SystemColors.v1.sesame};
    background-color: ${(props) =>
        props.isSelected ? SystemColors.v1.candy50 : SystemColors.v1.white};
    border-radius: 8px;
    padding: 7px 15px;
    align-self: stretch;
    cursor: pointer;
    border-color: ${(props) =>
        props.isSelected ? SystemColors.v1.candy50 : SystemColors.v1.gray80};
    border-width: 1px;
    border-style: solid;
    font-weight: 600;
    text-align: center;
    &:hover {
        box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.05);
    }
`;

type RefundTextBoxProps = {
    width: string;
    isBold: boolean;
};

const RefundTextBox = styled.div<RefundTextBoxProps>`
    margin-top: 10px;
    width: ${(props) => props.width};
    color: ${SystemColors.v1.sesame};
    background-color: ${SystemColors.v1.white};
    border-radius: 8px;
    padding: 7px 15px;
    align-self: stretch;
    border-color: ${SystemColors.v1.gray80};
    border-width: 1px;
    border-style: solid;
    font-weight: ${(props) => (props.isBold ? 600 : "normal")};
    text-align: start;
`;

type RefundReasonProps = {
    isSelected: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const RefundReason = styled.div<RefundReasonProps>`
    display: flex;
    margin: 5px 0px;
    border-radius: 8px;
    padding: 7px 15px;
    align-self: stretch;
    cursor: pointer;
    border-color: ${(props) =>
        props.isSelected ? SystemColors.v1.black : SystemColors.v1.gray80};
    border-width: 1px;
    border-style: solid;
    font-family: Inter;
    font-style: normal;
    font-weight: ${(props) => (props.isSelected ? "600" : "normal")};
    &:hover {
        box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.05);
    }
`;

const PinInput = styled.input`
    border-radius: 8px;
    margin-top: 10px;
    color: ${SystemColors.v1.sesame};
    background-color: ${SystemColors.v1.white};
    border-radius: 8px;
    padding: 7px 15px;
    align-self: stretch;
    border-color: ${SystemColors.v1.gray80};
    border-width: 1px;
    border-style: solid;
    font-weight: "normal";
    text-align: start;
`;

export default RefundModal;
