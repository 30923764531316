import React, { useContext, useMemo } from "react";
import clsx from "clsx";

import { Button } from "src/@/components/ui/button";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReportsContext } from "#app/reports-context-provider";
import { sourceOptions } from "#reports/sales-summary/lib";
import { SourceFilter } from "#reports/sales-summary/types";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";

export const PlatformFilterPicker = () => {
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { filter } = reportsState;

    const selectedValues = useMemo(
        () => new Set(filter.source as string[]),
        [filter.source],
    );

    return (
        <FilterSelect
            title={"Source"}
            selectedValues={selectedValues}
            options={sourceOptions}
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className={clsx(
                        "flex items-center space-x-2",
                        selectedValues.size > 0 && "border-neutral-800",
                    )}
                >
                    <FilterIcon className="h-4 w-4" />
                    <span>Source</span>
                </Button>
            }
            onOptionSelected={(value: string) => {
                if (selectedValues.has(value)) {
                    selectedValues.delete(value);
                } else {
                    selectedValues.add(value);
                }
                setReportsState((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        source: Array.from(selectedValues) as SourceFilter[],
                    },
                }));
            }}
        />
    );
};
