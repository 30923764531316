import { useState } from "react";
import { match } from "ts-pattern";
import { FabricObject } from "fabric";

import { useCanvasEffect } from "#table-editor/components/Editor/hooks";
import { Element } from "#table-editor/canvas/Element";
import { Element as Variant } from "src/api/graphql/generated/types";
import { LayoutProperty } from "#table-editor/types";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";

import { ModifyTable } from "./ModifyTable";
import { ModifyLabel } from "./ModifyLabel";
import { ModifyBarrier } from "./ModifyBarrier";

type ElementSelection = {
    id: string;
    properties: LayoutProperty;
};

export function ModifyElement() {
    const editor = useEditorStore();
    const [selection, setSelection] = useState<ElementSelection | null>(null);

    useCanvasEffect((canvas) => {
        const selectElement = (obj: FabricObject) => {
            if (obj.isType(Element.type)) {
                const element = obj as Element;

                const selectedElement = editor
                    .getState()
                    .elements.find((el) => el.id === element.id);

                if (selectedElement) {
                    setSelection({
                        id: element.id,
                        properties: selectedElement.properties
                    });
                }
            }
        };

        const creation = canvas.on("selection:created", (e) => {
            if (canvas.getActiveObjects()?.length === 1) {
                selectElement(e.selected[0]);
            } else {
                setSelection(null);
            }
        });

        const cleared = canvas.on("selection:cleared", () => {
            if (canvas.getActiveObjects()?.length === 0) {
                setSelection(null);
            }
        });

        const updated = canvas.on("selection:updated", (e) => {
            if (canvas.getActiveObjects()?.length === 1) {
                selectElement(e.selected[0]);
            } else {
                setSelection(null);
            }
        });

        return () => {
            creation();
            cleared();
            updated();
        };
    }, []);

    useCanvasEffect(
        (canvas) =>
            canvas.on("object:removed", (e) => {
                if (e.target.isType(Element.type)) {
                    const element = e.target as Element;

                    if (element.id === selection?.id) {
                        setSelection(null);
                    }
                }
            }),
        [selection]
    );

    return match(selection)
        .with({ properties: { type: Variant.Table } }, (element) => (
            <ModifyTable id={element.id} properties={element.properties} />
        ))
        .with({ properties: { type: Variant.Label } }, (element) => (
            <ModifyLabel id={element.id} properties={element.properties} />
        ))
        .with({ properties: { type: Variant.Barrier } }, (element) => (
            <ModifyBarrier id={element.id} />
        ))
        .otherwise(() => <EmptyPrompt />);
}

function EmptyPrompt() {
    return (
        <p className="text-wrap font-medium text-neutral-500">
            Select a table or a label to edit.
        </p>
    );
}
