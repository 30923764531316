import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, RouteComponentProps, Link } from "react-router-dom";
import { toast } from "sonner";
import useDeepCompareEffect from "use-deep-compare-effect";
import { captureException } from "@sentry/react";
import styled from "@emotion/styled";

import { PromoFormContainer } from "#promotion/components/shared/form-container";
import { FormSectionTitle } from "#promotion/components/shared/form-section-title";
import { PromoDiscountType } from "#promotion/components/promo-discount-type";
import { TitleBreadcrumbs } from "#promotion/components/title-bread-crumbs";
import { FormDropdownSelect } from "#promotion/components/shared/form-dropdown-select";
import {
    FIELD_NAMES,
    FormGiftCardState,
    FormSubmitState,
    FormUsageTypes,
    GiftCardPromo,
} from "#promotion/utils/types";
import { ReviewModal } from "#promotion/components/review-modal";
import { PromoName } from "#promotion/components/promo-name";
import { DISCOUNT_TYPES, DOLLAR_OPTIONS } from "#promotion/utils/constants";
import { FormSubmit } from "#promotion/components/shared/form-submit";
import { isPromoTypeChangeError } from "#promotion/lib";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { BackButton } from "#promotion/components/back-button";
import {
    GIFT_CARD_PERCENT_OPTIONS,
    GiftCardDefaultValues,
    GiftCardPromoValidationSchema,
} from "#promotion/utils/validation/form-schemas/gift-card";
import { FormNumberInput } from "#promotion/components/shared/form-number-input";
import { FormFieldName } from "#settings-integrations/components/shared/form-field-name";
import { Routes } from "#navigation/routes";
import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { PromoImage } from "#promotion/components/promo-image";

import { useSubmitPromo } from "./useSubmitPromo";

type Props = RouteComponentProps<object, object, FormGiftCardState>;

export const GiftCardPromotionForm = (props: Props) => {
    const { isMobile } = useWindowDimensions();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state } = props.location;
    const history = useHistory();
    const methods = useForm<GiftCardPromo>({
        // @ts-expect-error see https://stackoverflow.com/questions/69271892/react-hook-form-resolver-type-error-using-yup
        resolver: yupResolver(GiftCardPromoValidationSchema),
        mode: "all",
        defaultValues: GiftCardDefaultValues,
    });
    const submitPromo = useSubmitPromo();
    const { handleSubmit, watch, reset } = methods;
    const formDataState = watch();

    let formData: Partial<GiftCardPromo> = {};
    let formUsage =
        state.formUsage === "Create"
            ? FormUsageTypes.Create
            : FormUsageTypes.Edit;
    let promoId: string | undefined = state?.promoId || undefined;

    useDeepCompareEffect(() => {
        // If pushed to form with default values, use them!
        // This happens if the user is editing an existing promo
        // or using a shortcut to create a new promo.
        if (state) {
            formData = state.formData;
            formUsage =
                state.formUsage === "Create"
                    ? FormUsageTypes.Create
                    : FormUsageTypes.Edit;
            promoId = state.promoId;
            reset(formData); // This will set new defaultValues
        } else {
            // If no incoming values, then this is a new promo creation, use default values for promo type
            reset(GiftCardDefaultValues);
        }
    }, [state]);

    const openReviewModal = () => {
        setIsModalOpen(true);
    };

    // Will only be called if the form submission is valid based on the validation schema
    const onSubmitConfirmed = async () => {
        const submitState: FormSubmitState = {
            formUsage,
            success: false,
        };
        if (!isSubmitting) {
            setIsSubmitting(true);
            try {
                await submitPromo(formUsage, formDataState, promoId);
                // Set submit state to true so that notification shows up
                submitState.success = true;
                // Reset the form
                reset(GiftCardDefaultValues);
                // Go back to list of promos on success
                history.push("/promotion", submitState);
            } catch (err: any) {
                const message: string = err.response?.data?.message;
                // Handle Promo type change attempt/validation error
                // This occurs when a promotion's derived type during submit is different from its existing type
                if (isPromoTypeChangeError(message)) {
                    toast.error("Promo Type Change Detected", {
                        description:
                            "Fields were changed in a way that resulted in a different promo type being saved, which is not currently supported. \n\nPlease contact Support for help with editing this promotion.",
                    });
                } else {
                    // Only log to Sentry for non-handled errors
                    captureException(Error(JSON.stringify(err)));
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const discountType = watch(FIELD_NAMES.DISCOUNT_TYPE);

    return (
        <FormProvider {...methods}>
            <TitleContainer>
                {isMobile ? <BackButton /> : null}
                <TitleBreadcrumbs
                    title="Create a Gift Card Promotion"
                    breadcrumbs={[
                        ["Promos", "promotion"],
                        ["New Promo", ""],
                    ]}
                />
            </TitleContainer>
            <form onSubmit={handleSubmit(openReviewModal)}>
                <PromoFormContainer>
                    <FormSectionTitle title="Details" />
                    <PromoName placeholder="Gift Card Promo" />
                    <PromoDiscountType
                        hideFreeItemOption={true}
                        hideNewPriceOption={true}
                    />
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount (%)"
                            descriptor="Most stores start with 15% - 25% off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT}
                            options={GIFT_CARD_PERCENT_OPTIONS}
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `${val}%`}
                            required
                        />
                    )}
                    {discountType === DISCOUNT_TYPES.DOLLARS_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount ($)"
                            descriptor="Most stores start with $5 - $10 off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS}
                            options={DOLLAR_OPTIONS}
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                            required
                        />
                    )}
                    <FormNumberInput
                        name="Minimum Load"
                        fieldName={FIELD_NAMES.MINIMUM_PRICE}
                        format="dollar"
                        min={0}
                        descriptor={
                            "Minimum gift card value required to activate this promotion"
                        }
                    />
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormNumberInput
                            name="Maximum Discount"
                            fieldName={FIELD_NAMES.MAXIMUM_DISCOUNT}
                            format="dollar"
                            min={0}
                            descriptor={
                                "The maximum discount that this promotion can ever give"
                            }
                        />
                    )}
                    <div className="w-1/2">
                        <FormFieldName name="Qualifying Items" size="md" />
                        <FormFieldDescriptor
                            descriptor={
                                <>
                                    This promotion will apply to any Gift Cards
                                    you sell (physical and digital). See your
                                    Gift Card settings{" "}
                                    <Link to={Routes.SettingsGiftCard}>
                                        here
                                    </Link>
                                </>
                            }
                        />
                    </div>
                    <div className="mt-6">
                        <PromoImage />
                    </div>
                    <FormSubmit
                        isEdit={formUsage === FormUsageTypes.Edit}
                        submitting={isSubmitting}
                    />
                </PromoFormContainer>
            </form>
            <ReviewModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={onSubmitConfirmed}
                state={formDataState}
                formUsage={formUsage}
            />
        </FormProvider>
    );
};

const TitleContainer = styled.div`
    position: relative;
`;
