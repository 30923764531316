import { version } from "src/utils/version";

const isProduction = process.env.REACT_APP_ENV === "production";

export default {
    baseHost:
        process.env.REACT_APP_BASE_HOST || "https://staging-api.snackpass.co",
    apiUrl:
        process.env.REACT_APP_API_URL ||
        "https://staging-api.snackpass.co/api/v4",
    gqlUrl:
        process.env.REACT_APP_GQL_URL ||
        "https://staging-api.snackpass.co/graphql",
    menuGqlUrl:
        process.env.REACT_APP_MENU_GQL_URL ||
        "https://staging-graph.snackpass.co/graphql",
    menuBucketUrl:
        process.env.REACT_APP_MENU_BUCKET_URL ||
        "https://pub-1beb685e4630468ca9a27a04aa32e657.r2.dev",
    env: process.env.REACT_APP_ENV || "development",

    isProduction,
    isDevEnv: process.env.REACT_APP_ENV === "development",

    /**
     *  This key is different from other platform production keys. This is
     *  because it is useful to separate the restaurant vs. user flow
     */
    PRODUCTION_SEGMENT_API_KEY:
        process.env.REACT_APP_SEGMENT_API_KEY ||
        "rIMsjMDhpF0813sQnOYmxqXSAQHtZDk2",

    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,

    // Statsig calls feature flags feature gates.
    // Going to use this to have clean separation from existing Flagsmith flags above.
    featureGates: {
        multiMenus: "multi_menus",
        multistoreSalesSummaryReport: "rdb_multistore_sales_summary",
        blacklistedPhoneKioskRewards: "blacklisted_phone_kiosk_rewards",
        rdbReportsHidePayout: "rdb_reports_hide_payout",
        discountRewardAddonsEnabled: "rdb_discount_reward_addons",
        reportsLaborTiles: "rdb_reports_labor_tiles",
        selfServePromoCodeEnabled: "rdb_self_serve_promo_code",
        snackTvSettings: "snack_tv_settings",
        reportsGiftCard: "rdb_reports_gift_card",
        channelVisibilitySettings: "products_channel_filtering",
        duoSplashScreen: "allow_duo_splash_screen",
        dgcInMenu: "rdb_dgc_in_menu",
        selfServeTaxRate: "self_serve_tax_rate",
        invoices: "invoices",
        alcoholSalesEnabledToggle: "alcohol_sales_enabled_toggle",
        tableside: "tableside",
        prepStations: "prep_stations",
        stripeHostedPayoutsOnboarding: "stripe_hosted_payouts_onboarding",
        offlineModeEligibility: "rdb_offline_mode_eligibility",
        allowMultipleRefunds: "allow_multiple_refunds",
        menusV3: "menus_v3",
        prepStationChannelFulfillment: "prep_station_channel_fulfillment",
        enableKeyBasedRegistration: "enable_key_based_registration",
        tables: "register_tables",
    },

    /**
     * The stripe public key is intended to be used on client.
     */
    STRIPE_PUBLIC_KEY: isProduction
        ? "pk_live_4jjPSlr2HTi1f0QkTEnXsfLQ"
        : "pk_test_wKhXBkEzIdpkkTd0iEGLmhYR",

    pubnub: {
        conversations: {
            publishKey: process.env.REACT_APP_PUBNUB_CONVERSATIONS_PUBLISH_KEY,
            subscribeKey:
                process.env.REACT_APP_PUBNUB_CONVERSATIONS_SUBSCRIBE_KEY ??
                "not-set",
        },
    },

    STATSIG_ENVIRONMENT: process.env.REACT_APP_STATSIG_ENVIRONMENT,
    STATSIG_KEY: process.env.REACT_APP_STATSIG_KEY,

    firebaseApiKey:
        process.env.REACT_APP_FIREBASE_API_KEY ??
        "AIzaSyDFYc6u8l2RZ0WvoocxpNHTc3bsQ3slLQ8",
    firebaseAuthDomain:
        process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ??
        "futura-c7f12.firebaseapp.com",
    firebaseDatabaseUrl:
        process.env.REACT_APP_FIREBASE_DATABASE_URL ??
        "https://futura-c7f12.firebaseio.com",
    firebaseProjectId:
        process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "futura-c7f12",
    firebaseStorageBucket:
        process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ??
        "futura-c7f12.appspot.com",
    firebaseMessagingSenderId:
        process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "616491088441",

    /**
     * This version is generated by genversion and gets synced with
     * the package.json version during merged to main/master.
     */
    releaseVersion: `restaurant-dashboard@${version}`,
};
