// The CellInfo type extends RowInfo and typescript seems to not understand this, hence the next line we disable prop-types for this file
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import moment from "moment-timezone";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { usePurchaseReportRows } from "src/api/rest/usePurchaseReports";
import { DataTable } from "src/@/components/ui/data-table";

import { columns } from "./lib";

export const OrderHistory = () => {
    const { data, isLoading } = usePurchaseReportRows();

    const updatedTime = moment().format("h:mm a");

    const toolbarOptions = {
        showColumnFilter: true,
        search: {
            key: "Customer",
            placeholder: "Search Customer Name or Phone",
        },
    };

    if (isLoading)
        return (
            <>
                <Skeleton className="my-4 h-8 w-64" />
                <Skeleton className="h-52 w-full" />
            </>
        );

    return (
        <div>
            <h4 className="my-6 text-large">Order History</h4>
            <DataTable
                toolbar={toolbarOptions}
                columns={columns}
                data={data ?? []}
                showPagination={true}
                footerElement={
                    <span className="text-micro text-neutral-600 md:text-body">
                        Updated {updatedTime}
                    </span>
                }
                hiddenColumns={["Custom Surcharge", "Custom Discounts"]}
            />
        </div>
    );
};
