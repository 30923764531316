import { SystemColors } from "@snackpass/design-system";
import { IPurchase, PaymentProvider } from "@snackpass/snackpass-types";
import React from "react";
import { styled } from "styled-components";
import { CountryCode } from "libphonenumber-js";

import { ReactComponent as Mail } from "src/assets/icons/mail-letter.svg";
import { ReactComponent as Phone } from "src/assets/icons/phone-outgoing.svg";
import { ReactComponent as Pencil } from "src/assets/icons/pencil-over-paper.svg";
import { humanizePurchaseStatus } from "#order-details/utils";
import {
    getPOSIntegrationUserInfo,
    normalizePhoneNumber,
    purchaseIsThirdPartyDeliverect,
} from "#utils/helpers";

import { PrintButton } from "./print-button";
import { RefundButton } from "./refund-button";
import { RefundSummary } from "./refund-summary";
import { Card, Header, Title } from "./ui";

type Props = {
    purchase: IPurchase;
};

export const PurchaseCustomer = ({ purchase }: Props) => {
    const status = humanizePurchaseStatus(purchase, true);
    const userInfoSource = getPOSIntegrationUserInfo(purchase) || purchase;
    const isUnpaid = purchase.paymentProviderId === PaymentProvider.unpaid;
    return (
        <Card>
            <Title>
                Order #{purchase.receiptNumber} · {userInfoSource.user?.name}
            </Title>
            {status ? (
                <FulfillmentMessage color={status.color}>
                    {status.text}
                </FulfillmentMessage>
            ) : null}
            <RefundSummary purchase={purchase} />
            {purchase.notes ? (
                <FlexRow>
                    <Pencil fill={SystemColors.v1.sesame} />
                    <MarginLeft />
                    <Header>Order Note:</Header>
                    <MarginLeft />
                    <div className="flex-1">{purchase.notes}</div>
                </FlexRow>
            ) : null}
            {userInfoSource.user?.number ? (
                <FlexRow>
                    <Phone fill={SystemColors.v1.sesame} />
                    <MarginLeft />
                    <Header>
                        {purchase.user?.countryCode &&
                        purchase.user.countryCode !== "US" ? (
                            <div>
                                <span className="mr-2 text-critical-light">
                                    Non-US ({purchase.user.countryCode})
                                </span>
                                {normalizePhoneNumber(
                                    userInfoSource.user.number,
                                    purchase.user.countryCode as CountryCode,
                                )}
                            </div>
                        ) : (
                            normalizePhoneNumber(userInfoSource.user?.number)
                        )}
                    </Header>
                </FlexRow>
            ) : null}
            {userInfoSource.user?.email ? (
                <FlexRow>
                    <Mail fill={SystemColors.v1.sesame} />
                    <MarginLeft />
                    <Header>{userInfoSource.user?.email}</Header>
                </FlexRow>
            ) : null}
            <FlexRow>
                <PrintButton purchase={purchase} />
                <MarginLeft />
                {!isUnpaid && !purchaseIsThirdPartyDeliverect(purchase) ? (
                    <RefundButton purchase={purchase} />
                ) : null}
            </FlexRow>
        </Card>
    );
};

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
`;

const FulfillmentMessage = styled(Title)<{ color: string }>`
    color: ${({ color }) => color};
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${({ color }) => color};
`;

const MarginLeft = styled.div`
    margin-left: 8px;
`;
