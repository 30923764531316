import * as _ from "radash";
import { match } from "ts-pattern";

import {
    Properties as GQLProperties,
    TableProperties as GQLTableProperties,
    LabelProperties as GQLLabelProperties,
    BarrierProperties as GQLBarrierProperties,
    Geometry as GQLGeometry,
    RectGeometry as GQLRectGeometry,
    DiamondGeometry as GQLDiamondGeometry,
    CircleGeometry as GQLCircleGeometry,
    Element as Variant,
    Shape,
    ServiceAreaFieldsFragment,
    CreateServiceAreaLayout,
    CreateRectGeometry,
    CreateDiamondGeometry,
    CreateCircleGeometry,
    ShapeStroke
} from "src/api/graphql/generated/types";
import {
    CircleGeometry,
    DiamondGeometry,
    LabelProperty,
    LayoutElement,
    LayoutGeometry,
    LayoutProperty,
    RectGeometry,
    ServiceArea,
    TableProperty
} from "#table-editor/types";
import { Element } from "#table-editor/canvas/Element";
import { LABEL_STROKE_WIDTH } from "#table-editor/canvas/defaults";

type Table = ServiceAreaFieldsFragment["tables"][number];

export function fromGQLServiceArea(
    serviceArea: ServiceAreaFieldsFragment
): ServiceArea {
    const tablesMap = _.objectify(serviceArea.tables, (table) => table.id);

    const elements = serviceArea.layout
        .map<LayoutElement | null>((element) => {
            const geometry = makeLayoutGeometry(element.geometry);
            const properties = makeLayoutProperty(
                element.properties,
                tablesMap
            );

            if (geometry) {
                return {
                    id: element.id,
                    geometry,
                    properties
                };
            } else {
                return null;
            }
        })
        .filter(Boolean) as LayoutElement[];

    return {
        id: serviceArea.id,
        name: serviceArea.name,
        layout: elements
    };
}

const isRectGeometry = (geometry: GQLGeometry): geometry is GQLRectGeometry =>
    geometry.type === Shape.Rect;
const isDiamondGeometry = (
    geometry: GQLGeometry
): geometry is GQLDiamondGeometry => geometry.type === Shape.Diamond;
const isCircleGeometry = (
    geometry: GQLGeometry
): geometry is GQLCircleGeometry => geometry.type === Shape.Circle;

function makeLayoutGeometry(geometry: GQLGeometry): LayoutGeometry | null {
    return match(geometry)
        .when(
            isRectGeometry,
            (geometry) =>
                ({
                    ...geometry,
                    width:
                        geometry.stroke === ShapeStroke.Dash
                            ? geometry.width - LABEL_STROKE_WIDTH
                            : geometry.width,
                    height:
                        geometry.stroke === ShapeStroke.Dash
                            ? geometry.height - LABEL_STROKE_WIDTH
                            : geometry.height,
                    type: Shape.Rect as const
                }) satisfies RectGeometry
        )
        .when(
            isDiamondGeometry,
            (geometry) =>
                ({
                    ...geometry,
                    type: Shape.Diamond as const
                }) satisfies DiamondGeometry
        )
        .when(
            isCircleGeometry,
            (geometry) =>
                ({
                    ...geometry,
                    type: Shape.Circle as const
                }) satisfies CircleGeometry
        )
        .otherwise(() => null);
}

const isTableProperty = (
    properties: GQLProperties
): properties is GQLTableProperties => properties.type === Variant.Table;

const isLabelProperty = (
    properties: GQLProperties
): properties is GQLLabelProperties => properties.type === Variant.Label;

const isBarrierProperty = (
    properties: GQLProperties
): properties is GQLBarrierProperties => properties.type === Variant.Barrier;

function makeLayoutProperty(
    properties: GQLProperties,
    tablesMap: Record<string, Table>
): LayoutProperty {
    return match(properties)
        .when(
            isTableProperty,
            (property) =>
                ({
                    type: Variant.Table as const,
                    tableId: property.tableId,
                    tableName: property.tableId
                        ? tablesMap[property.tableId].name
                        : "",
                    numSeats: property.tableId
                        ? tablesMap[property.tableId].numSeats
                        : 4
                }) satisfies TableProperty
        )
        .when(
            isLabelProperty,
            (property) =>
                ({
                    type: Variant.Label as const,
                    label: property.label
                }) satisfies LabelProperty
        )
        .when(isBarrierProperty, () => ({
            type: Variant.Barrier as const
        }))
        .exhaustive();
}

export function toGQLServiceAreaLayout(
    elements: LayoutElement[],
    objects: Element[]
): CreateServiceAreaLayout {
    const elementsMap = _.objectify(elements, (el) => el.id);

    return objects.reduce(
        (acc, obj) => {
            if (obj.variant === "table") {
                const properties = elementsMap[obj.id]
                    .properties as TableProperty;

                const geometry = extractGeometry(
                    elementsMap[obj.id].geometry,
                    obj
                );

                acc.tables?.push({
                    tableId: properties.tableId,
                    tableName: properties.tableName,
                    numSeats: properties.numSeats,
                    rectGeometry: geometry.rect,
                    diamondGeometry: geometry.diamond,
                    circleGeometry: geometry.circle
                });
            } else if (obj.variant === "label") {
                const properties = elementsMap[obj.id]
                    .properties as LabelProperty;

                const geometry = extractGeometry(
                    elementsMap[obj.id].geometry,
                    obj
                );

                if (geometry.rect) {
                    acc.labels?.push({
                        label: properties.label,
                        rectGeometry: geometry.rect
                    });
                }
            } else if (obj.variant === "barrier") {
                const geometry = extractGeometry(
                    elementsMap[obj.id].geometry,
                    obj
                );

                if (geometry.rect) {
                    acc.barriers?.push({
                        rectGeometry: geometry.rect
                    });
                }
            }

            return acc;
        },
        { tables: [], labels: [], barriers: [] } as CreateServiceAreaLayout
    );
}

type GQLCreateGeometry = {
    rect: CreateRectGeometry | null;
    diamond: CreateDiamondGeometry | null;
    circle: CreateCircleGeometry | null;
};

function extractGeometry(
    geometry: LayoutGeometry,
    element: Element
): GQLCreateGeometry {
    const shape: GQLCreateGeometry = {
        rect: null,
        diamond: null,
        circle: null
    };

    if (geometry.type === Shape.Rect) {
        const elementGeometry = element.toGeometry("Rect");

        if (elementGeometry && elementGeometry.type === Shape.Rect) {
            const { type: _, ...props } = elementGeometry;

            shape.rect = props;
        }
    } else if (geometry.type === Shape.Circle) {
        const elementGeometry = element.toGeometry("Circle");

        if (elementGeometry && elementGeometry.type === Shape.Circle) {
            const { type: _, ...props } = elementGeometry;

            shape.circle = props;
        }
    } else if (geometry.type === Shape.Diamond) {
        const elementGeometry = element.toGeometry("Rect");

        if (elementGeometry && elementGeometry.type === Shape.Rect) {
            // Get rotated width instead of Element width
            const size =
                Math.max(elementGeometry.width, elementGeometry.height) *
                Math.cos(Math.PI / 4);

            shape.diamond = {
                x: elementGeometry.x,
                y: elementGeometry.y,
                size
            };
        }
    }

    return shape;
}

/**
 * mergeElementProperties combines both old and new elements' properties but keeping
 * geometry and attributes of the old elements
 */
export function mergeElementProperties(
    oldElements: LayoutElement[],
    newElements: LayoutElement[]
) {
    return oldElements
        .map((el, i) => [el, newElements[i]])
        .map(([oldElement, newElement]) => ({
            ...oldElement,
            properties: {
                ...newElement.properties
            }
        }));
}
