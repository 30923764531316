import { useCallback, useEffect, useRef, useState } from "react";

import { useDeleteServiceArea, useSaveServiceArea } from "#table-editor/api";
import {
    useEditorState,
    useEditorStore
} from "#table-editor/components/Editor/EditorProvider";
import { Button } from "src/@/components/ui/button";

import { SelectServiceArea } from "./SelectServiceArea";
import { SaveButton } from "./SaveButton";

export function ServiceArea() {
    const isDirty = useEditorState((state) => state.isDirty);
    const activeServiceArea = useEditorState(
        (state) => state.activeServiceArea,
        (a, b) => a?.id === b?.id
    );

    const editor = useEditorStore();

    const ref = useRef<HTMLDivElement>(null);
    const [viewportWidth, setViewportWidth] = useState<number>();

    const { loading, saveServiceArea } = useSaveServiceArea();
    const { loading: deleteLoading, deleteServiceArea } =
        useDeleteServiceArea();

    useEffect(() => {
        if (ref.current) {
            setViewportWidth(ref.current.getBoundingClientRect().width);
        }
    }, []);

    const onChangeServiceArea = useCallback(
        async (id: string) => {
            if (isDirty) {
                await saveServiceArea();
            }
            editor.getState().setServiceArea(id);
        },
        [editor, isDirty, saveServiceArea]
    );

    const onDeleteServiceArea = useCallback(async () => {
        if (activeServiceArea) {
            await deleteServiceArea(activeServiceArea.id);
        }
    }, [activeServiceArea, deleteServiceArea]);

    const disableDelete =
        deleteLoading || !activeServiceArea || !activeServiceArea.id;

    return (
        <div className="flex flex-col gap-2" ref={ref}>
            <h2 className="text-base text-white underline">Service Area</h2>
            <SelectServiceArea
                width={viewportWidth}
                onChange={onChangeServiceArea}
            />
            <SaveButton overrideLoading={loading} />
            <Button
                variant="destructive"
                className="w-full"
                disabled={disableDelete}
                onClick={onDeleteServiceArea}
            >
                Delete
            </Button>
        </div>
    );
}
