import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import { ShortcutsCard } from "./shortcuts-card";
import { shortcuts, ShortcutData } from "#dashboard/components/shortcuts-data";
import { Input } from "src/@/components/ui/input";

type Store = ReturnType<typeof getActiveStore>;

const filterShortcuts = (
    shortcuts: ShortcutData[],
    searchTerm: string,
): ShortcutData[] => {
    return shortcuts.filter((shortcut) =>
        shortcut.title.toLowerCase().includes(searchTerm.toLowerCase()),
    );
};

export function ShortcutsGrid() {
    const store = useSelector(getActiveStore);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const filteredShortcuts = useMemo(
        () => filterShortcuts(shortcuts, searchTerm),
        [searchTerm],
    );

    return (
        <div className="space-y-4">
            <Input
                type="text"
                placeholder="Search shortcuts..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full max-w-sm mb-4"
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                {filteredShortcuts.map((shortcut) => (
                    <ShortcutsCard
                        key={shortcut.id}
                        shortcut={shortcut}
                        store={store}
                    />
                ))}
            </div>
        </div>
    );
}
