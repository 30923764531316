import { useEffect } from "react";

import { Element as Variant } from "src/api/graphql/generated/types";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { useCanvasEffect } from "#table-editor/components/Editor/hooks";

import { Section } from "../Section";

import { Circle, Diamond, Rectangle } from "./Shape";
import { makeElement } from "./makeElement";

export function Draw() {
    const editor = useEditorStore();

    useEffect(() => {
        function onPressEscape(e: KeyboardEvent) {
            if (e.key === "Escape") {
                e.preventDefault();
                editor.getState().setEditMode();
            }
        }

        window.addEventListener("keydown", onPressEscape);

        return () => {
            window.removeEventListener("keydown", onPressEscape);
        };
    }, [editor]);

    useCanvasEffect(
        (canvas) =>
            canvas.on("mouse:down", (e) => {
                const controls = editor.getState().controls;

                if (controls.mode === "draw" && e.target == null) {
                    const newElement = makeElement(
                        e.viewportPoint.x,
                        e.viewportPoint.y,
                        controls.shape,
                        controls.variant
                    );

                    editor.getState().addElement(newElement);
                }
            }),
        [editor]
    );

    return (
        <>
            <Section label="Tables">
                <Diamond variant={Variant.Table} />
                <Rectangle variant={Variant.Table} drawShape="square" />
                <Circle variant={Variant.Table} />
            </Section>
            <Section label="Barriers & Labels" className="mt-2">
                <Rectangle variant={Variant.Barrier} drawShape={"rect"} />
                <Rectangle
                    variant={Variant.Label}
                    border="dash"
                    fill="transparent"
                    drawShape="rect"
                    className="flex select-none flex-row items-center justify-center text-white"
                >
                    Label
                </Rectangle>
            </Section>
        </>
    );
}
