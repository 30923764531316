import { PromoTarget, PromoTypes } from "@snackpass/snackpass-types";

import { Routes } from "#navigation/routes";

export type FormTypeInfo = {
    route:
        | Routes.CreatePromotionGeneric
        | Routes.CreatePromotionReward
        | Routes.CreatePromotionHappyHour
        | Routes.CreatePromotionBogo
        | Routes.CreatePromotionStudents
        | Routes.CreatePromotionFirstTime
        | Routes.CreatePromotionDealDrop
        | Routes.CreatePromotionPromoCode
        | Routes.CreatePromotionGiftCard;
    advancedFields: FIELD_NAMES[];
};

export enum FIELD_NAMES {
    NAME = "NAME",
    PROMOTION_TYPE = "PROMOTION_TYPE",
    DISCOUNT_TYPE = "DISCOUNT_TYPE",
    DISCOUNT_AMOUNT_PERCENT = "DISCOUNT_AMOUNT_PERCENT",
    DISCOUNT_AMOUNT_DOLLARS = "DISCOUNT_AMOUNT_DOLLARS",
    DISCOUNT_NEWPRICEAMOUNT_DOLLARS = "DISCOUNT_NEWPRICEAMOUNT_DOLLARS",
    DISCOUNT_ADDONS = "DISCOUNT_ADDONS",
    DISCOUNT_LEAST_EXPENSIVE = "DISCOUNT_LEAST_EXPENSIVE",
    EXPIRATION_DAYS = "EXPIRATION_DAYS",
    IMAGE = "IMAGE",
    AUDIENCE = "AUDIENCE",
    PROMO_CODE_ENABLED = "PROMO_CODE_ENABLED",
    PROMO_CODE = "PROMO_CODE",
    SINGLE_USE = "SINGLE_USE",
    LIMIT_USES = "LIMIT_USES",
    TOTAL_USES = "TOTAL_USES",
    POINTS_REQUIRED = "POINTS_REQUIRED",

    // Cart Rules fields
    ONE_PER_CART = "ONE_PER_CART",
    CART_MINIMUM = "CART_MINIMUM",
    CART_MAXIMUM = "CART_MAXIMUM",
    REQUIRED_PURCHASE = "REQUIRED_PURCHASE",
    REQUIRED_PURCHASE_ITEMS = "REQUIRED_PURCHASE_ITEMS",

    // Item condition fields
    MINIMUM_PRICE = "MINIMUM_PRICE",
    MAXIMUM_DISCOUNT = "MAXIMUM_DISCOUNT",

    // Fufillment fields
    FULFILLMENT_METHODS = "FULFILLMENT_METHODS",

    // Platform fields
    PLATFORMS = "PLATFORMS",

    // Discounted products fields
    DISCOUNT_QUANTITY = "DISCOUNT_QUANTITY",
    DISCOUNT_QUALIFIER = "DISCOUNT_QUALIFIER",
    DISCOUNTED_ITEMS = "DISCOUNTED_ITEMS",

    // Schedule fields
    SCHEDULE_ENABLED = "SCHEDULE_ENABLED",
    SCHEDULE_MONDAY = "SCHEDULE_MONDAY",
    SCHEDULE_MONDAY_ENABLED = "SCHEDULE_MONDAY_ENABLED",
    SCHEDULE_TUESDAY = "SCHEDULE_TUESDAY",
    SCHEDULE_TUESDAY_ENABLED = "SCHEDULE_TUESDAY_ENABLED",
    SCHEDULE_WEDNESDAY = "SCHEDULE_WEDNESDAY",
    SCHEDULE_WEDNESDAY_ENABLED = "SCHEDULE_WEDNESDAY_ENABLED",
    SCHEDULE_THURSDAY = "SCHEDULE_THURSDAY",
    SCHEDULE_THURSDAY_ENABLED = "SCHEDULE_THURSDAY_ENABLED",
    SCHEDULE_FRIDAY = "SCHEDULE_FRIDAY",
    SCHEDULE_FRIDAY_ENABLED = "SCHEDULE_FRIDAY_ENABLED",
    SCHEDULE_SATURDAY = "SCHEDULE_SATURDAY",
    SCHEDULE_SATURDAY_ENABLED = "SCHEDULE_SATURDAY_ENABLED",
    SCHEDULE_SUNDAY = "SCHEDULE_SUNDAY",
    SCHEDULE_SUNDAY_ENABLED = "SCHEDULE_SUNDAY_ENABLED",

    // Duration fields
    DURATION_ENABLED = "DURATION_ENABLED",
    DURATION_START_DATE = "DURATION_START_DATE",
    DURATION_END_DATE = "DURATION_END_DATE",
}

export interface GenericPromo {
    [FIELD_NAMES.NAME]: string;
    [FIELD_NAMES.DISCOUNT_TYPE]: string;
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: number;
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: number;
    [FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS]: number;

    // Required Purchase
    [FIELD_NAMES.REQUIRED_PURCHASE]: boolean;
    [FIELD_NAMES.REQUIRED_PURCHASE_ITEMS]: MenuItem[];

    // Discounted products / categories
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues;
    [FIELD_NAMES.DISCOUNTED_ITEMS]: MenuItem[];

    [FIELD_NAMES.DISCOUNT_ADDONS]: boolean;
    // TODO: uncomment once this field is re-enabled in form
    // [FIELD_NAMES.DISCOUNT_LEAST_EXPENSIVE]: boolean;
    [FIELD_NAMES.ONE_PER_CART]: boolean;
    [FIELD_NAMES.CART_MINIMUM]: number;
    [FIELD_NAMES.CART_MAXIMUM]: number;
    [FIELD_NAMES.SCHEDULE_ENABLED]: boolean;
    [FIELD_NAMES.IMAGE]: string;
    [FIELD_NAMES.IMAGE]: string;
    [FIELD_NAMES.AUDIENCE]: PromoTarget;
    [FIELD_NAMES.PROMO_CODE_ENABLED]: boolean;
    [FIELD_NAMES.PROMO_CODE]: string;
    [FIELD_NAMES.SINGLE_USE]: boolean;
    [FIELD_NAMES.LIMIT_USES]: boolean;
    [FIELD_NAMES.TOTAL_USES]: number;
    [FIELD_NAMES.FULFILLMENT_METHODS]: FulfillmentValues;

    [FIELD_NAMES.PLATFORMS]: PlatformValues;

    // Schedule
    [FIELD_NAMES.SCHEDULE_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_MONDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_MONDAY_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_TUESDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_WEDNESDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_THURSDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_FRIDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_SATURDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED]: boolean;

    [FIELD_NAMES.SCHEDULE_SUNDAY]: ScheduleDay[];
    [FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED]: boolean;

    // Duration
    [FIELD_NAMES.DURATION_ENABLED]: boolean;
    [FIELD_NAMES.DURATION_START_DATE]: string;
    [FIELD_NAMES.DURATION_END_DATE]: string;
}

export interface DiscountPromo extends GenericPromo {
    // Required Purchase
    [FIELD_NAMES.REQUIRED_PURCHASE]: false;
}
export interface DealPromo extends GenericPromo {}

export interface RewardPromo {
    [FIELD_NAMES.NAME]: string;

    [FIELD_NAMES.DISCOUNT_TYPE]: string;
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: number;
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: number;
    [FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS]: number;

    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues;

    [FIELD_NAMES.POINTS_REQUIRED]: number;

    [FIELD_NAMES.EXPIRATION_DAYS]: number;

    [FIELD_NAMES.DISCOUNTED_ITEMS]: MenuItem[];

    [FIELD_NAMES.DISCOUNT_ADDONS]: boolean;
}

export interface HappyHourPromo extends GenericPromo {
    [FIELD_NAMES.SCHEDULE_ENABLED]: true;
}

export interface PromoCodePromo extends GenericPromo {
    [FIELD_NAMES.REQUIRED_PURCHASE]: true;
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues.SPECIFIC;
}
export interface BogoPromo extends GenericPromo {
    [FIELD_NAMES.REQUIRED_PURCHASE]: true;
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues.SPECIFIC;
}

export interface StudentPromo extends GenericPromo {
    [FIELD_NAMES.AUDIENCE]: PromoTarget.Students;
}

export interface FirstTimePromo extends GenericPromo {
    [FIELD_NAMES.AUDIENCE]: PromoTarget.FirstTime;
}

export interface DealDropPromo extends GenericPromo {
    [FIELD_NAMES.DURATION_ENABLED]: true;
    [FIELD_NAMES.LIMIT_USES]: true;
}

export interface GiftCardPromo {
    [FIELD_NAMES.NAME]: string;
    [FIELD_NAMES.PROMOTION_TYPE]: PromoTypes.GiftCard;

    [FIELD_NAMES.DISCOUNT_TYPE]: string;
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: number;
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: number;
    [FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS]: number;

    [FIELD_NAMES.MINIMUM_PRICE]: number | undefined;
    [FIELD_NAMES.MAXIMUM_DISCOUNT]: number | undefined;
    [FIELD_NAMES.IMAGE]: string;
}

export enum PromoShortcut {
    Bogo = "bogo",
    HappyHour = "happyHour",
    PromoCode = "promoCode",
    FirstTime = "firstTime",
    DealDrop = "dealDrop",
    Student = "student",
    Referral = "referral",
    Reward = "reward",
    Party = "party",
    GiftCard = "giftCard",
}

export enum FormTypes {
    Generic = "GENERIC",
    Reward = "REWARD",
    HappyHour = "HAPPYHOUR",
    Bogo = "BOGO",
    Students = "STUDENTS",
    FirstTime = "FIRSTTIME",
    DealDrop = "DEALDROP",
    PromoCode = "PROMOCODE",
    GiftCard = "GIFTCARD",
}

export enum FormUsageTypes {
    Create = "Create",
    Edit = "Edit",
    CreateFromTemplate = "CreateFromTemplate",
}

export type ScheduleDay = {
    start: string;
    end: string;
};

export enum RequiredPurchaseValues {
    None = "none",
    AnyItem = "anyItem",
    AllItems = "allItems",
}

export enum PlatformValues {
    OnlyApp = "onlyApp",
    OnlyRegister = "onlyRegister",
    AppAndKiosk = "appAndKiosk",
    AppAndKioskAndRegister = "appAndKioskAndRegister",
}

export enum FulfillmentValues {
    All = "all",
    PickupAndDineIn = "pickupAndDineIn",
    Delivery = "delivery",
}

export enum QualifierValues {
    ANY_ITEM = "ANY_ITEM",
    SPECIFIC = "SPECIFIC",
    SAME_ITEM = "SAME_ITEM",
    SAME_CATEGORY = "SAME_CATEGORY",
}

export type Platforms = {
    app: boolean;
    kiosk: boolean;
    register: boolean;
};

export type DayOfWeek =
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";

export interface HourItemTime {
    dayOfWeek: DayOfWeek;
    time: Date;
}

export interface HoursItem {
    startTime: HourItemTime;
    endTime: HourItemTime;
}

export type MenuItem = {
    label: string;
    products: string[];
    categories: string[];
};

export type Time = {
    day: number;
    hours: number;
    minutes: number;
    seconds?: number;
    am?: boolean;
    pm?: boolean;
};

export type DayMap = {
    day: DayOfWeek;
    field: string & keyof GenericPromo;
    enabled: string & keyof GenericPromo;
};

export interface FormDefaultState {
    formData:
        | GenericPromo
        | DiscountPromo
        | RewardPromo
        | HappyHourPromo
        | GiftCardPromo;
    formUsage: string; // Should be either "Create" or "Edit"
    promoId?: string;
}

export interface FormHappyHourState extends FormDefaultState {
    formData: HappyHourPromo;
}

export interface FormRewardState extends FormDefaultState {
    formData: RewardPromo;
}

export interface FormBogoState extends FormDefaultState {
    formData: BogoPromo;
}

export interface FormStudentState extends FormDefaultState {
    formData: StudentPromo;
}

export interface FormFirstTimeState extends FormDefaultState {
    formData: FirstTimePromo;
}

export interface FormDealDropState extends FormDefaultState {
    formData: DealDropPromo;
}

export interface FormPromoCodeState extends FormDefaultState {
    formData: PromoCodePromo;
}

export interface FormGiftCardState extends FormDefaultState {
    formData: GiftCardPromo;
}

export type FormSubmitState = {
    formUsage: FormUsageTypes;
    success: boolean;
};
