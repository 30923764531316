import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { debounce } from "radash";

import { LabelProperty } from "#table-editor/types";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { Element as Variant } from "src/api/graphql/generated/types";
import { Section } from "#table-editor/components/Editor/Panel/Section";
import { Input } from "src/@/components/ui/input";
import { Button } from "src/@/components/ui/button";

type ModifyLabelProps = {
    id: string;
    properties: LabelProperty;
};

export function ModifyLabel({ id, properties }: ModifyLabelProps) {
    const editor = useEditorStore();

    const [label, setLabel] = useState(properties.label);

    useEffect(() => {
        setLabel(properties.label);
    }, [properties]);

    const updateLabel = debounce({ delay: 100 }, (label: string) => {
        editor.getState().setLabelData(id, {
            type: Variant.Label,
            label
        });
    });

    const onChangeLabel = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setLabel(e.target.value);
            updateLabel(e.target.value);
        },
        [updateLabel]
    );

    const onClickDelete = useCallback(() => {
        editor.getState().removeSelected(id);
    }, [editor, id]);

    return (
        <Section label="Edit Label">
            <div className="flex w-full flex-col gap-2.5">
                <Input
                    placeholder="Label"
                    value={label}
                    onChange={onChangeLabel}
                    className="w-full border-none bg-neutral-600 text-white placeholder:text-neutral-400"
                />

                <Button
                    type="button"
                    variant="destructive"
                    onClick={onClickDelete}
                >
                    <span>Delete</span>
                </Button>
            </div>
        </Section>
    );
}
