import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import axios from "axios";

import ItemSalesChart from "#reports/menu-item-insights/components/ItemSalesChart";
import ItemPerformanceTable from "#reports/menu-item-insights/components/ItemPerformanceTable";
import { getActiveStore } from "src/redux/selectors";
import FilterHeader from "#reports/sales-summary/shared-components/FilterHeader";
import { getStoreTimezone } from "#utils/helpers";
import { ReportsContext } from "#app/reports-context-provider";
import api from "src/api/rest";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import { logAndSendError } from "src/utils/errors";

const ItemInsights = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, filter } = reportsState;
    const trackEvent = useTrackEvent();

    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.menuInsightsData) {
            // set loading
            setReportsState((reportsState) => ({
                ...reportsState,
                menuInsightsData: {
                    itemInsightsDataLoading: true,
                    itemInsightsDataFailed: false,
                    itemInsightsData:
                        reportsState?.menuInsightsData?.itemInsightsData,
                    itemTableModifierData:
                        reportsState?.menuInsightsData?.itemTableModifierData,
                },
            }));

            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        const params = {
            storeId: activeStore?._id,
            startDate: dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: dateRanges[0][1].format("YYYY-MM-DD"),

            timezone: getStoreTimezone(activeStore),

            channel: JSON.stringify(filter.channel),
            source: JSON.stringify(filter.source),
            fulfillment: JSON.stringify(filter.fulfillment),
        };

        api.reports
            .getMenuItemInsights(params, abortController.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    menuInsightsData: {
                        itemInsightsDataLoading: false,
                        itemInsightsDataFailed: false,
                        itemInsightsData: res.data?.items,
                        itemTableModifierData: undefined, // flush out modifiers data when loading new item insights data
                    },
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    menuInsightsData: {
                        itemInsightsDataLoading: false,
                        itemInsightsDataFailed: true,
                        itemInsightsData: undefined,
                        itemTableModifierData: undefined,
                    },
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);

    const fetchDataDebounced = useCallback(
        debounce(() => fetchDataRef.current?.(), 600),
        [],
    );

    return (
        <div>
            <FilterHeader reportType={ReportType.MENU_ITEM_INSIGHTS} />
            <div className="px-6 md:px-24">
                <ItemSalesChart />
                <ItemPerformanceTable />
            </div>
        </div>
    );
};

export default ItemInsights;
