import React, { useEffect, useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import {
    Controller,
    SubmitErrorHandler,
    SubmitHandler,
    useForm,
    FieldValues,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Spinner } from "react-activity";
import { useMediaQuery } from "react-responsive";
import { ScreenState } from "@snackpass/snackpass-types";
import { toast } from "sonner";

import api from "src/api/rest";
import { SettingsInput } from "#settings-catering/components/SettingsInput";
import { SettingsEmailInput } from "#settings-catering/components/SettingsEmailInput";
import { setActiveStore } from "src/redux/slices";
import { getActiveStore } from "src/redux/selectors";
import { MobileHeader } from "#reusable/layout/header";
import { ScreenLayout } from "#reusable/layout/screen-layout";
import { Text } from "#reusable/text/index";
import constants from "#core/constants";
import { Space } from "#reusable/divider/space";
import { Note } from "#reusable/text/note";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { useCateringEnabled } from "#navigation/utils";
import { FeatureBadge } from "#navigation/FeatureBadge";

import { SettingsToggle } from "./components/SettingsToggle";

const schema = yup
    .object({
        allowDelivery: yup.boolean(),
        orderMinimum: yup.number().required().min(0),
        minLeadTime: yup
            .number()
            .required()
            .min(0)
            .max(24 * 365),
        email: yup.string(),
    })
    .required();

const SettingsCateringScreen = () => {
    const viewOnly = !useHasEditSettingsForActiveStore();
    const dispatch = useDispatch();
    const store = useSelector(getActiveStore);
    const cateringEnabled = useCateringEnabled();
    const [isLoading, setLoading] = useState(false);
    const {
        handleSubmit,
        reset,
        control,
        formState: { isDirty, isValid },
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
    });
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });

    useEffect(() => {
        if (!store) return;
        reset({
            allowDelivery: store.catering.allowDelivery || false,
            orderMinimum: store.catering.orderMinimum || 0,
            minLeadTime: store.catering.minLeadTime || 48,
            email: store.catering.email || "",
        });
    }, [store]);

    const handleSave: SubmitHandler<FieldValues> = async (body) => {
        if (!store || viewOnly) return;
        try {
            setLoading(true);
            const { data } = await api.stores.update(store._id, {
                catering: { ...store.catering, ...body },
            });
            dispatch(setActiveStore(data.store));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleReject: SubmitErrorHandler<FieldValues> = (_data) => {
        toast.error("Can not update the store, please try again later");
    };

    return (
        <form
            className="h-[100%]"
            onSubmit={(e) => {
                void handleSubmit(handleSave, handleReject)();
                e.preventDefault();
            }}
        >
            <ScreenLayout
                header={
                    isMobile ? (
                        <MobileHeader
                            title="Catering"
                            right={<FeatureBadge variant="legacy" />}
                        />
                    ) : (
                        <div className="flex flex-row items-center gap-3">
                            <Text size="xl" weight="x-bold" className="w-fit">
                                Catering
                            </Text>
                            <FeatureBadge variant="legacy" />
                        </div>
                    )
                }
                isLoading={isLoading}
                description={
                    isMobile ? null : (
                        <Text size="s" color="light-grey" weight="thin">
                            Allow customers to place scheduled catering orders
                            online or on Snackpass Register.
                        </Text>
                    )
                }
                content={
                    <Container>
                        <div className="mb-4 mt-6">
                            {!cateringEnabled ? (
                                <Note
                                    note="To start taking catering orders, please contact support."
                                    noteType="warning"
                                    noteStyle="subtle"
                                />
                            ) : (
                                <Note
                                    note="You will need to add a catering menu in the menu editor"
                                    link="/menu-editor"
                                    linkPlaceholder="Open Menu Editor"
                                />
                            )}
                        </div>
                        <DisableContainer disabled={!cateringEnabled}>
                            <Text size="l" weight="x-bold" spacing="m">
                                Orders
                            </Text>
                            <Space vertical="s" />
                            <Controller
                                name="orderMinimum"
                                control={control}
                                render={({ field }) => (
                                    <SettingsInput
                                        title="Order minimum"
                                        subtitle="Set the minimum amount customers must spend for catering orders"
                                        inputProps={{
                                            placeholder: "$0.00",
                                            type: "number",
                                            disabled: viewOnly,
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                            <Divider />
                            <Controller
                                name="minLeadTime"
                                control={control}
                                render={({ field }) => (
                                    <SettingsInput
                                        title="Minimum lead time"
                                        subtitle="The minimum number of hours catering orders must be scheduled ahead"
                                        inputProps={{
                                            placeholder: "48 hours",
                                            type: "number",
                                            disabled: viewOnly,
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                            <Divider />
                            <Text size="l" weight="x-bold" spacing="m">
                                Preferences
                            </Text>
                            <Space vertical="s" />
                            <div className="mb-6">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <SettingsEmailInput
                                            title="Email notifications"
                                            subtitle="Send order notifications to a catering-specific email"
                                            inputProps={{
                                                placeholder:
                                                    "catering@email.com",
                                                type: "email",
                                                disabled: viewOnly,
                                                ...field,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <Controller
                                name="allowDelivery"
                                control={control}
                                render={({ field }) => (
                                    <SettingsToggle
                                        title="Restaurant-provided delivery"
                                        subtitle="Allow customers to order catering delivery, delivered by your store. 3P delivery platforms are not yet supported for catering."
                                        // @ts-expect-error old form component, needs a cast to boolean on value thats a bit tricky
                                        switchProps={field}
                                        disabled={viewOnly}
                                    />
                                )}
                            />
                            <Space vertical="s" />
                            <Divider />
                            <Note
                                title="Need help?"
                                note="Learn how to place and accept catering orders"
                                link="https://support.snackpass.co/en/articles/7173948-how-to-use-catering"
                                linkPlaceholder="Watch Tutorial"
                            />
                        </DisableContainer>
                    </Container>
                }
                footer={
                    isDirty && !viewOnly ? (
                        <div className="flex flex-row justify-center md:justify-end">
                            <SecondaryButton
                                style={{ marginRight: 16 }}
                                onClick={() => reset()}
                                disabled={isLoading}
                            >
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                disabled={!isValid || isLoading}
                            >
                                {isLoading ? (
                                    <Spinner
                                        color={SystemColors.v1.white}
                                        size={18}
                                    />
                                ) : (
                                    "Save"
                                )}
                            </PrimaryButton>
                        </div>
                    ) : (
                        <></>
                    )
                }
            />
        </form>
    );
};

export default SettingsCateringScreen;

const Container = styled.div`
    overflow-y: scroll;
    padding-bottom: 50px;
    padding-right: 20%;
    height: 100%;
    @media ${ScreenState.MOBILE} {
        padding-left: 20px;
        padding-right: 30px;
    }
    @media ${ScreenState.TABLET} {
        padding-right: 50px;
    }
    width: -webkit-fill-available;
    width: -moz-available;
`;

const DisableContainer = styled.div<{ disabled: boolean }>`
    ${({ disabled }) =>
        disabled &&
        css`
            display: none;
        `}
`;

const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    margin: 24px 0px;
`;

const Button = styled.button`
    all: unset;
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 8px;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: all 0.3s;
    @media ${ScreenState.MOBILE} {
        justify-content: center;
        display: flex;
        flex: 1;
    }
    &:hover {
        opacity: 0.8;
    }
`;

const PrimaryButton = styled(Button)`
    background: ${SystemColors.v1.candy50};
    color: ${SystemColors.v1.white};
    :disabled {
        background: ${SystemColors.v1.gray80};
        color: ${SystemColors.v1.gray20};
    }
`;

const SecondaryButton = styled(Button)`
    color: ${SystemColors.v1.sesame};
    border: 1px solid ${SystemColors.v1.gray80};
    :disabled {
        opacity: 0.3;
    }
`;
