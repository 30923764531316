import { SystemColors } from "@snackpass/design-system";
import {
    IPurchase,
    PurchaseReportData,
    PurchaseStatus,
} from "@snackpass/snackpass-types";
import moment from "moment";

export const sortCateringPurchases = (
    a: PurchaseReportData,
    b: PurchaseReportData,
) => {
    const getDate = (p: PurchaseReportData) =>
        new Date(p.scheduledFor || p.dateReceived);
    return getDate(a).getTime() - getDate(b).getTime();
};

const dateToStr = (date?: Date, includeTime?: boolean) => {
    let result = moment(date).fromNow();
    if (includeTime) {
        result += ", " + moment(date).format("h:mm A");
    }
    return result;
};

export const humanizePurchaseStatus = (
    purchase?: IPurchase,
    includeTime?: boolean,
): { text: string; color: string } | null => {
    if (!purchase) return null;

    if (!purchase?.status?.length)
        return {
            text: `Received ${dateToStr(new Date(purchase.createdAt))}`,
            color: SystemColors.v1.candy50,
        };

    const lastStatus = purchase.status[purchase.status.length - 1];
    if (purchase.refund && !purchase.partialRefund) {
        const date = purchase.refunds.length
            ? purchase.refunds[0].refundedAt
            : lastStatus.createdAt;
        return {
            text: `Refunded ${dateToStr(date, includeTime)}`,
            color: SystemColors.v1.melon50,
        };
    }
    if (lastStatus.type === PurchaseStatus.canceled) {
        return {
            text: `Canceled ${dateToStr(lastStatus.createdAt)}`,
            color: SystemColors.v1.melon50,
        };
    }
    if (purchase.catering.isCatering && purchase.catering.status === "rejected")
        return {
            text: `Rejected ${dateToStr(lastStatus.createdAt)}`,
            color: SystemColors.v1.melon50,
        };
    if (lastStatus.type === PurchaseStatus.completed) {
        return {
            text: `Completed ${dateToStr(lastStatus.createdAt)}`,
            color: SystemColors.v1.parsley50,
        };
    }

    const getTitle = () => {
        let result = "";
        switch (purchase.fulfillment) {
            case "PICKUP":
                result += "Pickup ";
                break;
            case "DELIVERY":
                if (purchase.deliveryInfo?.provider === "store") {
                    result += "Delivery arrives "; // based on the text message to the user (this is the time the delivery arrives)
                } else {
                    result += "Delivery driver en route";
                }
                break;
            case "DINE_IN":
                result += "Dine-in ";
                break;
            default:
                break;
        }
        result += dateToStr(
            purchase.scheduledDate ||
                purchase.pickupTime ||
                (purchase.createdAt as Date),
        );
        return result;
    };

    return {
        text: getTitle(),
        color:
            lastStatus?.type === PurchaseStatus.started
                ? SystemColors.v1.macaroni50
                : SystemColors.v1.candy50,
    };
};
