import { ScreenState } from "@snackpass/snackpass-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { onAuthStateChanged } from "firebase/auth";

import { gridGap } from "#css";
import { PageHeader } from "#order-details/components/page-header";
import { setActivePurchase } from "src/redux/slices";
import { selectActivePurchase } from "src/redux/selectors";
import { PurchaseItems } from "#order-details/components/purchase-items";
import api from "src/api/rest";
import LoadingPage from "#utility-pages/loading-page";
import { sendError } from "src/utils/errors";
import { firebaseAuth } from "#app/firebase";

import { CustomerPickupOptions } from "./components/pickup-options";
import { PurchaseInfo } from "./components/purchase-info";
import { PurchaseCustomer } from "./components/purchase-customer";

type Props = {
    purchaseId: string;
    handleHide?: () => void;
};

const OrderDetails = ({ purchaseId, handleHide }: Props) => {
    const dispatch = useDispatch();
    const purchase = useSelector(selectActivePurchase);
    const [isLoading, setLoading] = useState(true);

    const handleFetch = async () => {
        if (!purchaseId?.length) return;
        setLoading(true);
        try {
            // If user is logged in, will also automatically send the user's JWT
            const { data } = await api.purchases.getById(purchaseId);
            dispatch(setActivePurchase(data.purchase ?? null));
        } catch (err) {
            sendError(err);
            dispatch(setActivePurchase(null));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onAuthStateChanged(firebaseAuth, () => {
            setTimeout(() => {
                void handleFetch();
            }, 500);
        });
    }, []);

    if (isLoading) {
        return <LoadingPage />;
    }

    if (!purchase) {
        return (
            <Container className="order-details-modal">
                <PageHeader handleHide={handleHide} />
                <NotFound>This purchase doesn't exist.</NotFound>
            </Container>
        );
    }

    return (
        <Container className="order-details-modal">
            <PageHeader handleHide={handleHide} />
            <Grid>
                <OrderDetailsColumn1>
                    <PurchaseCustomer purchase={purchase} />
                    <PurchaseItems purchase={purchase} />
                </OrderDetailsColumn1>
                <OrderDetailsColumn2>
                    <CustomerPickupOptions purchase={purchase} />
                    <PurchaseInfo purchase={purchase} />
                </OrderDetailsColumn2>
            </Grid>
        </Container>
    );
};

export const OrderDetailsRoute = () => {
    const { params } = useRouteMatch<{ id: string }>();
    return <OrderDetails purchaseId={params.id} />;
};

const { MOBILE, TABLET, DESKTOP } = ScreenState;
const { MOBILE_GRIDGAP, TABLET_GRIDGAP, DESKTOP_GRIDGAP } = gridGap;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media ${ScreenState.MOBILE} {
        margin: 16px;
    }

    @media ${ScreenState.TABLET} {
        margin: 24px;
    }

    @media ${ScreenState.DESKTOP} {
        margin: 25px 48px;
    }
`;

const NotFound = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: ${SystemColors.v1.gray20};
`;

// grid view for each column
const OrderDetailsColumn1 = styled.div`
    display: grid;
    grid-area: OrderDetailsColumn1;
    grid-template-areas:
        "CustomerInfo"
        "Items";

    @media ${MOBILE} {
        grid-gap: ${MOBILE_GRIDGAP};
        grid-template-rows: auto max-content;
    }

    @media ${TABLET} {
        grid-gap: ${TABLET_GRIDGAP};
        grid-template-rows: auto max-content;
    }

    @media ${DESKTOP} {
        grid-gap: ${DESKTOP_GRIDGAP};
        grid-template-rows: auto max-content;
    }
`;

const OrderDetailsColumn2 = styled.div`
    display: grid;
    grid-area: OrderDetailsColumn2;
    grid-template-areas:
        "PickupOptions"
        "PurchaseInfo";
    height: fit-content;

    @media ${MOBILE} {
        grid-gap: ${MOBILE_GRIDGAP};
        grid-template-rows: auto auto auto;
    }

    @media ${TABLET} {
        grid-gap: ${TABLET_GRIDGAP};
        grid-template-rows: auto auto auto;
    }

    @media ${DESKTOP} {
        grid-gap: ${DESKTOP_GRIDGAP};
        grid-template-rows: auto auto auto;
    }
`;

const Grid = styled.span`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "OrderDetailsColumn1 OrderDetailsColumn2";

    @media ${MOBILE} {
        grid-gap: ${MOBILE_GRIDGAP};
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
            "OrderDetailsColumn1"
            "OrderDetailsColumn2";
    }

    @media ${TABLET} {
        grid-gap: ${TABLET_GRIDGAP};
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
            "OrderDetailsColumn1"
            "OrderDetailsColumn2";
    }

    @media ${DESKTOP} {
        grid-gap: ${DESKTOP_GRIDGAP};
    }
`;

export default OrderDetails;
