import { SystemColors } from "@snackpass/design-system";
import { IPurchase } from "@snackpass/snackpass-types";
import React, { useState } from "react";
import { useGate } from "statsig-react";
import styled from "styled-components";

import config from "#config";
import RefundModal from "#refund-modal";

import { ReactComponent as Briefcase } from "src/assets/icons/briefcase-arrow-left.svg";

import { Button } from "./ui";

type Props = {
    purchase: IPurchase;
};

export const RefundButton = ({ purchase }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const allowMultipleRefunds = useGate(
        config.featureGates.allowMultipleRefunds,
    );

    const show = () => setShowModal(true);
    const hide = () => setShowModal(false);

    if (
        (purchase.refund && !purchase.partialRefund) ||
        (purchase.partialRefund && !allowMultipleRefunds.value)
    ) {
        return null;
    }

    return (
        <>
            <RefundModal
                purchase={purchase}
                isVisible={showModal}
                hide={hide}
            />
            <Button onClick={show}>
                <div>
                    <Briefcase fill={SystemColors.v1.sesame} />
                </div>
                <MarginLeft>Refund / Adjust Price</MarginLeft>
            </Button>
        </>
    );
};

const MarginLeft = styled.div`
    margin-left: 8px;
`;
