import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { useHistory } from "react-router-dom";
import { Pencil1Icon } from "@radix-ui/react-icons";

import { useMenus, useActiveMenus } from "#menu-manager/hooks";
import { DataTable } from "src/@/components/ui/data-table";
import { Switch } from "src/@/components/ui/switch";
import { StoreMenu } from "src/api/graphql/generated/types";
import { MenuManagerRoutes } from "#menu-manager/routes";
import { Skeleton } from "src/@/components/ui/skeleton";
import { Button } from "src/@/components/ui/button";

export function MenuTable() {
    const { data, loading, error } = useMenus();
    const history = useHistory();

    useEffect(() => {
        if (error) toast.error(error.message);
    }, [error]);

    const { activeMenus, setActiveMenus, isPending } = useActiveMenus();

    // XXX: we only allow one active menu to be selected for now.
    const activeMenuID = activeMenus?.[0];
    const setActiveMenuID = useCallback(
        async (id?: string) => {
            setWorkingActiveMenuID(id); // optimistic update
            void setActiveMenus(id ? [id] : []).catch((err) => {
                toast.error("Error setting menu active", {
                    description: err?.message,
                });
                setWorkingActiveMenuID(activeMenuID); // on failure, go back to actual value
            });
        },
        [activeMenuID, setActiveMenus],
    );

    const [workingActiveMenuID, setWorkingActiveMenuID] =
        useState(activeMenuID);

    const rows: StoreMenuWithActive[] = useMemo(
        () =>
            data.map((e) => ({
                ...e,
                active: e.id === workingActiveMenuID,
                activePending: isPending,
            })),
        [data, isPending, workingActiveMenuID],
    );

    const columns = useColumns({ setActiveMenuID });

    if (loading)
        return <Skeleton aria-label="skeleton" className="h-96 w-full" />;

    return (
        <DataTable
            data={rows}
            columns={columns}
            className="rounded-none border-x-0"
            emptyText="No menus found."
            getRowClickListener={(row) => () =>
                history.push(
                    MenuManagerRoutes.EDIT_MENU.replace(":id", row.original.id),
                )
            }
        />
    );
}

type UseColumnsOptions = {
    setActiveMenuID: (id: string) => void;
};

// NB: We currently are storing active state of store menus on the store object itself
// if we ever move this state to being on the store object this proxy type can be removed
type StoreMenuWithActive = StoreMenu & {
    active?: boolean;
    activePending?: boolean;
};

function useColumns({
    setActiveMenuID,
}: UseColumnsOptions): ColumnDef<StoreMenuWithActive>[] {
    return useMemo(
        () => [
            {
                header: () => <span className="md:ml-8">Active</span>,
                cell: ({ row }) => (
                    <Switch
                        className="md:ml-8"
                        disabled={row.original.activePending}
                        checked={row.original.active}
                        aria-label={`toggle whether ${row.original.name} is active`}
                        onCheckedChange={(checked) =>
                            setActiveMenuID(checked ? row.original.id : "")
                        }
                        onClick={
                            (e) =>
                                e.stopPropagation() /* avoid propagating to parent's onclick that navigates away */
                        }
                    />
                ),
                id: "Active",
            },
            {
                header: "Name",
                accessorKey: "name",
            },
            {
                header: "Added",
                accessorKey: "createdAt",
                cell: ({ row }) =>
                    DateTime.fromISO(row.original.createdAt).toFormat(
                        "LLL dd, yyyy h:mma",
                    ),
            },
            {
                header: "Updated",
                accessorKey: "updatedAt",
                cell: ({ row }) =>
                    DateTime.fromISO(row.original.updatedAt).toFormat(
                        "LLL dd, yyyy h:mma",
                    ),
            },
            {
                id: "Edit",
                cell: () => (
                    // no need for onclick, the row has on click-- this is just to surface that you can edit to the user.
                    <Button variant="outline">
                        <Pencil1Icon />
                    </Button>
                ),
                size: 10,
            },
        ],
        [setActiveMenuID],
    );
}
