import { create } from "zustand";
import { Canvas } from "fabric";

import {
    LabelProperty,
    LayoutElement,
    ServiceArea,
    TableProperty
} from "#table-editor/types";
import { Shape, Element as Variant } from "src/api/graphql/generated/types";
import { drawElement } from "#table-editor/canvas/drawElement";
import { redrawElementText } from "#table-editor/canvas/redrawElementText";
import { Element } from "#table-editor/canvas/Element";
import { clearCanvas } from "#table-editor/canvas/clearCanvas";
import { mergeElementProperties } from "#table-editor/serviceAreas";

export type EditorControls =
    | {
          mode: "edit";
      }
    | {
          mode: "draw";
          shape: Shape;
          variant: Variant;
      };

export type EditorState = {
    serviceAreas: ServiceArea[];
    activeServiceArea: Omit<ServiceArea, "layout"> | null;
    isReady: boolean;
    isDirty: boolean;
    elements: LayoutElement[];
    controls: EditorControls;
    canvas: Canvas | null;
};

export type EditorAction = {
    // Service area
    addServiceArea(name: string): void;
    setServiceArea(id: string): void;
    setActiveServiceAreaName(name: string): void;
    mergeActiveServiceArea(serviceArea: ServiceArea): void;
    removeServiceArea(id: string): void;

    // Layout elements
    addElement(element: LayoutElement): void;
    removeSelected(id: string): void;

    // Canvas
    setCanvas(canvas: Canvas | null): void;
    setDirty(isDirty: boolean): void;

    // Layout properties -- (element MUST be currently selected)
    setTableData(id: string, properties: TableProperty): void;
    setLabelData(id: string, properties: LabelProperty): void;

    // Editor mode
    setDrawMode(shape: Shape, variant: Variant): void;
    setEditMode(): void;
};

export const createEditorStore = (serviceAreas: ServiceArea[]) =>
    create<EditorState & EditorAction>()(
        // Zustand has an immer middleware, but will contradict the mutable nature of Canvas
        (set, get) => ({
            // Editor state
            serviceAreas,
            isDirty: false,
            isReady: false,
            activeServiceArea: serviceAreas.length > 0 ? serviceAreas[0] : null,
            elements: serviceAreas.length > 0 ? serviceAreas[0].layout : [],
            controls: { mode: "edit" },
            canvas: null,

            // Service area
            addServiceArea: (name) => {
                const { serviceAreas } = get();

                const newServiceArea: ServiceArea = {
                    id: "",
                    name,
                    layout: []
                };

                set({
                    serviceAreas: [...serviceAreas, newServiceArea]
                });
            },
            setServiceArea: (id) => {
                const { serviceAreas, canvas } = get();

                const idx = serviceAreas.findIndex((area) => area.id === id);
                if (idx !== -1) {
                    set({
                        activeServiceArea: serviceAreas[idx],
                        elements: serviceAreas[idx].layout,
                        isReady: false
                    });

                    if (canvas) {
                        clearCanvas(canvas);

                        serviceAreas[idx].layout.forEach((element) =>
                            drawElement(canvas, element)
                        );

                        set({
                            isReady: true
                        });
                    }
                }
            },
            setActiveServiceAreaName: (name) => {
                const { activeServiceArea } = get();

                if (activeServiceArea) {
                    set({
                        activeServiceArea: {
                            ...activeServiceArea,
                            name
                        },
                        isDirty: true
                    });
                }
            },
            mergeActiveServiceArea: (serviceArea: ServiceArea) => {
                const { serviceAreas, activeServiceArea, elements } = get();

                if (activeServiceArea) {
                    set({ isReady: false });
                    const idx = serviceAreas.findIndex(
                        (area) => area.id === activeServiceArea.id
                    );
                    if (idx !== -1) {
                        // We prevent updating elementIds since they are tied to drawn Elements
                        // But we will update existing tableIds to match server
                        const updatedElements = mergeElementProperties(
                            elements,
                            serviceArea.layout
                        );

                        // We prevent replacing elements to avoid
                        // having to redraw every time
                        set({
                            serviceAreas: serviceAreas.toSpliced(
                                idx,
                                1,
                                serviceArea
                            ),
                            activeServiceArea: serviceArea,
                            elements: updatedElements,
                            isReady: true,
                            isDirty: false
                        });
                    }
                }
            },
            removeServiceArea: (id) => {
                const { serviceAreas, setServiceArea } = get();

                const idx = serviceAreas.findIndex((area) => area.id === id);
                if (idx !== -1) {
                    set({
                        serviceAreas: serviceAreas.toSpliced(idx, 1),
                        activeServiceArea: null,
                        isDirty: false
                    });

                    if (idx > 0) {
                        setServiceArea(serviceAreas[idx - 1].id);
                    }
                }
            },

            // Layout elements
            addElement: (element) => {
                const { elements, canvas } = get();

                set({ elements: [...elements, element] });

                if (canvas) {
                    const newElement = drawElement(canvas, element);

                    if (newElement) {
                        canvas.setActiveObject(newElement);
                    }
                }
            },
            removeSelected: (id) => {
                const { elements, canvas } = get();

                const idx = elements.findIndex((element) => element.id === id);
                if (idx !== -1) {
                    set({ elements: elements.toSpliced(idx, 1) });
                }

                if (canvas && canvas.getActiveObject()) {
                    canvas.remove(canvas.getActiveObject()!);
                }
            },

            // Canvas
            setCanvas: (canvas) => {
                const { elements } = get();

                set({ canvas });

                if (canvas) {
                    // Add elements to canvas
                    elements.forEach((element) => drawElement(canvas, element));

                    set({ isReady: true });
                }
            },
            setDirty: (isDirty) => {
                set({ isDirty });
            },

            // Layout properties
            setTableData: (id, properties) => {
                const { canvas, elements } = get();

                const idx = elements.findIndex((element) => element.id === id);
                if (idx !== -1) {
                    const newElement = {
                        ...elements[idx],
                        properties
                    };

                    set({
                        elements: elements.toSpliced(idx, 1, newElement),
                        isDirty: true
                    });
                }

                const selectedElement = canvas?.getActiveObject();
                if (
                    canvas &&
                    selectedElement &&
                    selectedElement.isType(Element.type)
                ) {
                    redrawElementText(
                        canvas,
                        selectedElement as Element,
                        properties.tableName
                    );
                }
            },
            setLabelData: (id, properties) => {
                const { canvas, elements } = get();

                const idx = elements.findIndex((element) => element.id === id);
                if (idx !== -1) {
                    const newElement = {
                        ...elements[idx],
                        properties
                    };

                    set({
                        elements: elements.toSpliced(idx, 1, newElement),
                        isDirty: true
                    });
                }

                const selectedElement = canvas?.getActiveObject();
                if (
                    canvas &&
                    selectedElement &&
                    selectedElement.isType(Element.type)
                ) {
                    redrawElementText(
                        canvas,
                        selectedElement as Element,
                        properties.label
                    );
                }
            },

            // Editor mode
            setDrawMode: (shape, variant) => {
                set({
                    controls: {
                        mode: "draw",
                        shape,
                        variant
                    }
                });
            },

            setEditMode: () => {
                set({ controls: { mode: "edit" } });
            }
        })
    );
