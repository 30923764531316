import { useCallback, useEffect, useState } from "react";

type UseEditorSizeOptions = {
    width?: number;
    height?: number;
};

export function useEditorSize(options?: UseEditorSizeOptions) {
    const getSize = useCallback(
        (width?: number, height?: number) =>
            typeof window !== "undefined"
                ? {
                      width: width ?? window.innerWidth,
                      height: height ?? window.innerHeight
                  }
                : { width: 100, height: 100 },
        []
    );

    const [size, setSize] = useState(getSize());

    useEffect(() => {
        function handleResize() {
            setSize(getSize(options?.width, options?.height));
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [getSize, options?.height, options?.width]);

    useEffect(() => {
        setSize(getSize(options?.width, options?.height));
    }, [getSize, options?.width, options?.height]);

    return size;
}
