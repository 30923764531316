import { toDollar } from "@snackpass/accounting";
import moment from "moment-timezone";

import { useGlobalDate } from "#hooks";
import { NetSalesChart } from "#dashboard/components/sales/net-sales-chart";
import {
    usePurchaseReports,
    usePurchaseReportTotals,
} from "src/api/rest/usePurchaseReports";
import { useFinancialReportsData } from "#financial-reports/hooks/useFinancialReportsData";
import { SalesCard } from "#dashboard/components/sales/SalesCard";

export const Sales = () => {
    const { startDate, endDate } = useGlobalDate();
    const { total: report, isLoading, error } = usePurchaseReportTotals();
    const { data } = useFinancialReportsData("day");

    const daysDiff = endDate.diff(startDate, "days") + 1;
    const { data: lastReport } = usePurchaseReports({
        noRows: true,
        since: moment(startDate).subtract(daysDiff, "d").toDate(),
        until: moment(startDate).toDate(),
    });
    const lastNetSales = lastReport?.total?.netSales || 0;

    return (
        <div>
            <div className="mb-12 grid w-full grid-cols-1 gap-4 md:grid-cols-3">
                <SalesCard
                    title="Net Sales"
                    isLoading={isLoading}
                    isError={!!error}
                    value={error ? "--" : toDollar(report?.netSales || 0)}
                    subValue={
                        <>
                            <span className="font-bold">
                                {`${toDollar(lastNetSales)}`}{" "}
                            </span>
                            {`in prev. ${daysDiff} day${
                                daysDiff > 1 ? "s" : ""
                            }`}
                        </>
                    }
                    toolTipContent={
                        "The subtotal of all sales plus any customer to store fees."
                    }
                />
                <SalesCard
                    title="Orders"
                    isLoading={isLoading}
                    isError={!!error}
                    value={<>{report?.count ?? 0}</>}
                />
                <SalesCard
                    title="Avg. Ticket Size"
                    isLoading={isLoading}
                    isError={!!error}
                    value={
                        <>
                            {toDollar(
                                !report?.netSales
                                    ? 0
                                    : report.netSales / report.count,
                            )}
                        </>
                    }
                />
            </div>
            {data.length === 0 ? (
                <></>
            ) : (
                <NetSalesChart
                    data={data}
                    isError={!!error}
                    isLoading={isLoading}
                    className="mt-3"
                />
            )}
        </div>
    );
};
