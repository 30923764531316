import { PropsWithChildren } from "react";

import { cn } from "src/@/lib/utils";

type SectionProps = PropsWithChildren<{
    label: string;
    className?: string;
}>;

export function Section({ label, children, className }: SectionProps) {
    return (
        <div className={cn("flex flex-col gap-2", className)}>
            <h2 className="text-base text-white underline">{label}</h2>
            <div className="flex flex-row items-center gap-2.5">{children}</div>
        </div>
    );
}
