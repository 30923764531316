import { SystemColors } from "@snackpass/design-system";
import { IPurchase } from "@snackpass/snackpass-types";
import { Divider } from "antd";
import Dinero from "dinero.js";
import { last } from "lodash";
import moment from "moment";
import styled from "styled-components";

type Props = {
    purchase: IPurchase;
};

export function RefundSummary({ purchase }: Props) {
    const hasRefunds = purchase.refunds.length > 0;
    const hasUpcharge = !!purchase.upCharge;

    if (!hasRefunds && !hasUpcharge) return null;

    return (
        <>
            <SummaryContainer>
                {hasRefunds && (
                    <>
                        <SummaryTitle>Refund History</SummaryTitle>
                        <SummaryReason>
                            Reason: {purchase.refundReason}
                        </SummaryReason>
                        {purchase.refunds.map((refund, index) => (
                            <SummaryItem key={`refund-${index}`}>
                                <SummaryTime>
                                    {moment(refund.refundedAt).format(
                                        "MMM D, YYYY h:mm A",
                                    )}
                                </SummaryTime>
                                <SummaryAmount isRefund>
                                    {Dinero({
                                        amount: refund._amount,
                                        currency: "USD",
                                    }).toFormat("$0,0.00")}
                                </SummaryAmount>
                            </SummaryItem>
                        ))}
                    </>
                )}
                {hasUpcharge && (
                    <>
                        <SummaryTitle>Upcharge History</SummaryTitle>
                        <SummaryItem>
                            <SummaryTime>
                                {moment(
                                    last(purchase.charges)?.createdAt,
                                ).format("MMM D, YYYY h:mm A")}
                            </SummaryTime>
                            <SummaryAmount>
                                {Dinero({
                                    amount: Math.round(
                                        (purchase.upChargeAmount || 0) * 100,
                                    ),
                                    currency: "USD",
                                }).toFormat("$0,0.00")}
                            </SummaryAmount>
                        </SummaryItem>
                    </>
                )}
            </SummaryContainer>
            <Divider />
        </>
    );
}

const SummaryContainer = styled.div`
    margin-top: 20px;
    padding: 15px;
    background-color: ${SystemColors.v1.gray90};
    border-radius: 8px;
`;

const SummaryTitle = styled.h3`
    font-size: 16px;
    font-weight: 600;
    color: ${SystemColors.v1.sesame};
    margin-bottom: 10px;
`;

const SummaryItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid ${SystemColors.v1.gray80};

    &:last-child {
        border-bottom: none;
    }
`;

const SummaryTime = styled.span`
    font-size: 14px;
    color: ${SystemColors.v1.sesame};
`;

const SummaryAmount = styled.span<{ isRefund?: boolean }>`
    font-size: 14px;
    font-weight: 600;
    color: ${(props) =>
        props.isRefund ? SystemColors.v1.melon50 : SystemColors.v1.parsley50};
`;

const SummaryReason = styled.span`
    font-size: 14px;
    color: ${SystemColors.v1.sesame};
    font-style: italic;
`;
