import moment, { Moment } from "moment";
import { DateTime } from "luxon";
import { toDollar } from "@snackpass/accounting";
import { cloneDeep } from "lodash";

import colors from "#reusable/colors/colors.json";
import {
    ComparisonType,
    GranularityType,
    ReportType,
} from "#reports/sales-summary/types";

// Filter header options
export const rangePresets = [
    { label: "Today", value: [moment(), moment()] },
    {
        label: "Yesterday",
        value: [moment().subtract(1, "day"), moment().subtract(1, "day")],
    },
    {
        label: "This Week",
        value: [moment().startOf("isoWeek"), moment().endOf("day")],
    },
    {
        label: "Last Week",
        value: [
            moment().subtract(1, "week").startOf("isoWeek"),
            moment().subtract(1, "week").endOf("isoWeek"),
        ],
    },
    {
        label: "This Month",
        value: [moment().startOf("month"), moment().endOf("day")],
    },
    {
        label: "Last Month",
        value: [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month").endOf("day"),
        ],
    },
    {
        label: "This Year",
        value: [moment().startOf("year"), moment().endOf("day")],
    },
    {
        label: "Last Year",
        value: [
            moment().subtract(1, "year").startOf("year"),
            moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
    },
];

export const granularityOptions = [
    { value: GranularityType.DAILY, label: "Daily" },
    { value: GranularityType.WEEKLY, label: "Weekly" },
    { value: GranularityType.MONTHLY, label: "Monthly" },
];
export const sourceOptions = [
    { value: "app", label: "App" },
    { value: "kiosk", label: "Kiosk" },
    { value: "online", label: "Online" },
    { value: "register", label: "Register" },
    { value: "thirdParty", label: "Integrations" },
];

export const snackpassChannelOption = {
    value: "snackpass",
    label: "Snackpass",
};
export const otherChannelOption = { value: "other", label: "Other" };

// Commented out ones that are currently not supported by our reports backend.
export const channelOptionsMap: Record<
    string,
    {
        value: string;
        label: string;
    }
> = {
    doordash: { value: "doordash", label: "Doordash" },
    fantuan: { value: "fantuan", label: "Fantuan" },
    grubhub: { value: "grubhub", label: "Grubhub" },
    uberPostmates: { value: "uberPostmates", label: "Postmates" },
    uberEats: { value: "uberEats", label: "UberEats" },
    chowbus: { value: "chowbus", label: "Chowbus" },
    deliverycom: { value: "deliverycom", label: "Delivery.com" },
    ricepo: { value: "ricepo", label: "Ricepo" },
    ezcater: { value: "ezcater", label: "Ezcater" },
    chownow: { value: "chownow", label: "Chownow" },
    hungrypanda: { value: "hungrypanda", label: "Hungrypanda" },
};

export const fulfillmentOptions = [
    { value: "pickup", label: "Pickup" },
    { value: "delivery", label: "Delivery" },
    { value: "dineIn", label: "Dine-in" },
];
export const paymentOptions = [
    { value: "creditCard", label: "Credit Card" },
    { value: "cash", label: "Cash" },
    { value: "giftCard", label: "Gift Card" },
    { value: "other", label: "Other" },
];

export const formatRange = (range: Moment[], granularity?: GranularityType) => {
    const rangeSpansYear = range[0].year() != range[1].year();

    if (range[1].diff(range[0], "days") == 0) {
        return range[0].format("MMM D, YYYY");
    }

    return granularity == GranularityType.MONTHLY
        ? `${range[0].format(
              `MMM ${rangeSpansYear ? "YYYY" : ""}`,
          )} - ${range[1].format("MMM YYYY")}`
        : `${range[0].format(
              `MMM D${rangeSpansYear ? ", YYYY" : ""}`,
          )} - ${range[1].format("MMM D, YYYY")}`;
};

export const getComparisonRange = (
    baseRange: Moment[],
    comp: ComparisonType,
): Moment[] => {
    if (comp == ComparisonType.MONTH) {
        return [
            baseRange[0].clone().add(-1, "month"),
            baseRange[1].clone().add(-1, "month"),
        ];
    } else if (comp == ComparisonType.YEAR) {
        return [
            baseRange[0].clone().add(-1, "year"),
            baseRange[1].clone().add(-1, "year"),
        ];
    } else if (comp == ComparisonType.PERIOD) {
        const rangePeriodDiff = baseRange[0].diff(baseRange[1], "day");
        return [
            baseRange[0].clone().add(rangePeriodDiff - 1, "day"),
            baseRange[1].clone().add(rangePeriodDiff - 1, "day"),
        ];
    } else if (comp == ComparisonType.WEEK) {
        return [
            baseRange[0].clone().add(-1, "week"),
            baseRange[1].clone().add(-1, "week"),
        ];
    }
    return [...baseRange];
};

export const periodOptionLabels = {
    [ComparisonType.NONE]: "No Comparison",
    [ComparisonType.WEEK]: "Previous Week",
    [ComparisonType.MONTH]: "Previous Month",
    [ComparisonType.YEAR]: "Previous Year",
    [ComparisonType.PERIOD]: "Previous Period",
};

// Essentially the same date separations as in aggregateDatedRows, but just returning date objects
export const datePointsFromRangeAndGranularity = (
    range: Moment[],
    granularity: GranularityType,
): Moment[] => {
    let currentDate = range[0].clone();
    const datePoints = [];
    const endDate = range[1].clone().startOf("day");
    while (endDate.diff(currentDate, "days") >= 0) {
        datePoints.push(currentDate);

        if (granularity == GranularityType.MONTHLY) {
            currentDate = currentDate
                .clone()
                .add(1, "months")
                .startOf("month")
                .startOf("day");
        } else if (granularity == GranularityType.WEEKLY) {
            currentDate = currentDate
                .clone()
                .startOf("isoWeek")
                .add(1, "weeks")
                .startOf("day");
        } else {
            currentDate = currentDate.clone().add(1, "day").startOf("day");
        }
    }
    return datePoints;
};

export const aggregateDatedRows = <T extends { date: string }>(
    rowData: T[],
    aggregateRows: (acc: T, toAdd: T) => T,
    granularity: GranularityType,
) => {
    const toReturn: T[] = [];
    let currentAggregate: T | null = null;
    let currentAggregateEnd: Moment | null = null;

    const rangeSpansYear =
        rowData.length &&
        moment(rowData[0].date).year() !=
            moment(rowData[rowData.length - 1].date).year();

    // aggregate based on granularity, because are only receiving daily data.
    rowData.forEach((row: T, index: number) => {
        const currentDate = moment(row.date);

        if (!currentAggregate) {
            // begin keeping track of an aggregate if you aren't yet, set the end range of dates we will sum
            currentAggregate = cloneDeep(row);
            if (granularity == GranularityType.MONTHLY) {
                currentAggregateEnd = currentDate
                    .clone()
                    .endOf("month")
                    .startOf("day");
            } else if (granularity == GranularityType.WEEKLY) {
                currentAggregateEnd = currentDate
                    .clone()
                    .startOf("isoWeek")
                    .add(6, "days");
            } else {
                currentAggregateEnd = currentDate;
            }
        } else {
            // aggregate the current row onto the current aggregate.
            currentAggregate = aggregateRows(currentAggregate, row);
        }
        const isCurrentDateEndOfAggregateRange =
            currentDate.diff(currentAggregateEnd, "days") >= 0 ||
            index == rowData.length - 1;
        if (isCurrentDateEndOfAggregateRange) {
            // upon reaching the end range or the end of the rows, push the current aggregate to the list with a newly formatted date.
            const aggregateDate = moment(currentAggregate.date);
            if (granularity != GranularityType.DAILY)
                currentAggregate.date = aggregateDate.isSame(
                    currentDate,
                    "days",
                )
                    ? aggregateDate.format("M/D")
                    : `${aggregateDate.format("M/D")}-${currentDate.format(
                          "M/D",
                      )}`;
            else
                currentAggregate.date = rangeSpansYear
                    ? aggregateDate.format("M/D/YY")
                    : aggregateDate.format("M/D");

            toReturn.push(currentAggregate);
            currentAggregate = null;
        }
    }, []);
    return toReturn;
};

export const formatHour = (hour: number) => {
    const dateTime = DateTime.fromObject({ hour });
    const decoratedHour = dateTime.toFormat("h a");
    return decoratedHour;
};

export const formatYAxisTick = (value: string | number, isMoney: boolean) => {
    if (typeof value != "number") return value;
    return isMoney ? toDollarFormatted(value) : value;
};

// Add commas to to separate every 3 digits of dollars
export const toDollarFormatted = (value: number): string => {
    const dollarString = toDollar(value).slice(1);

    const parts = dollarString.split(".");
    const dollars = parts[0];
    const cents = parts[1] || "";

    const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${formattedDollars}.${cents}`;
};

// Add commas to to separate every 3 digits
export const formatNumber = (number: number): string => {
    // Convert the number to a string
    const numberString = Math.round(number).toString();

    // Use a regular expression to match groups of three digits from the end
    const regex = /\B(?=(\d{3})+(?!\d))/g;

    // Add commas to the number string using the regex
    const numberWithCommas = numberString.replace(regex, ",");

    return numberWithCommas;
};

export const tooltipStyles = {
    backgroundColor: colors["neutral-700"],
    displayColors: true,
    cornerRadius: 16,
    xPadding: 15,
    yPadding: 12,
    titleFontSize: 18,
    titleFontStyle: "normal",
    footerFontStyle: "normal",
    footerFontSize: 18,
    footerMarginTop: 8,
    titleMarginBottom: 8,
    bodyFontSize: 18,
    bodySpacing: 4,
    mode: "index",
};

const DATA_POINTS_MINIMUM = 5;

// Attempts to adhere to the granularity that the user selects, unless it results in too few data points.
export const getGraphGranularity = (
    range: Moment[],
    selectedGranularity: GranularityType,
) => {
    const highestGranularity =
        range[1].diff(range[0], "month") + 1 >= DATA_POINTS_MINIMUM
            ? GranularityType.MONTHLY
            : range[1].diff(range[0], "week") + 1 >= DATA_POINTS_MINIMUM
              ? GranularityType.WEEKLY
              : GranularityType.DAILY;

    if (selectedGranularity === GranularityType.MONTHLY) {
        return highestGranularity;
    }

    if (
        selectedGranularity === GranularityType.WEEKLY &&
        highestGranularity !== GranularityType.DAILY
    ) {
        return GranularityType.WEEKLY;
    }

    return GranularityType.DAILY;
};

export const isMultiLocationReport = (reportType: ReportType) => {
    if (
        reportType === ReportType.LOCATION_SALES_REPORT ||
        reportType === ReportType.LOCATION_MENU_CATEGORY_REPORT ||
        reportType === ReportType.LOCATION_MENU_ITEM_REPORT ||
        reportType === ReportType.GIFT_CARD_BALANCE_REPORT ||
        reportType === ReportType.GIFT_CARD_PURCHASE_REPORT ||
        reportType === ReportType.GIFT_CARD_TRANSACTION_REPORT
    ) {
        return true;
    }
    return false;
};
