import Skeleton from "react-loading-skeleton";

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";
import { ReactComponent as InfoIcon } from "src/assets/icons/Info.svg";

type Props = {
    title: string | JSX.Element;
    value: string | JSX.Element;
    subValue?: string | JSX.Element;
    toolTipContent?: string | JSX.Element;
    isLoading?: boolean;
    isError?: boolean;
};

export const SalesCard = ({
    title,
    value,
    toolTipContent,
    subValue,
    isLoading,
    isError,
}: Props) => {
    if (isLoading) {
        return <Skeleton className="h-32 rounded-xl" />;
    }
    return (
        <div className={"flex-1 rounded-xl border border-neutral-400 p-6"}>
            <p className="text-small">
                {title}{" "}
                {toolTipContent ? <CardTooltip body={toolTipContent} /> : null}
            </p>
            <h1 className="text-xlarge">{isError ? "--" : value}</h1>
            {subValue && !isError ? (
                <p className="mb-0 mt-2 text-small text-neutral-600">
                    {subValue}
                </p>
            ) : null}
        </div>
    );
};

const CardTooltip = ({ body }: { body: string | JSX.Element }) => (
    <TooltipProvider delayDuration={100}>
        <Tooltip>
            <TooltipTrigger asChild>
                <InfoIcon className={"mx-1 h-3 w-3 cursor-pointer"} />
            </TooltipTrigger>

            <TooltipContent
                className="z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                sideOffset={5}
            >
                {body}
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
);
