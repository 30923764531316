export interface ShortcutData {
    id: string;
    title: string;
    description: string;
    image: string;
    link: string;
}

export const shortcuts: ShortcutData[] = [
    {
        id: "support-center",
        title: "Support Center",
        description: "Read help articles and chat with our support team",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Farticle.png?alt=media&token=67806a5e-83af-4ba2-bfe8-4e08a3b5124b",
        link: "https://support.snackpass.co/en/",
    },
    {
        id: "kitchen-display",
        title: "Kitchen Display",
        description: "Real-time kitchen management and tracking",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fbef6f9a5-6ede-45a2-a485-998f7216642c.png?alt=media&token=106ad9c9-a87c-4b20-8f20-3b8f26bad298",
        link: "https://store.snackpass.co/collections/kitchen-display",
    },
    {
        id: "pickup-screen",
        title: "Pickup Screen",
        description: "Manage customer pickups with AI voice.",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fe2f59dcf-1464-4a7d-b934-93da261fb922%20(1).jpeg?alt=media&token=fcd7c77b-fb15-4a5b-84c4-e84a11ab1dd0",
        link: "https://store.snackpass.co/collections/pickup-screens",
    },
    {
        id: "standing-kiosk-gen-2",
        title: "Standing Kiosk Gen 2",
        description: "Our new standing kiosk design",
        image: "https://store.snackpass.co/cdn/shop/files/5ClmoNXUn0OGiXIhTTmrLiFNqTw.webp?v=1722992842&width=823",
        link: "https://store.snackpass.co/products/standing-kiosk-gen-3",
    },
    {
        id: "handheld",
        title: "Handheld",
        description:
            "Everything you need to do on a register in a handheld size",
        image: "https://store.snackpass.co/cdn/shop/files/Handheld_702598cd-f9d7-4bbf-aa50-0d9546b8f378.jpg?v=1719322929&width=1426",
        link: "https://store.snackpass.co/products/handheld-register-v2",
    },
    {
        id: "partner-app",
        title: "Partner App",
        description: "Manage your store on the go",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2F993d2178-b15c-49c6-be8a-b2703f50358e.png?alt=media&token=a7ff5857-7dcc-4235-b60d-65f66b8f5622",
        link: "https://apps.apple.com/us/app/snackpass-partner/id1591165543",
    },
    {
        id: "branding",
        title: "Branding",
        description: "Set your kiosk splash video, tip image, logo and more",
        image: "https://store.snackpass.co/cdn/shop/files/Presotea_Harware_2024_02_090562copy.jpg?v=1725483384&width=823",
        link: "/settings-brand-and-appearance",
    },
    {
        id: "gift-cards",
        title: "Gift Cards",
        description: "Boost sales with gift cards",
        image: "https://framerusercontent.com/images/xLhp6ivrk5Q1FnyfQcGOy2iZDso.png",
        link: "/settings-gift-card",
    },
    {
        id: "sms-campaigns",
        title: "SMS Campaigns",
        description: "Create and track SMS marketing campaigns",
        image: "https://framerusercontent.com/images/dZJaMuCh6kiMg3EiHAjUXVR7YA.png?scale-down-to=1024",
        link: "/guestbook/campaigns/sms",
    },
    {
        id: "online-ordering",
        title: "Online Ordering",
        description: "Receive and manage online orders",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2F5707f8dc-7419-49b3-960d-1a2b9230ba21.png?alt=media&token=02e9fcb5-795d-4769-84de-64043eb30720",
        link: "online-ordering",
    },
    {
        id: "branded-mobile-app",
        title: "Branded Mobile App",
        description: "Customize your branded mobile app",
        image: "https://framerusercontent.com/images/4w31Ef1hpE82XKGxiv2cCD8NEPo.png?scale-down-to=1024",
        link: "https://airtable.com/appy8eQbMkVKrsnZ4/paglW8OzypSkSbqep/form",
    },
    {
        id: "create-promo",
        title: "Create a Promo",
        description: "Create and track promotions and rewards",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2FF2peQl2MnxvgCu82xlH4Zg4J2JM.png?alt=media&token=54cd90fa-28c9-4bad-82d6-9483bb0c6d70",
        link: "/promotion",
    },
    {
        id: "menu-insights",
        title: "Menu Insights",
        description: "Analyze your menu performance and trends",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2F2QId9wTLZIyNqBEZxKmBBmzHaY.png?alt=media&token=de80da90-7655-4eda-a914-b3fae07c76cd",
        link: "/item-insights",
    },
    {
        id: "invite-admin",
        title: "Invite an Admin",
        description: "Add admins or employees for clocking in and out",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2FFrame%201350.png?alt=media&token=45a89f6b-9be8-4343-b1d4-6dc7ae574907",
        link: "/users",
    },
];
