import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";
import React from "react";

export const LoadingOrderListRow = () => (
    <Container>
        <LoadingTitle />
        <LoadingSubitle />
        <LoadingText />
    </Container>
);

const Container = styled.div`
    padding: 16px;
    border-bottom: 1px solid ${SystemColors.v1.gray90};
`;

const LoadingShine = styled.div`
    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }

    background: linear-gradient(
        110deg,
        rgba(198 203 222 / 25%) 8%,
        rgba(198 203 222 / 7%) 18%,
        rgba(198 203 222 / 25%) 33%
    );
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    margin-bottom: 8px;
`;

const LoadingTitle = styled(LoadingShine)`
    height: 18px;
    width: 120px;
`;

const LoadingSubitle = styled(LoadingShine)`
    height: 16px;
    width: 200px;
`;

const LoadingText = styled(LoadingShine)`
    height: 16px;
    width: 160px;
`;
