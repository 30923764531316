import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useGetServiceAreasQuery } from "src/api/graphql/generated/types";
import { getActiveStoreId } from "src/redux/slices";
import { useAppSelector } from "src/redux/hooks";
import { fromGQLServiceArea } from "#table-editor/serviceAreas";
import { ServiceArea } from "#table-editor/types";
import { FirstServiceAreaDialog } from "#table-editor/components/FirstServiceAreaDialog";

import { Editor, Panel, useEditorSize } from "./Editor";

export function TablesEditor() {
    const storeId = useAppSelector(getActiveStoreId);

    const { data, loading: loadingServiceAreas } = useGetServiceAreasQuery({
        variables: {
            storeId
        }
    });

    const [serviceAreas, setServiceAreas] = useState<ServiceArea[]>();

    const ref = useRef<HTMLDivElement>(null);
    const size = useEditorSize();

    const [width, setWidth] = useState(size.width);
    const [height, setHeight] = useState(size.height);

    useEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();

            setWidth(rect.width);

            // Not the best way to determine height of canvas, but because of some generalized styling that
            // affects all pages, the current page does not properly reflect the remaining
            // space.
            setHeight(window.innerHeight - rect.top);
        }
    }, [ref.current]);

    useEffect(() => {
        if (loadingServiceAreas || !data) return;

        setServiceAreas(data.serviceAreas.map(fromGQLServiceArea));
    }, [data, loadingServiceAreas]);

    const isLoading = loadingServiceAreas || serviceAreas === undefined;

    return (
        <div ref={ref} className="h-full w-full overflow-hidden">
            {isLoading ? (
                <Skeleton className="h-32" />
            ) : (
                <Editor
                    serviceAreas={serviceAreas}
                    width={width}
                    height={height}
                    grid={{
                        size: 30,
                        snap: { gridSize: 30, fraction: 1 }
                    }}
                >
                    <Panel />
                    <FirstServiceAreaDialog />
                </Editor>
            )}
        </div>
    );
}
