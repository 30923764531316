import { useCallback } from "react";

import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { Section } from "#table-editor/components/Editor/Panel/Section";
import { Button } from "src/@/components/ui/button";

export function ModifyBarrier({ id }: { id: string }) {
    const editor = useEditorStore();

    const onClickDelete = useCallback(() => {
        editor.getState().removeSelected(id);
    }, [editor, id]);

    return (
        <Section label="Edit Barrier">
            <div className="flex w-full flex-col">
                <Button
                    type="button"
                    variant="destructive"
                    onClick={onClickDelete}
                >
                    <span>Delete</span>
                </Button>
            </div>
        </Section>
    );
}
