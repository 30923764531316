import React from "react";
import { Card, CardContent } from "src/@/components/ui/card";
import { Link } from "react-router-dom";
import { ShortcutData } from "./shortcuts-data";
import { IStore } from "@snackpass/snackpass-types";

interface ShortcutsCardProps {
    shortcut: ShortcutData;
    store: IStore | null;
}

export function ShortcutsCard({ shortcut, store }: ShortcutsCardProps) {
    const isExternalLink =
        shortcut.link.startsWith("http") ||
        shortcut.title === "Online Ordering";
    const href =
        shortcut.title === "Online Ordering"
            ? `https://order.snackpass.co/${store?.slug}`
            : shortcut.link;
    const LinkComponent = isExternalLink ? "a" : Link;
    const linkProps = isExternalLink
        ? {
              href,
              target: "_blank",
              rel: "noopener noreferrer",
              className: "no-underline",
          }
        : { to: href, className: "no-underline" };

    return (
        <LinkComponent {...(linkProps as any)}>
            <Card className="overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg cursor-pointer">
                <CardContent className="flex flex-col p-4">
                    <div className="flex-grow">
                        <h3 className="text-base font-semibold mb-1">
                            {shortcut.title}
                        </h3>
                        <p className="text-sm text-muted-foreground mb-2 line-clamp-2 h-12">
                            {shortcut.description}
                        </p>
                    </div>
                    <div className="w-full aspect-square overflow-hidden rounded-md">
                        <img
                            src={shortcut.image}
                            alt={shortcut.title}
                            className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105 select-none"
                            draggable="false"
                        />
                    </div>
                </CardContent>
            </Card>
        </LinkComponent>
    );
}
