import { PlusIcon } from "@radix-ui/react-icons";
import { useCallback, useState } from "react";

import { NewMenuDialog, MenuTable } from "#menu-manager/components";
import { Button } from "src/@/components/ui/button";

export function MenuTableScreen() {
    const [newMenuDialogOpen, setNewMenuDialogOpen] = useState(false);
    const open = useCallback(() => setNewMenuDialogOpen(true), []);
    const close = useCallback(() => setNewMenuDialogOpen(false), []);

    return (
        <div>
            <div className="flex items-center justify-between px-8 py-4">
                <NewMenuDialog open={newMenuDialogOpen} close={close} />
                <span className="text-large font-semibold">Menu Manager</span>
                <div className="flex items-center">
                    <Button size="sm" onClick={open}>
                        <PlusIcon className="mr-1" />
                        New Menu
                    </Button>
                </div>
            </div>
            <MenuTable />
        </div>
    );
}
