import { useCallback } from "react";
import { Next } from "react-router-guards";

import { useAppSelector } from "src/redux/hooks";
import { getActiveStore, getUser } from "src/redux/selectors";
import { useEmployeeOnboardingAccess } from "#navigation/guards/useEmployeeOnboardingAccess";

import { Routes } from "../routes";

import { FromRouteProps, ToRouteProps } from "./types";

export function useRequireOnboardedForRoute() {
    const activeStore = useAppSelector(getActiveStore);
    const activeUser = useAppSelector(getUser);

    const isSnackpassEmployee =
        activeUser?.snackpassPermissions.isSnackpassEmployee ?? false;
    const employeeOnboardingAccess = useEmployeeOnboardingAccess();

    return useCallback(
        (to: ToRouteProps, _from: FromRouteProps | null, next: Next) => {
            const path = to.match.path as Routes;
            if (employeeOnboardingAccess) {
                next();
            } else if (
                // Show Snackpass Admin tabs even if not done onboarding
                isSnackpassEmployee &&
                [
                    Routes.InternalSettings,
                    Routes.InternalFees,
                    Routes.InternalSeniorSupport,
                ].includes(path)
            ) {
                next();
            } else if (activeStore?.onboardingComplete) {
                path === Routes.Onboarding
                    ? next.redirect(Routes.Index)
                    : next();
            } else {
                [
                    // Welcome
                    Routes.Onboarding,
                    // My Account
                    Routes.SettingsAccount,
                    Routes.MyAccountTeams,
                    // Menu
                    Routes.MenuEditor,
                    Routes.MenuInventoryManager,
                    Routes.MultiMenus,
                    // Teams
                    Routes.SettingsPermissions,
                    // Log Out
                    Routes.Logout,
                ].includes(path)
                    ? next()
                    : next.redirect(Routes.Onboarding);
            }
        },
        [activeStore?.onboardingComplete, employeeOnboardingAccess],
    );
}
