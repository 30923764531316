import _ from "lodash";

import {
    EmployeeShiftDetails,
    PaySummaryByDateRange,
} from "src/api/graphql/generated/types";

import { DateTime } from "luxon";
import {
    AttendancePayPeriodStrategy,
    EmployeePermissions,
    IAttendanceSettings,
} from "@snackpass/snackpass-types";

export const getInitials = (firstName: string, lastName: string): string => {
    if (firstName.length > 0 && lastName.length > 0) {
        return (
            firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
        );
    }
    return "";
};

export type ShiftByEmployeeRole = {
    role: string;
    shifts: [EmployeeShiftDetails, ...EmployeeShiftDetails[]];
};

export const groupShiftsByEmployeeRole = (
    shifts: EmployeeShiftDetails[],
): ShiftByEmployeeRole[] =>
    _.chain(shifts)
        .groupBy("employee.role")
        .map((shifts, role) => ({ role, shifts }))
        .value();

export const getEmployeesWithOngoingShifts = (
    paySummary: PaySummaryByDateRange,
): string[] => {
    const employeesWithOngoing: string[] = [];
    paySummary.employeesWithOngoingShifts.forEach((employee) => {
        if (employee.name) {
            employeesWithOngoing.push(employee.name);
        }
    });
    return employeesWithOngoing;
};

export type TimeRangeValues = {
    startDate: DateTime;
    endDate: DateTime;
    relatedRanges: TimeRelatedRangeOption[];
};

export type TimeRelatedRangeOption = {
    label: string;
    value: TimeRange;
};

export type TimeRange = {
    start: DateTime;
    end: DateTime;
};

/**
 * We may be provided dates from the URL query string. If so we need to convert them to DateTime objects
 * and produce an entry for the drop down picker which will just be "custom"
 * @param startRaw
 * @param endRaw
 * @returns
 */
export const setupStartAndEndForStrings = (
    startRaw: string,
    endRaw: string,
): TimeRangeValues => {
    let endDate = DateTime.now();
    let startDate = endDate.minus({ days: 7 });
    const relatedRanges: TimeRelatedRangeOption[] = [];
    const testStart = DateTime.fromJSDate(new Date(startRaw));
    const testEnd = DateTime.fromJSDate(new Date(endRaw));
    if (testStart.isValid && testEnd.isValid) {
        startDate = testStart;
        endDate = testEnd;
    }
    return {
        startDate,
        endDate,
        relatedRanges,
    };
};

/**
 * Based on the store's pay period setting this function will produce the most recent pay periods
 * for a month period. It uses another helper that provides the last 3 and then modifies the results
 * to produce the last 1, 2, or 4 depending on the strategy
 * @param storeSettings
 * @param endDateIncoming
 * @returns
 */
export const setupDateRangeValuesForFullMonthPeriod = (
    storeSettings: IAttendanceSettings | undefined,
    endDateIncoming?: DateTime,
): TimeRelatedRangeOption[] => {
    let { relatedRanges } = setupDateRangeValues(
        storeSettings,
        endDateIncoming,
    );
    if (storeSettings?.strategy) {
        switch (storeSettings.strategy) {
            case AttendancePayPeriodStrategy.Weekly:
                // need to add one more week to get to a month of weeks
                if (relatedRanges.length === 3) {
                    relatedRanges.push({
                        label: "Final",
                        value: {
                            start: relatedRanges[2].value.start.minus({
                                days: 7,
                            }),
                            end: relatedRanges[2].value.end.minus({ days: 7 }),
                        },
                    });
                }
                break;
            case AttendancePayPeriodStrategy.SemiMonthly:
                // only need 2x two week periods
                relatedRanges = relatedRanges.slice(0, 2);
                break;
            case AttendancePayPeriodStrategy.Monthly:
                // only need 1x four week period
                relatedRanges = relatedRanges.slice(0, 1);
                break;
        }
    }
    return relatedRanges;
};

/**
 * This function will examine the store's attendance settings to see if they have a pay period strategy set.
 * If so it will produce the most recent pay period that ends prior to the current date (or endDateIncoming if provided).
 * It will also produce an array with that most recent pay period and the 2 prior pay periods (for that strategy) suitable
 * for being shown in the drop down selector for pay periods (so a label and start/end dates for each).
 * Finally if there is no store setting it will just produce a single pay period for the last 7 days
 * @param storeSettings
 * @param endDateIncoming
 * @returns
 */
export const setupDateRangeValues = (
    storeSettings: IAttendanceSettings | undefined,
    endDateIncoming?: DateTime,
): TimeRangeValues => {
    let endDate = endDateIncoming ?? DateTime.now();
    let startDate = endDate.minus({ days: 7 });
    let relatedRanges: TimeRelatedRangeOption[] = [
        {
            label: "Custom",
            value: {
                start: startDate,
                end: endDate,
            },
        },
    ];
    if (storeSettings?.strategy) {
        const now = endDate ?? DateTime.now();
        const currentDayOfWeek = now.weekday;
        let daysDifference =
            currentDayOfWeek - (storeSettings.startOfWeek ?? 1);
        if (daysDifference < 0) {
            daysDifference += 7;
        }
        const oneMonthAgo = now.minus({ months: 1 });
        switch (storeSettings.strategy) {
            case AttendancePayPeriodStrategy.Weekly:
                startDate = now.minus({ days: daysDifference + 7 });
                endDate = now.minus({ days: daysDifference + 1 });
                relatedRanges = getWeeklyOptions(startDate, endDate, 7);
                break;
            case AttendancePayPeriodStrategy.BiWeekly:
                startDate = now.minus({ days: daysDifference + 14 });
                endDate = now.minus({ days: daysDifference + 1 });
                relatedRanges = getWeeklyOptions(startDate, endDate, 14);
                break;
            case AttendancePayPeriodStrategy.SemiMonthly:
                // pay on the 1st and 16th
                if (now.day > 15) {
                    startDate = DateTime.fromObject({
                        year: now.year,
                        month: now.month,
                        day: 1,
                    });
                    endDate = DateTime.fromObject({
                        year: now.year,
                        month: now.month,
                        day: 15,
                    });
                } else {
                    startDate = DateTime.fromObject({
                        year: oneMonthAgo.year,
                        month: oneMonthAgo.month,
                        day: 16,
                    });
                    endDate = oneMonthAgo.endOf("month");
                }
                relatedRanges = getMonthlyOptions(startDate, endDate, true);
                break;
            case AttendancePayPeriodStrategy.Monthly:
                if (now.day > (storeSettings.startOfMonth ?? 0) + 1) {
                    startDate = DateTime.fromObject({
                        year: oneMonthAgo.year,
                        month: now.month,
                        day: (storeSettings.startOfMonth ?? 0) + 1,
                    });
                    endDate = DateTime.fromObject({
                        year: now.year,
                        month: now.month + 1,
                        day: storeSettings.startOfMonth ?? 1,
                    });
                } else {
                    startDate = DateTime.fromObject({
                        year: now.minus({ months: 2 }).year,
                        month: now.minus({ months: 2 }).month,
                        day: (storeSettings.startOfMonth ?? 0) + 1,
                    });
                    endDate = DateTime.fromObject({
                        year: oneMonthAgo.year,
                        month: oneMonthAgo.month,
                        day: storeSettings.startOfMonth ?? 1,
                    });
                }
                relatedRanges = getMonthlyOptions(startDate, endDate, false);
                break;
        }
    }
    return {
        startDate,
        endDate,
        relatedRanges,
    };
};

/**
 * This function is a helper for setupDateRangeValues() above that takes a start date, end date, and number of days to go back
 * and produces an array of the 3 most recent pay periods based on those inputs. The return format is useful for a
 * drop down selector (label and start/end dates for each)
 * @param startDate
 * @param endDate
 * @param daysBack
 * @returns
 */
const getWeeklyOptions = (
    startDate: DateTime,
    endDate: DateTime,
    daysBack: number,
) => {
    const dropDownResults = [];
    const onePreviousStart = startDate.minus({ days: daysBack });
    const onePreviousEnd = startDate.minus({ days: 1 });
    const twoPreviousStart = onePreviousStart.minus({ days: daysBack });
    const twoPreviousEnd = onePreviousStart.minus({ days: 1 });
    dropDownResults.push({
        label: getDateLabel(startDate, endDate),
        value: {
            start: startDate,
            end: endDate,
        },
    });
    dropDownResults.push({
        label: getDateLabel(onePreviousStart, onePreviousEnd),
        value: {
            start: onePreviousStart,
            end: onePreviousEnd,
        },
    });
    dropDownResults.push({
        label: getDateLabel(twoPreviousStart, twoPreviousEnd),
        value: {
            start: twoPreviousStart,
            end: twoPreviousEnd,
        },
    });
    return dropDownResults;
};

/**
 * This is another helper for setupDateRangeValues() above
 * It produces the last 3 pay periods for a semi-monthly or monthly pay period strategy
 * @param startDate
 * @param endDate
 * @param semiMonthly
 * @returns
 */
const getMonthlyOptions = (
    startDate: DateTime,
    endDate: DateTime,
    semiMonthly: boolean,
) => {
    const dropDownResults = [];
    let onePreviousStart: DateTime,
        onePreviousEnd: DateTime,
        twoPreviousStart: DateTime,
        twoPreviousEnd: DateTime;
    if (semiMonthly) {
        if (startDate.day === 1) {
            onePreviousStart = startDate.minus({ months: 1 }).set({ day: 16 });
            onePreviousEnd = onePreviousStart.endOf("month");
            twoPreviousStart = onePreviousStart.set({ day: 1 });
            twoPreviousEnd = onePreviousStart.set({ day: 15 });
        } else {
            onePreviousStart = startDate.set({ day: 1 });
            onePreviousEnd = startDate.set({ day: 15 });
            twoPreviousStart = onePreviousStart
                .minus({ months: 1 })
                .set({ day: 16 });
            twoPreviousEnd = onePreviousStart.endOf("month");
        }
    } else {
        onePreviousStart = startDate.minus({ months: 1 });
        onePreviousEnd = startDate.minus({ days: 1 });
        twoPreviousStart = onePreviousStart.minus({ months: 1 });
        twoPreviousEnd = onePreviousStart.minus({ days: 1 });
    }
    dropDownResults.push({
        label: getDateLabel(startDate, endDate),
        value: {
            start: startDate,
            end: endDate,
        },
    });
    dropDownResults.push({
        label: getDateLabel(onePreviousStart, onePreviousEnd),
        value: {
            start: onePreviousStart,
            end: onePreviousEnd,
        },
    });
    dropDownResults.push({
        label: getDateLabel(twoPreviousStart, twoPreviousEnd),
        value: {
            start: twoPreviousStart,
            end: twoPreviousEnd,
        },
    });
    return dropDownResults;
};

const getDateLabel = (startDate: DateTime, endDate: DateTime): string =>
    `${startDate.toFormat("MMM d")} - ${endDate.toFormat("MMM d")}`;

import { match } from "ts-pattern";
import { Maybe } from "@snackpass/conversations.types";

import { EmployeeData } from "#core";

export const formatPermissionLabel = (permission: keyof EmployeePermissions) =>
    match(permission)
        .with("discounts", () => "Discounts")
        .with("orders", () => "Orders")
        .with("cashDrawer", () => "Cash Drawer")
        .with("sales", () => "View Sales")
        .exhaustive();

export const formatEmployeePermissions = (permissions: EmployeePermissions) => {
    const employeePermissions = Object.entries(permissions) as Array<
        [keyof EmployeePermissions, boolean]
    >;

    return (
        employeePermissions
            .filter((perm) => perm[1])
            .map((perm) => formatPermissionLabel(perm[0]))
            .join(", ") || "None"
    );
};

export const formatPhoneNumber = (phoneNumber: string): Maybe<string> => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
            "",
        );
    }
    return null;
};

export const groupEmployeesByRole = (
    employeesList: EmployeeData[],
): {
    role: string;
    employees: [EmployeeData, ...EmployeeData[]];
}[] =>
    _.chain(employeesList)
        .map((employee) => {
            employee.role = _.upperCase(employee.role);
            return employee;
        })
        .groupBy("role")
        .map((employees, role) => ({ role, employees }))
        .value();
