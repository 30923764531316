import { Divider } from "#reusable/divider";

import { Draw } from "./Draw";
import { ModifyElement } from "./ModifyElement";
import { ServiceArea } from "./ServiceArea";

export function Panel() {
    return (
        <div className="absolute left-3 top-3 flex flex-col rounded-xl bg-neutral-800">
            <div className="px-3 pt-3">
                <ServiceArea />
            </div>
            <Divider className="my-3 border-neutral-600" />
            <div className="px-3">
                <Draw />
            </div>
            <Divider className="my-3 border-neutral-600" />
            <div className="px-3 pb-3">
                <ModifyElement />
            </div>
        </div>
    );
}
