import { useCallback, useMemo } from "react";
import { GraphQLError } from "graphql/error";
import { toast } from "sonner";

import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStoreId } from "src/redux/slices";
import { useDeleteServiceAreaMutation } from "src/api/graphql/generated/types";
import { logAndSendError } from "src/utils/errors";

export function useDeleteServiceArea() {
    const editor = useEditorStore();

    const storeId = useAppSelector(getActiveStoreId);

    const [remove, { loading, reset }] = useDeleteServiceAreaMutation();

    const deleteServiceArea = useCallback(
        async (id: string) => {
            try {
                const deletedId = await remove({
                    variables: {
                        storeId,
                        id
                    }
                });

                if (deletedId.data?.deleteServiceArea) {
                    editor
                        .getState()
                        .removeServiceArea(deletedId.data.deleteServiceArea);
                }
            } catch (err) {
                logAndSendError(err);
                if (err instanceof GraphQLError) {
                    toast.error(`Unexpected error: ${err.message}`);
                }
            } finally {
                reset();
            }
        },
        [reset, editor, remove, storeId]
    );

    return useMemo(
        () => ({
            loading,
            deleteServiceArea
        }),
        [loading, deleteServiceArea]
    );
}
