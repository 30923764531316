import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { AccessorKeyColumnDef } from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { formatNumber as formatPhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { getActiveStore } from "@snackpass/accounting";

import { OrderDetailsDrawer } from "#guestbook/components/order-details";
import { OrderHistoryDrawer } from "#guestbook/components";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    formatNumber,
    formatRange,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import { ReportsContext } from "#app/reports-context-provider";
import CustomerDownloadButton from "#reports/customer-directory-insights/components/CustomerDownloadButton";
import { DataTable } from "src/@/components/ui/data-table/";
import { exportColumns } from "#reports/customer-directory-insights/lib";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import { useLocation } from "react-router-dom";

interface TableRow {
    name: string;
    userId: string;
    rank: number;
    totalSpent: number;
    orders: number;
    lastOrder: string;
    customerSince: string;
    points: number;
    giftcardBalance: number;
    frequency: number;
    phone: string;
    email: string;
}

const CustomerDirectoryTable = () => {
    const activeStore = useSelector(getActiveStore);
    const location = useLocation<{
        userId: string;
        search: string;
    }>();
    const { reportsState } = useContext(ReportsContext);
    const { customerInsightsData, granularity, dateRanges } = reportsState;
    const [defaultSearchValue, setDefaultSearchValue] = useState(
        location?.state?.search ?? "",
    );
    const [autoSidebarOpenOccured, setAutoSidebarOpenOccured] = useState(false);
    const [selectedUserRow, setSelectedUserRow] = useState<
        TableRow | undefined
    >();

    const dispatch = useDispatch();
    const handleClickUser = useCallback(
        (row: TableRow) => {
            setSelectedUserRow(row);
            dispatch(show("OrderHistoryDrawer"));
        },
        [dispatch],
    );

    const renderName = (name: string, row: TableRow) => (
        <span
            className="cursor-pointer text-success-light"
            onClick={() => handleClickUser(row)}
        >
            {name}
        </span>
    );

    const renderFrequency = (freq: number) => `${freq.toFixed(2)}/wk`;

    const columns: AccessorKeyColumnDef<TableRow, string | number>[] = [
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Name"
                />
            ),
            accessorKey: "name",
            cell: (props) =>
                renderName(props.getValue() as string, props.row.original),
            id: "Name",
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Lifetime Rank"
                />
            ),
            accessorKey: "rank",
            id: "Lifetime Rank",
            cell: (props) => (
                <div className="text-center">
                    {formatNumber(props.row.original.rank)}
                </div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Total Spent"
                />
            ),
            accessorKey: "totalSpent",
            id: "Total Spent",
            cell: (props) => (
                <div className="text-center">
                    {toDollarFormatted(props.row.original.totalSpent)}
                </div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Lifetime Orders"
                />
            ),
            accessorKey: "orders",
            id: "Orders",
            cell: (props) => (
                <div className="text-center">{props.getValue()}</div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Customer Since"
                />
            ),
            accessorKey: "customerSince",
            id: "Customer Since",
            cell: (props) => (
                <div className="text-center">
                    {moment(props.getValue()).format("M/DD/YY")}
                </div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Last Order"
                />
            ),
            accessorKey: "lastOrder",
            id: "Last Order",
            cell: (props) => (
                <div className="text-center">
                    {moment(props.getValue()).format("M/DD/YY")}
                </div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Frequency"
                />
            ),
            accessorKey: "frequency",
            id: "Frequency",
            cell: (props) => (
                <div className="text-center">
                    {renderFrequency(props.row.original.frequency)}
                </div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Gift Card Balance"
                />
            ),
            accessorKey: "giftcardBalance",
            id: "Gift Card Balance",
            cell: (props) => (
                <div className="text-center">
                    {toDollarFormatted(props.row.original.giftcardBalance)}
                </div>
            ),
            enableGlobalFilter: false,
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Phone Number"
                />
            ),
            accessorKey: "phone",
            id: "Phone Number",
            cell: (props) => (
                <div className="text-center">
                    {formatPhoneNumber(
                        props.row.original.phone,
                        "US",
                        "NATIONAL",
                    )}
                </div>
            ),
        },
    ];

    const rows = useMemo(() => {
        const data = customerInsightsData?.customerData || [];
        return data.map((item) => ({
            name: item.name,
            userId: item.userId,
            rank: item.rank,
            totalSpent: item.totalSpent,
            orders: item.orders,
            lastOrder: item.lastOrder,
            customerSince: item.customerSince,
            points: item.points,
            giftcardBalance: item.giftcardBalance,
            frequency: item.frequency,
            phone: item.phone ? item.phone : "",
            email: item.email || "",
        }));
    }, [customerInsightsData]);

    useEffect(() => {
        if (rows?.length > 0 && !autoSidebarOpenOccured) {
            if (location?.state?.userId) {
                const userRow = rows.find(
                    (row: TableRow) => row.userId === location?.state?.userId,
                );
                setSelectedUserRow(userRow);
                dispatch(show("OrderHistoryDrawer"));
            }
            if (location?.state?.search) {
                // Clear defaultSearchValue after initial render
                setDefaultSearchValue("");
            }
            setAutoSidebarOpenOccured(true);
        }
    }, [
        dispatch,
        location.state?.search,
        location.state?.userId,
        rows,
        autoSidebarOpenOccured,
    ]);

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            placeholder: "Search by Name or Phone",
            global: true,
            defaultSearchValue,
        },
    };

    return (
        <div className="mb-20">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Customers</h4>
                <CustomerDownloadButton
                    rows={rows}
                    columns={exportColumns}
                    filename={`${activeStore?.name} Customer Report ${
                        !reportsState.allTime
                            ? formatRange(dateRanges[0], granularity)
                            : ""
                    }`}
                />
            </div>
            {selectedUserRow && (
                <>
                    <OrderHistoryDrawer
                        userId={selectedUserRow?.userId}
                        chatMetadata={{
                            userNumOrders: selectedUserRow?.orders,
                            userAmountSpent:
                                typeof selectedUserRow?.totalSpent === "number"
                                    ? toDollarFormatted(
                                          selectedUserRow.totalSpent,
                                      )
                                    : selectedUserRow?.totalSpent,
                            userLastPurchase: moment(
                                selectedUserRow?.lastOrder,
                            ).format("M/DD/YY"),
                            userFrequency:
                                typeof selectedUserRow?.frequency === "number"
                                    ? renderFrequency(selectedUserRow.frequency)
                                    : selectedUserRow?.frequency,
                            userEmail: selectedUserRow?.email,
                        }}
                        nameOverride={selectedUserRow?.name}
                        showGuestbookLink
                        showLoyaltyLink
                        showContactInfo
                    />
                    <OrderDetailsDrawer />
                </>
            )}

            <div className="overflow-x-scroll">
                {!customerInsightsData?.customerDataLoading &&
                (customerInsightsData?.customerInsightsDataFailed ||
                    customerInsightsData?.customerData) ? (
                    customerInsightsData?.customerData ? (
                        <div className="ml-1 mt-2">
                            <DataTable
                                columns={columns}
                                data={rows}
                                showPagination
                                toolbar={toolbarOptions}
                            />
                        </div>
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default CustomerDirectoryTable;
