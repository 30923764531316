import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { debounce } from "radash";
import * as Select from "@radix-ui/react-select";
import { CaretDownIcon, PlusIcon } from "@radix-ui/react-icons";

import { Input } from "src/@/components/ui/input";
import {
    useEditorState,
    useEditorStore
} from "#table-editor/components/Editor/EditorProvider";
import { cn } from "src/@/lib/utils";
import { SelectItem, SelectScrollUpButton } from "src/@/components/ui/select";
import { Button } from "src/@/components/ui/button";

type SelectServiceAreaProps = {
    onChange(id: string): void;
    width?: number;
};

export function SelectServiceArea({ onChange, width }: SelectServiceAreaProps) {
    const editor = useEditorStore();

    const serviceAreas = useEditorState((state) => state.serviceAreas);
    const activeServiceArea = useEditorState(
        (state) => state.activeServiceArea,
        (a, b) => a?.id === b?.id
    );

    const [name, setName] = useState(activeServiceArea?.name ?? "");

    useEffect(() => {
        setName(activeServiceArea?.name ?? "");
    }, [activeServiceArea]);

    const updateName = debounce({ delay: 200 }, (name: string) => {
        editor.getState().setActiveServiceAreaName(name);
    });

    const onChangeName = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            updateName(e.target.value);
            setName(e.target.value);
        },
        [updateName]
    );

    const onAddNewServiceArea = useCallback(() => {
        editor.getState().addServiceArea("");
        onChange("");
    }, [onChange, editor]);

    return (
        <Select.Root
            onValueChange={onChange}
            value={activeServiceArea?.id ?? ""}
        >
            <div className="flex items-center justify-between gap-2">
                <Input
                    placeholder="Name"
                    value={name}
                    onChange={onChangeName}
                    className="rounded-none border-x-0 border-t-0 border-input border-b-neutral-500 bg-transparent text-white placeholder:text-neutral-400 focus-visible:border-b-white focus-visible:ring-0"
                />
                <Select.Trigger className="flex h-9 w-9 items-center justify-center rounded-md border-0 hover:bg-accent hover:text-accent-foreground">
                    <Select.Icon asChild>
                        <CaretDownIcon className="h-4 w-4" />
                    </Select.Icon>
                </Select.Trigger>
            </div>
            <Select.Portal style={{ width }}>
                <Select.Content
                    className={cn(
                        "relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                        "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1"
                    )}
                    position="popper"
                    align="end"
                >
                    <SelectScrollUpButton />
                    <Select.Viewport className="h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)] p-1">
                        {serviceAreas.map((serviceArea) =>
                            serviceArea.id === activeServiceArea?.id ? (
                                <SelectItem
                                    value={activeServiceArea.id}
                                    key={activeServiceArea.id}
                                >
                                    {name}
                                </SelectItem>
                            ) : (
                                <SelectItem
                                    value={serviceArea.id}
                                    key={serviceArea.id}
                                >
                                    {serviceArea.name}
                                </SelectItem>
                            )
                        )}
                        <Button
                            type="button"
                            variant="ghost"
                            size="default"
                            className="w-full gap-2"
                            onClick={onAddNewServiceArea}
                        >
                            <PlusIcon />
                            <span>Add Service Area</span>
                        </Button>
                    </Select.Viewport>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}
